import { AuthorizationRoles, useGetKnowledgeArticle } from '#edsn/api/pie-bff';
import { hasRole } from '#edsn/auth';
import { useCurrentOrganisation } from '#pie/auth';

export const useRelatedKnowledgeArticles = (mainCategoryId?: string | string[], subCategoryId?: string | string[]) => {
  const { currentAccount } = useCurrentOrganisation();

  const { data: relatedArticlesData, isFetching: relatedArticlesLoading } = useGetKnowledgeArticle(
    {
      filter: {
        mainCategoryIds: Array.isArray(mainCategoryId) ? mainCategoryId : [mainCategoryId!],
        subCategoryIds: subCategoryId ? (Array.isArray(subCategoryId) ? subCategoryId : [subCategoryId]) : undefined,
      },
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    {
      query: {
        enabled:
          !!mainCategoryId &&
          mainCategoryId.length > 0 &&
          currentAccount &&
          hasRole([AuthorizationRoles.KnowledgeArticles], currentAccount.roles),
      },
    }
  );
  return { relatedArticles: relatedArticlesData?.items, relatedArticlesLoading };
};
