import { type FieldValues, type Path, useFormState } from 'react-hook-form';
import type { InputFieldProps } from '#pie/components/input-field/InputField';
import { InputField } from '#pie/components/input-field/InputField';

interface FormFieldProps<T extends FieldValues> extends Omit<InputFieldProps, 'name' | 'error'> {
  name: Path<T>;
}

export function FormField<T extends FieldValues>({ name, children, ...props }: FormFieldProps<T>) {
  const { errors } = useFormState({
    name,
  });

  return (
    <InputField name={name} error={errors?.[name]?.message as string} {...props}>
      {children}
    </InputField>
  );
}
