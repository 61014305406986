import { useTranslation } from 'react-i18next';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { TicketSelection } from '#pie/components/ticket-type-selector/TicketTypeSelector';
import { useStoredParams } from '#pie/stores/searchParams';

export const TicketCreateSelectType: React.FC = () => {
  const { t } = useTranslation();
  const params = useStoredParams('/tickets');
  return (
    <>
      <PageHead title={t('ticket_create.page_title')} />
      <Page>
        <PageHeader
          title={t('ticket_create.page_title')}
          previousText={t('ticket_create.back_to_tickets')}
          backHref={`/tickets${params}`}
        />
        <TicketSelection />
      </Page>
    </>
  );
};
