// NOTE: When src contains msdyn_knowledgearticleimages it's a dynamics url that needs to be retreived differently
export const extractKnowledgeBaseImageId = (src?: string) => {
  if (!src?.includes('msdyn_knowledgearticleimages')) {
    return;
  }

  return decodeURI(src)
    .match(/\((.*)\)/)
    ?.pop();
};
