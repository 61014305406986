import type { SurveyDialogueDto } from '../generated/model/surveyDialogueDto';

export const createSurveyDialogue = (overrides: Partial<SurveyDialogueDto> = {}): SurveyDialogueDto => ({
  createdBy: {
    id: 'c6f7e4a2-7dc6-ea11-a816-000d3a21145f',
    label: 'Jantje Puk',
  },
  createdOn: '2024-05-29T08:39:31',
  effortScore: 'Dissatisfied',
  explanation: 'Ik ben niet vriendelijk behandled',
  hasUnreadComments: true,
  id: '1a3a7d76-dcf8-ee11-a1fd-000d3a26g4fde',
  regionalGridOperator: {
    id: 'c6f7e4a2-7dc6-ea11-a816-000d3a21145f',
    label: 'Liander',
  },
  regionalGridOperatorHandler: {
    id: 'c6f7e4a2-7dc6-ea11-a816-000d3a21145f',
    label: 'Pietje Klein',
  },
  status: 'New',
  subject: 'Duration',
  ticketId: '12345',
  ticketNumber: 'T-2024-03-113389',
  ticketTitle: 'ticket titel',
  ...overrides,
});
