import HtmlWrapper from 'react-html-map';
import type { CommentDto } from '#edsn/api/pie-bff';
import { createComment } from '#edsn/api/pie-bff/mocks';
import { AttachmentAnchor } from '../attachment-anchor/AttachmentAnchor';
import { Skeleton } from '../skeleton/Skeleton';
import { SkeletonLines } from '../skeleton-lines/SkeletonLines';
import { getFormattedDate } from '#pie/i18n/dateFormats';
import { parseDates } from '#pie/utils/date';
import { tv } from '#pie/utils/TailwindUtils';

type CommentProps =
  | {
      comment: CommentDto;
      isLoading?: undefined;
    }
  | { comment?: undefined; isLoading: true };

export const Comment: React.FC<CommentProps> = ({ comment, isLoading }) => {
  const s = styles();
  const { attachments, description, createdOn, createdBy } = parseDates(isLoading ? createComment() : comment, [
    'createdOn',
  ]);

  return (
    <div className={s.base()}>
      <header className={s.header()}>
        <h3 className={s.name()}>{isLoading ? <Skeleton className="w-{8rem}" /> : createdBy.label}</h3>
        <p className={s.date()}>{isLoading ? <Skeleton className="w-{5rem}" /> : getFormattedDate(createdOn)}</p>
      </header>
      {description && (
        <div className="whitespace-break-spaces">
          {isLoading ? (
            <>
              <SkeletonLines lines={3} />
              <Skeleton className="w-[50%]" />
            </>
          ) : (
            <HtmlWrapper decodeEntities html={description}>
              {{
                a: ({ children, href, id, target }) => {
                  return (
                    <a id={id} rel="noopener noreferrer" href={href} target={target} className="text-primary link">
                      {children}
                    </a>
                  );
                },
                br: () => <br />,
                div: ({ children }) => <>{children}</>,
                em: ({ children }) => <em>{children}</em>,
                figure: ({ children }) => <figure>{children}</figure>,
                li: ({ children }) => <li>{children}</li>,
                ol: ({ children }) => <ol className="mb-4 ml-4 list-decimal">{children}</ol>,
                p: ({ children }) => <>{children}</>,
                span: ({ children }) => <>{children}</>,
                strong: ({ children }) => <strong>{children}</strong>,
                table: ({ children }) => <table className="bg-white">{children}</table>,
                tbody: ({ children }) => <tbody className="border border-neutral-300">{children}</tbody>,
                td: ({ children }) => <td className="border border-neutral-300 px-2 py-1">{children}</td>,
                thead: ({ children }) => <thead>{children}</thead>,
                tr: ({ children }) => <tr>{children}</tr>,
                u: ({ children }) => <u>{children}</u>,
                ul: ({ children }) => <ul className="mb-4 ml-4 list-disc">{children}</ul>,
              }}
            </HtmlWrapper>
          )}
        </div>
      )}
      {!isLoading && attachments.map(attachment => <AttachmentAnchor key={attachment.id} attachment={attachment} />)}
    </div>
  );
};

const styles = tv({
  base: 'flex flex-col gap-4',
  slots: {
    date: 'text-sm text-neutral-500',
    header: 'flex justify-between',
    name: 'text-primary-dark text-xl font-bold',
  },
});
