/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type SurveyDialogueStatus = (typeof SurveyDialogueStatus)[keyof typeof SurveyDialogueStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SurveyDialogueStatus = {
  New: 'New',
  InProgress: 'InProgress',
  Closed: 'Closed',
  Reopened: 'Reopened',
} as const;
