import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import type { DataToolbar } from '#pie/components/data-toolbar/DataToolbar';
import type { mapToFilterOptions } from '#pie/utils/mapToFilterOptions';
import type { WritableAtom } from 'jotai';
import type { ComponentProps } from 'react';

export interface Props<TFilterData extends object, TFilter extends object> {
  filtersQuery: () => Promise<TFilterData>;
  filtersAtom: WritableAtom<TFilter, [TFilter], TFilter>;
  filters: {
    id: keyof TFilter;
    label: string;
    options:
      | ((data: TFilterData | undefined) => ReturnType<typeof mapToFilterOptions>)
      | ReturnType<typeof mapToFilterOptions>;
  }[];
  showFilterPanel: boolean;
  setShowFilterPanel: (showFilterPanel: boolean) => void;
}

export const useDataToolbar = <TFilterData extends object, TFilter extends object>({
  filtersQuery,
  filtersAtom,
  filters: definedFilters,
  showFilterPanel,
  setShowFilterPanel,
}: Props<TFilterData, TFilter>): ComponentProps<typeof DataToolbar<TFilter>> => {
  const { data } = useQuery({
    /* c8 ignore next */
    queryFn: () => filtersQuery(),
    // TODO: Find a way to get the url of the query instead of using the name
    queryKey: [filtersQuery.name],
  });
  const [activeFilters, setActiveFilters] = useAtom(filtersAtom);
  const filters: ComponentProps<typeof DataToolbar<TFilter>>['filters'] = definedFilters.map(
    ({ id, options, label }) => ({
      id,
      label,
      options: Array.isArray(options) ? options : options(data),
      values: activeFilters[id] ?? [],
    })
  );

  return {
    activeFilters,
    filters,
    setActiveFilters,
    setShowFilterPanel,
    showFilterPanel,
  };
};
