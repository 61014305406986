import { useDataFilters } from './useDataFilters';
import { useDataTable } from './useDataTable';
import { useDataToolbar } from './useDataToolbar';
import type { Props as FilterProps } from './useDataFilters';
import type { Props as TableProps } from './useDataTable';
import type { Props as ToolbarProps } from './useDataToolbar';

type Props<
  TData extends object,
  TFilterData extends object,
  TFilter extends object,
  TDates extends (keyof TData)[],
> = TableProps<TData, TFilter, TDates> &
  Omit<ToolbarProps<TFilterData, TFilter>, 'showFilterPanel' | 'setShowFilterPanel'> &
  FilterProps<TFilterData, TFilter>;

export const useDataView = <
  TData extends object,
  TFilterData extends object,
  TFilter extends object,
  TDates extends (keyof TData)[],
>(
  props: Props<TData, TFilterData, TFilter, TDates>
) => {
  const tableProps = useDataTable(props);
  const filterProps = useDataFilters(props);
  const toolbarProps = useDataToolbar({
    ...props,
    setShowFilterPanel: filterProps.onOpenChange,
    showFilterPanel: filterProps.open,
  });

  return {
    filterProps,
    tableProps,
    toolbarProps,
  };
};
