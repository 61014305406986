/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type SurveySubject = (typeof SurveySubject)[keyof typeof SurveySubject];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SurveySubject = {
  Accessibility: 'Accessibility',
  Duration: 'Duration',
  Result: 'Result',
  Employee: 'Employee',
  Other: 'Other',
} as const;
