import { AnimatePresence } from 'framer-motion';
import { createContext, useCallback, useContext, useState } from 'react';
import { Stack } from '../stack/Stack';
import { Toast } from './Toast';
import type { ComponentProps, PropsWithChildren } from 'react';

interface Toast extends Omit<ComponentProps<typeof Toast>, 'onClose'> {
  id: number;
}

interface ToastContext {
  addToast: (toast: Omit<Toast, 'id'>) => number;
  removeToast: (id: number) => void;
  toasts: Toast[];
}

export const ToastContext = createContext<ToastContext | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be within ToastProvider');
  }
  return context;
};

interface ToastProviderProps extends PropsWithChildren {
  toasts?: Toast[];
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ toasts: initialToasts = [], children }) => {
  const [toasts, setToasts] = useState<Toast[]>(initialToasts);

  const addToast = useCallback(
    (toast: Omit<Toast, 'id'>) => {
      const id = performance.now();
      setToasts(t => [...t, { ...toast, id }]);
      return id;
    },
    [toasts]
  );
  const removeToast = useCallback(
    (id: number) => {
      setToasts(toasts.filter(t => t.id !== id));
    },
    [toasts]
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast, toasts }}>
      {children}
      <Stack gap="sm" className="fixed right-4 top-4 z-50 items-end" direction="columnReverse">
        <AnimatePresence aria-hidden="false" mode="popLayout">
          {toasts.map(toast => (
            <Toast
              key={toast.id}
              onClose={() => {
                removeToast(toast.id);
              }}
              {...toast}
            />
          ))}
        </AnimatePresence>
      </Stack>
    </ToastContext.Provider>
  );
};
