import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../icon/Icon';
import { BetaDialog } from '../layout/sidebar/beta-dialog/BetaDialog';
import { isLinkBeta } from '../layout/sidebar/menu/menu';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';
import type { Menu } from '../layout/sidebar/menu/SidebarMenu';
import { ConditionalWrapper } from '#pie/utils/ConditionalWrapper';
import { cn } from '#pie/utils/TailwindUtils';

export const NavigationTile = ({ to, label, description, icon, adornment }: Menu) => {
  const [showModal, setShowModal] = useState(false);
  const beta = isLinkBeta(to);
  const linkCss = cn(
    'flex items-center gap-5 rounded-md border border-neutral-300 px-5 py-3 hover:bg-neutral-100 focus:border-primary focus:outline-none focus:shadow-focusInput'
  );
  return (
    <>
      <ConditionalWrapper
        condition={beta}
        elseWrapper={children => (
          <button
            aria-label={label}
            onClick={() => setShowModal(true)}
            className={cn(linkCss, 'appearance-none text-left')}
          >
            {children}
          </button>
        )}
        wrapper={children => (
          <Link aria-label={label} to={to} className={linkCss}>
            {children}
          </Link>
        )}
      >
        <div
          className={cn(
            'before:bg-success relative before:absolute before:-bottom-1.5 before:-right-1.5 before:block before:h-3 before:w-3 before:rounded-full',
            !beta && 'before:content-none'
          )}
        >
          <Icon size={32} icon={icon} className="text-primary" />
        </div>
        <Stack>
          <Stack direction="row" gap="xs" className="items-center">
            <Text className="text-primary-dark leading-snug">{label}</Text> {!!adornment && adornment(true)}
          </Stack>
          <Text variant="sm" className="line-clamp-1 leading-snug text-neutral-500">
            {description}
          </Text>
        </Stack>
      </ConditionalWrapper>
      <BetaDialog open={showModal} onOpenChange={() => setShowModal(false)} to={to} />
    </>
  );
};
