import type { ContactDetailsDto } from '#edsn/api/pie-bff';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';

export const defaultContactDetailsSorting: DefaultSorting<ContactDetailsDto> = {
  desc: false,
  id: 'fullName',
};

export const contactDetailsSortingAtom = createSortingAtom({
  defaultSorting: defaultContactDetailsSorting,
});
