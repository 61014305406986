import plusIcon from '@iconify/icons-material-symbols/add';
import minIcon from '@iconify/icons-material-symbols/check-indeterminate-small';
import * as Accordion from '@radix-ui/react-accordion';
import type { PropsWithChildren } from 'react';
import { Icon } from '#pie/components/icon/Icon';
import { Text } from '#pie/components/text/Text';

export interface AccordionItemProps extends Accordion.AccordionItemProps, PropsWithChildren {
  className?: string;
}

export function AccordionItem({ className, children, title, ...props }: AccordionItemProps) {
  return (
    <Accordion.Item {...props}>
      <Accordion.Header>
        <Accordion.Trigger className="group flex w-full justify-between py-0.5">
          <Text className="text-left font-bold text-neutral-600">{title}</Text>

          <Icon
            icon={plusIcon}
            className="text-primary flex-shrink-0 group-data-[state=open]:hidden"
            size={24}
            aria-hidden
          />
          <Icon
            icon={minIcon}
            className="text-primary flex-shrink-0 group-data-[state=closed]:hidden"
            size={24}
            aria-hidden
          />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content className="mt-3">{children}</Accordion.Content>
    </Accordion.Item>
  );
}
