import { createColumnHelper, functionalUpdate } from '@tanstack/react-table';
import { useAtom } from 'jotai';
import get from 'lodash.get';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../table/Table';
import { Text } from '../text/Text';
import type { Optional } from '#pie/types';
import type { TableProps } from '../table/Table';
import type { ColumnHelper, SortingState } from '@tanstack/react-table';
import type { WritableAtom } from 'jotai';
import type { Paths } from 'type-fest';
import { paginationAtom } from '#pie/stores/pagination';

interface Props<TData extends object>
  extends Optional<
    Omit<
      TableProps<TData>,
      'columns' | 'emphasizedRows' | 'pageCount' | 'pagination' | 'sorting' | 'onPaginationChange' | 'onSortingChange'
    >,
    'data'
  > {
  sortingAtom: WritableAtom<SortingState, [SortingState], SortingState>;
  columns: (helper: ColumnHelper<TData>) => TableProps<TData>['columns'];
  totalCount?: number;
  isLoading?: boolean | number;
  idKey?: Paths<TData>;
}

export const DataTable = <TData extends object>({
  totalCount,
  columns,
  data,
  sortingAtom,
  idKey,
  ...props
}: Props<TData>) => {
  const { t } = useTranslation();
  const helper = useMemo(() => createColumnHelper<TData>(), []);

  const [pagination, setPagination] = useAtom(paginationAtom);
  const [sorting, setSorting] = useAtom(sortingAtom);

  const pageCount = totalCount ? Math.ceil(totalCount / pagination.pageSize) : -1;

  const unreadIds = hasUnreadProp(data) ? data.filter(row => row.hasUnreadComments).map(({ id }) => id) : [];

  // NOTE: Ignoring as the rowId is not easily targetable in tests
  /* c8 ignore start */
  const getRowId = (row: TData) => {
    const id = get(row, idKey || 'id') as string;
    if (!id) {
      throw new Error('Table rows must have an id');
    }
    return id;
  };
  /* c8 ignore end */

  return !props.isLoading && !totalCount ? (
    <Text className="p-3">{t('common.no_results')}</Text>
  ) : (
    <Table
      /* c8 ignore start */
      columns={columns(helper).map(column => ({
        ...column,
        id: hasAccessorKey(column) ? column.accessorKey : '',
      }))}
      /* c8 ignore end */
      sortDescFirst
      manualSorting
      getRowId={getRowId}
      data={data}
      emphasizedRows={unreadIds}
      enableMultiSort={false}
      getFilteredRowModel={undefined}
      getSortedRowModel={undefined}
      onPaginationChange={paginationUpdater => {
        const newPaginationVal = functionalUpdate(paginationUpdater, pagination);
        setPagination(newPaginationVal);
      }}
      onSortingChange={sortingUpdater => {
        const newSortVal = functionalUpdate(sortingUpdater, sorting);
        setSorting(newSortVal);
      }}
      pageCount={pageCount}
      pageSizes={[10, 25, 50, 100]}
      pagination={pagination}
      sorting={sorting}
      totalCount={totalCount}
      {...props}
    />
  );
};

const hasUnreadProp = <T,>(data?: T[]): data is (T & { id: string; hasUnreadComments: boolean })[] =>
  !!data &&
  data.some(
    row =>
      typeof row === 'object' &&
      row !== null &&
      Object.prototype.hasOwnProperty.call(row, 'hasUnreadComments') &&
      Object.prototype.hasOwnProperty.call(row, 'id')
  );

const hasAccessorKey = (column: object): column is { accessorKey: string } =>
  !!column &&
  typeof column === 'object' &&
  column !== null &&
  Object.prototype.hasOwnProperty.call(column, 'accessorKey');
