import * as AccordionBase from '@radix-ui/react-accordion';
import type { PropsWithChildren } from 'react';

export interface AccordionProps extends PropsWithChildren, AccordionBase.AccordionMultipleProps {
  className?: string;
}

export function Accordion({ className, children, ...props }: AccordionProps) {
  return (
    <AccordionBase.Root {...props} className="flex flex-col gap-5">
      {children}
    </AccordionBase.Root>
  );
}

export { AccordionItem } from './accordion-item/AccordionItem';
