import type { MainMenuSummaryQuery, TopbarSummaryQuery } from '../generated/graphql';

export const createGQLTopbarNavigation = (): TopbarSummaryQuery => ({
  navigationNodes: [
    {
      __typename: 'headerSub_Node',
      id: '0',
      nodeUri: 'zoeken',
      title: 'Search',
    },
  ],
});

export const createGQLMainMenu = (): MainMenuSummaryQuery => ({
  navigationNodes: [
    {
      __typename: 'headerMain_Node',
      content: [
        {
          __typename: 'headerMain_Node',
          content: [
            {
              __typename: 'headerMain_Node',
              id: '3',
              nodeUri: 'voor-wie/marktpartijen/meetbedrijven',
              title: 'Meetbedrijven',
            },
          ],
          id: '2',
          nodeUri: 'voor-wie/marktpartijen',
          title: 'Marktpartijen',
        },
      ],
      id: '1',
      nodeUri: 'voor-wie',
      title: 'Voor wie',
    },
    {
      __typename: 'headerMain_Node',
      content: [
        {
          __typename: 'headerMain_Node',
          content: [],
          id: '5',
          nodeUri: 'diensten/registreren-marktpartijen',
          title: 'Registreren marktpartijen',
        },
      ],
      id: '4',
      nodeUri: 'diensten',
      title: 'Diensten',
    },
    {
      __typename: 'headerMain_Node',
      content: [],
      id: '6',
      nodeUri: 'themas',
      title: "Thema's",
    },
    {
      __typename: 'headerMain_Node',
      content: [],
      id: '7',
      nodeUri: 'nieuws',
      title: 'Nieuws',
    },
    {
      __typename: 'headerMain_Node',
      content: [],
      id: '8',
      nodeUri: 'over-ons',
      title: 'Over ons',
    },
  ],
});
