import expandMoreRounded from '@iconify/icons-material-symbols/expand-more-rounded';
import spinnerIcon from '@iconify/icons-material-symbols/progress-activity';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import * as Select from '@radix-ui/react-select';
import { forwardRef } from 'react';
import { Icon } from '../icon/Icon';
import { styles } from '../input-text/InputText';
import { menuStyles } from '../menu/Menu';
import { Stack, StackItem } from '../stack/Stack';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import type { IconProps } from '../icon/Icon';
import type { ComponentProps } from 'react';
import { isDefined } from '#pie/utils/isDefined';
import { cn, tv } from '#pie/utils/TailwindUtils';

export type Option = {
  label: string;
  value: string | null;
  icon?: IconProps['icon'];
};

export interface InputDropdownProps
  extends VariantProps<typeof inputDropdown>,
    Omit<Select.SelectProps, 'name' | 'disabled'>,
    Required<Pick<Select.SelectProps, 'name'>> {
  id?: string;
  options?: Option[];
  onChange?: ComponentProps<typeof Select.Root>['onValueChange'];
  value?: string;
  placeholder?: string;
}

export const InputDropdown = forwardRef<HTMLButtonElement, InputDropdownProps>(function InputDropdown(
  { fullWidth, onChange, options, value, isError, disabled, placeholder = '- Selecteer -', isLoading, ...props },
  ref
) {
  const s = inputDropdown({ disabled, fullWidth, isError });
  const parsedOptions = [!props.required && ({ label: placeholder, value: null } as Option), ...(options || [])].filter(
    isDefined
  );

  return (
    /* c8 ignore next 1 */
    <Select.Root value={value} onValueChange={onChange} disabled={isLoading || disabled || false} name={props.name}>
      <Select.Trigger data-testid={`combobox-${props.name}`} className={s.input()} ref={ref} id={props.name} {...props}>
        <span className={s.value()}>
          <Select.Value placeholder={placeholder} />
        </span>

        <Select.Icon>
          {isLoading ? (
            <Icon role="progressbar" aria-label="loading" icon={spinnerIcon} size={16} className="animate-spin" />
          ) : (
            <Icon icon={expandMoreRounded} size={16} />
          )}
        </Select.Icon>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content
          className={cn(s.content(), 'overflow-hidden')} //'max-h-[--radix-select-content-available-height]')}
          position="popper"
        >
          <ScrollArea.Root type="auto" className="relative w-full">
            <Select.Viewport asChild>
              <ScrollArea.Viewport
                className="max-h-[calc(var(--radix-select-content-available-height)-1.5rem)] w-full"
                style={{ overflowY: undefined }}
              >
                <Stack as="ul" divider="line">
                  {parsedOptions.map((option, idx) => (
                    <StackItem as="li" key={idx}>
                      {/* force option to accept a null value to display our placeholder item */}
                      <Select.Item
                        data-testid={`option-${option.value}`}
                        value={option.value!}
                        className={s.item()}
                        aria-label={option.label}
                      >
                        {!!option.icon && <Icon className={s.icon()} icon={option.icon} size={24} />}
                        <Select.ItemText>{option.label}</Select.ItemText>
                      </Select.Item>
                    </StackItem>
                  ))}
                </Stack>
              </ScrollArea.Viewport>
            </Select.Viewport>
            <ScrollArea.Scrollbar orientation="vertical" className="w-1.5 rounded">
              <ScrollArea.Thumb className="w-1.5 rounded bg-neutral-300" />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
});

const inputDropdown = tv({
  base: '',
  extend: styles,
  slots: {
    content: menuStyles().content(),
    icon: menuStyles().icon(),
    input:
      'flex justify-between pl-4 pr-2 py-[11px] items-center data-[state=open]:border-primary data-[state=open]:ring-primary-lighter data-[state=open]:ring-2 gap-2',
    item: menuStyles().item(),
    value: 'overflow-hidden text-ellipsis whitespace-nowrap',
  },
  variants: {},
});
