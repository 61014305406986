import phoneIcon from '@iconify/icons-material-symbols/call';
import mailIcon from '@iconify/icons-material-symbols/mail-outline-rounded';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateUpdateContactDetailsDto, UpdateContactInformationDto } from '#edsn/api/pie-bff';
import {
  AuthorizationRoles,
  getGetContactDetailsIdQueryKey,
  useGetContactDetails,
  useGetContactDetailsId,
  usePutContactDetailsMyInformation,
} from '#edsn/api/pie-bff';
import type { SubmitHandler } from 'react-hook-form';
import { useCurrentOrganisation } from '#pie/auth';
import { useUserHasRole } from '#pie/auth/useUserHasRole';
import { AuthorizationTag } from '#pie/components/authorization-tag/AuthorizationTag';
import { Button } from '#pie/components/button/Button';
import { Card } from '#pie/components/card/Card';
import { IconButton } from '#pie/components/icon-button/IconButton';
import { InfoDialog } from '#pie/components/info-dialog/InfoDialog';
import { InputField } from '#pie/components/input-field/InputField';
import { InputText } from '#pie/components/input-text/InputText';
import { Page, PageHeader } from '#pie/components/page/Page';
import { Skeleton } from '#pie/components/skeleton/Skeleton';
import { SkeletonLines } from '#pie/components/skeleton-lines/SkeletonLines';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { useToast } from '#pie/components/toast/ToastContext';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormText } = typedFormFields<CreateUpdateContactDetailsDto>();

export const Account = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const { currentAccount } = useCurrentOrganisation();
  const userId = currentAccount?.userId;
  const hasCprRole = useUserHasRole({ role: AuthorizationRoles.CprRead });

  const { data: user, isLoading } = useGetContactDetailsId(userId!, {
    query: { enabled: !!userId, refetchOnWindowFocus: false },
  });

  const { data: superUserContacts, isLoading: isSuperUserLoading } = useGetContactDetails({
    filter: { roles: ['SuperUser'] },
    pagination: { pageIndex: 0, pageSize: 100 },
  });

  const formMethods = useForm<UpdateContactInformationDto>();

  useEffect(() => {
    formMethods.reset({
      ...user,
      phone: user?.phone || '',
    });
  }, [user]);

  const { mutate, isLoading: isMutating } = usePutContactDetailsMyInformation({
    mutation: {
      onError: () => {
        addToast({
          message: t('account.toast.error.message'),
          title: t('account.toast.error.title'),
          type: 'error',
        });
      },
      onSuccess: () => {
        addToast({
          message: t('account.toast.succes.message'),
          title: t('account.toast.succes.title'),
          type: 'success',
        });
        userId && queryClient.invalidateQueries(getGetContactDetailsIdQueryKey(userId));
      },
    },
  });

  const onSubmit: SubmitHandler<UpdateContactInformationDto> = data => {
    mutate({ data: { phone: data.phone } });
  };

  return (
    <Page>
      <FormProvider {...formMethods}>
        {/* NOTE: added due to react testing library not being able to find the form otherwise */}
        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
        <form role="form" onSubmit={formMethods.handleSubmit(onSubmit)}>
          <PageHeader title={t('account.header.title')}>
            <Button
              type="submit"
              isLoading={isLoading || isMutating}
              variant="secondary"
              isDisabled={!formMethods.formState.isDirty}
            >
              {t('organisation_management.update.header.button.submit')}
            </Button>
          </PageHeader>
          <Stack gap="lg" className="mx-auto my-6 max-w-4xl">
            <Card
              size="md"
              heading={
                <Stack direction="row" gap="xs" className="text-primary-dark">
                  <Text as="h6" variant="h6">
                    {t('common.personal_info')}
                  </Text>
                  <InfoDialog title={t('account.info.title')}>{t('account.info.message')}</InfoDialog>
                </Stack>
              }
              shadow={false}
            >
              <div className="grid grid-cols-2 gap-x-6 gap-y-4">
                <InputField name="firstName" label={t('common.first_name')}>
                  <InputText name="firstName" fullWidth disabled value={user?.firstName ?? ''} />
                </InputField>
                <InputField name="lastName" label={t('common.last_name_prefix')}>
                  <InputText name="lastName" fullWidth disabled value={user?.lastName ?? ''} />
                </InputField>
                <InputField name="email" label={t('common.email')}>
                  <InputText name="email" type="email" fullWidth disabled value={user?.email ?? ''} />
                </InputField>
                <FormText type="tel" name="phone" label={t('common.phone')} fullWidth isLoading={isLoading} />
                <InputField name="password" label={t('common.password')}>
                  <InputText name="password" type="password" fullWidth disabled value="*********" />
                </InputField>
              </div>
            </Card>
            <Card
              size="md"
              heading={
                <Text as="h6" variant="h6">
                  {t('common.roles')}
                </Text>
              }
              shadow={false}
            >
              <div className="flex flex-wrap gap-2">
                {isLoading ? (
                  <Stack direction="row" gap="sm">
                    {Array(3)
                      .fill(0)
                      .map((_, index) => (
                        <Skeleton key={index} className="w-16" />
                      ))}
                  </Stack>
                ) : user?.roles && user.roles.length > 0 ? (
                  user?.roles.map(m => <AuthorizationTag role={m} />)
                ) : (
                  <Text>{t('account.empty.roles')}</Text>
                )}
              </div>
            </Card>
            <Card
              size="md"
              heading={
                <Text as="h6" variant="h6">
                  {t('common.admin', { count: superUserContacts?.items.length || 2 })}
                </Text>
              }
              shadow={false}
            >
              <Stack>
                {isSuperUserLoading ? (
                  <SkeletonLines lines={3} />
                ) : (
                  <Stack gap="xs">
                    {superUserContacts?.items.map(contact => (
                      <div className="flex flex-row gap-1">
                        <Card shadow={false} className="bg- grow bg-neutral-100 px-5 py-3">
                          {contact.fullName}
                        </Card>
                        <IconButton
                          icon={mailIcon}
                          as={'a'}
                          href={`mailto:${contact.email}`}
                          className="border-1 rounded-lg border border-solid border-neutral-300 px-3 py-3 focus:bg-white"
                        />
                        <IconButton
                          icon={phoneIcon}
                          as={'a'}
                          href={`tel:${contact.phone}`}
                          className="border-1 rounded-lg border border-solid border-neutral-300 px-3 py-3 focus:bg-white"
                        />
                      </div>
                    ))}
                  </Stack>
                )}
              </Stack>
            </Card>
            <Card
              size="md"
              heading={
                <Stack direction="row" gap="xs" className="text-primary-dark">
                  <Text as="h6" variant="h6">
                    {t('account.team', { count: user?.teams.length || 2 })}{' '}
                  </Text>
                  <InfoDialog title={t('organisation_management.user_management.create.teams.info.title')}>
                    {t('organisation_management.user_management.create.teams.info.message')}
                  </InfoDialog>
                </Stack>
              }
              shadow={false}
            >
              <Stack gap="md">
                {isLoading ? (
                  <SkeletonLines lines={3} />
                ) : user?.teams && user.teams.length > 0 ? (
                  <Stack gap="sm">
                    {user?.teams.map(item => (
                      <Card shadow={false} className="px-5 py-3">
                        {item.label}
                      </Card>
                    ))}
                  </Stack>
                ) : (
                  <Text>{t('account.empty.teams')}</Text>
                )}
              </Stack>
            </Card>
            {hasCprRole && (
              <Card
                size="md"
                heading={
                  <Stack direction="row" gap="xs" className="text-primary-dark">
                    <Text as="h6" variant="h6">
                      {t('account.contact_group', { count: user?.contactGroups.length || 2 })}
                    </Text>
                  </Stack>
                }
                shadow={false}
              >
                <Stack gap="md">
                  {isLoading ? (
                    <SkeletonLines lines={3} />
                  ) : user?.contactGroups && user.contactGroups.length > 0 ? (
                    <Stack gap="sm">
                      {user.contactGroups.map(group => (
                        <Card shadow={false} className="px-5 py-3">
                          {group.label}
                        </Card>
                      ))}
                    </Stack>
                  ) : (
                    <Text>{t('account.empty.contact_groups')}</Text>
                  )}
                </Stack>
              </Card>
            )}
          </Stack>
        </form>
      </FormProvider>
    </Page>
  );
};
