import arrowLeftAlt from '@iconify/icons-material-symbols/arrow-left-alt';
import arrowRightAlt from '@iconify/icons-material-symbols/arrow-right-alt';
import calendarMonthRounded from '@iconify/icons-material-symbols/calendar-month-rounded';
import { parseDate } from '@internationalized/date';
import { type InputHTMLAttributes, forwardRef } from 'react';
import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DatePicker,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Popover,
} from 'react-aria-components';
import { Icon } from '../icon/Icon';
import { styles } from '../input-text/InputText';
import { type VariantProps, tv } from '#pie/utils/TailwindUtils';

export interface InputDateProps
  extends Omit<VariantProps<typeof inputDate>, 'hasIcon'>,
    Omit<InputHTMLAttributes<HTMLInputElement>, 'disabled' | 'onChange' | 'value'> {
  onChange: (date: string) => void;
  /** Undefined will be converted to null to keep the underlying DatePicker controlled */
  value?: string;
}

export const InputDate = forwardRef<HTMLDivElement, InputDateProps>(function InputDate(
  { className, isError, disabled, onChange, value, ...props },
  ref
) {
  let datePickerValue = null;
  try {
    datePickerValue = value ? parseDate(value) : null;
  } catch (e: unknown) {
    isError = true;
    // eslint-disable-next-line no-console
    console.error((e as Error).message);
  }

  const s = inputDate({ disabled, isError });

  return (
    <DatePicker
      aria-label={props['aria-label']}
      aria-labelledby={props.id}
      className={s.input({ className })}
      isDisabled={disabled}
      onChange={date => {
        onChange?.(date.toString());
      }}
      ref={ref}
      value={datePickerValue}
    >
      <Group className="flex w-full items-center justify-between px-4 py-[11px]">
        <DateInput className="flex">{segment => <DateSegment segment={segment} />}</DateInput>

        <Button
          isDisabled={disabled}
          className="ml-auto disabled:cursor-not-allowed"
          aria-label="Open date picker to select a date"
          id={props.id || props.name}
        >
          <Icon icon={calendarMonthRounded} size={16} />
        </Button>
      </Group>
      <Popover>
        <Dialog>
          <Calendar className="w-full rounded-md bg-white px-4 py-2 shadow">
            <header className="flex justify-between px-1 py-2 text-xl font-bold">
              <Button slot="previous" aria-label="Go to previous month">
                <Icon icon={arrowLeftAlt} size={16} />
              </Button>

              <Heading aria-label="Current month and year" />

              <Button slot="next" aria-label="Go to next month">
                <Icon icon={arrowRightAlt} size={16} />
              </Button>
            </header>

            <CalendarGrid>
              {date => (
                <CalendarCell
                  aria-label={date.toString()}
                  className="hover:bg-secondary-light data-[selected=true]:bg-secondary focus:bg-secondary-light p-2 text-center hover:rounded focus:rounded focus:outline-none data-[selected=true]:rounded data-[disabled=true]:text-white data-[selected=true]:text-white data-[disabled=true]:hover:cursor-default data-[disabled=true]:hover:bg-white"
                  date={date}
                />
              )}
            </CalendarGrid>
          </Calendar>
        </Dialog>
      </Popover>
    </DatePicker>
  );
});

export const inputDate = tv({
  base: 'flex w-fit items-center gap-2',
  extend: styles,
  slots: {
    input: 'flex items-center gap-4 p-0 rounded border border-input w-full data-[open=true]:border-primary',
  },
  variants: {},
});
