import { z } from 'zod';
import type { CprOrganisationDto, CprOrganisationFilter } from '#edsn/api/pie-bff';
import { MarketRole } from '#edsn/api/pie-bff';
import { createFiltersAtom } from './createFiltersAtom';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';
import type { ZodType } from 'zod';
import { getValues } from '#pie/utils/ZodUtils';

export const defaultCprOrganisationFilters = {
  marketPartyIds: [],
  marketRoles: [],
} satisfies z.infer<typeof cprOrganisationFiltersSchema>;

export const cprOrganisationFiltersSchema: ZodType<CprOrganisationFilter, z.ZodTypeDef, unknown> = z
  .object({
    marketPartyIds: z.array(z.string()).optional(),
    marketRoles: z.array(z.enum(getValues(MarketRole))).optional(),
  })
  .catch(defaultCprOrganisationFilters);

export const cprOrganisationFiltersAtom = createFiltersAtom<CprOrganisationFilter>({
  defaultFilters: defaultCprOrganisationFilters,
  filtersSchema: cprOrganisationFiltersSchema,
});

export const defaultCprOrganisationSorting: DefaultSorting<CprOrganisationDto> = {
  desc: false,
  id: 'marketParty.name',
};

export const cprOrganisationSortingAtom = createSortingAtom({ defaultSorting: defaultCprOrganisationSorting });
