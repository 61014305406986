import IconFilter from '@iconify/icons-material-symbols/filter-alt-outline';
import { useAtom, useAtomValue } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { searchAtom } from '../../stores/search';
import { Button } from '../button/Button';
import { InputSearch } from '../input-search/InputSearch';
import { KnowledgeArticleFilterPanel } from '../knowledge-article-filter-panel/KnowledgeArticleFilterPanel';
import { Stack } from '../stack/Stack';
import { knowledgeArticleFiltersAtom } from '#pie/stores/knowledgeArticle';

export const KnowledgeArticleToolbar = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useAtom(searchAtom);

  const activeFilters = useAtomValue(knowledgeArticleFiltersAtom);
  const activeFilterCount = Object.values(activeFilters)
    .flatMap(filter => filter)
    .filter(Boolean).length;

  return (
    <header className="px-3">
      <Stack direction="row" gap="md" className="items-center">
        <InputSearch
          placeholder={t('knowledge_base.article_table.header.search_placeholder')}
          onChange={e => setSearch(e.target.value)}
          value={search || ''}
          onClear={() => setSearch('')}
        />
        <Button variant="ghostPrimaryLight" size="sm" iconStart={IconFilter} onClick={() => setOpen(!open)}>
          {t('common.button.show_filters')} {!!activeFilterCount && `(${activeFilterCount})`}
        </Button>
      </Stack>
      <KnowledgeArticleFilterPanel open={open} onOpenChange={setOpen} />
    </header>
  );
};
