import IconScience from '@iconify/icons-material-symbols/science-outline';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../dialog/Dialog';
import type { DialogProps } from '../../dialog/Dialog';
import { Button } from '#pie/components/button/Button';
import { Icon } from '#pie/components/icon/Icon';
import { Text } from '#pie/components/text/Text';

interface BetaDialogProps extends Pick<DialogProps, 'open' | 'onOpenChange'> {
  to: string;
}

export const BetaDialog = ({ to, open, onOpenChange }: BetaDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} size="md" className="text-center" onOpenChange={onOpenChange}>
      <div className="mx-auto mb-1 grid h-14 w-14 items-center justify-center rounded-full bg-neutral-100">
        <Icon icon={IconScience} size={32} className="text-primary" />
      </div>

      <Text variant="h4" className="text-primary-dark mb-3">
        {t('beta_dialog.title')}
      </Text>

      <Text className="mb-6">{t('beta_dialog.description')}</Text>

      <div className="flex gap-4">
        <Button variant="ghost" className="flex-1" onClick={() => onOpenChange?.(false)}>
          {t('common.button.cancel')}
        </Button>

        <Button as="a" variant="primary" className="flex-1" href={to} target="_blank">
          {t('beta_dialog.button.leave')}
        </Button>
      </div>
    </Dialog>
  );
};
