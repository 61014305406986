import type { NotificationDto, NotificationSubcategoryDto } from '../generated/model';

export const createNotification = (overrides: Partial<NotificationDto> = {}): NotificationDto => ({
  email: 'jan.klaash@edsn.nl',
  enabled: true,
  subcategories: [
    createSubcategoryNotification(),
    createSubcategoryNotification(),
    createSubcategoryNotification({
      email: 'henk.deboot@enexis.nl',
      id: 'df14dd49-6be0-4340-98bd-46a59888ca0d',
      mainCategoryId: '1dad890a-7dc6-ea11-a812-000d3a21145f',
      mainCategoryName: 'GV Stamgegevens',
      subcategoryId: '072ff0c1-a1c6-ea11-a812-000d3a20f116',
      subcategoryName: 'GV Profielcode',
    }),
  ],
  ...overrides,
});

export const createSubcategoryNotification = (
  overrides: Partial<NotificationSubcategoryDto> = {}
): NotificationSubcategoryDto => ({
  email: 'piet.pietersen@enexis.nl',
  id: 'e62d452a-b227-4428-8d67-0367d29a3084',
  mainCategoryId: 'cf92e073-c004-eb11-a813-000d3ab9583d',
  mainCategoryName: 'Allocatie',
  subcategoryId: 'a832febc-c004-eb11-a813-000d3ab9583d',
  subcategoryName: 'Meetdata',
  ...overrides,
});
