import AddIcon from '@iconify/icons-material-symbols/add';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { CreateUpdateContactDto } from '#edsn/api/pie-bff';
import { getManagementContact, usePostCprManagementContactContactCreate } from '#edsn/api/pie-bff';
import { Button } from '../button/Button';
import { useToast } from '../toast/ToastContext';
import { ContactForm } from './ContactForm';
import type { SubmitHandler } from 'react-hook-form';
import { Dialog } from '#pie/components/layout/dialog/Dialog';

export const CreateContactModal = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { addToast } = useToast();

  const { mutate, isLoading: isPosting } = usePostCprManagementContactContactCreate({
    mutation: {
      onError: () => {
        addToast({
          message: t('cpr_contact_group_detail.create_user.toast.error.message'),
          title: t('cpr_contact_group_detail.create_user.toast.error.title'),
          type: 'error',
        });
      },
      onSuccess: (data, variables) => {
        if (data.id) {
          const { firstName, lastName } = variables.data;
          const name = `${firstName} ${lastName}`;
          gtag('event', 'create_contact', {});
          queryClient.invalidateQueries([getManagementContact.name]);
          queryClient.invalidateQueries(['/cpr-management-contact']);
          addToast({
            message: t('cpr_contact_group_detail.create_user.toast.success.message', { name }),
            title: t('cpr_contact_group_detail.create_user.toast.success.title'),
            type: 'success',
          });
          setIsOpen(false);
        }
      },
    },
  });

  const onSubmit: SubmitHandler<CreateUpdateContactDto> = data => {
    mutate({ data });
  };

  return (
    <>
      <Button iconStart={AddIcon} variant="secondary" onClick={() => setIsOpen(true)}>
        {t('cpr_management.contact_modal.button.new')}
      </Button>

      {isOpen && (
        <Dialog
          title={t('cpr_management.contact_modal.button.create')}
          size="md"
          open={isOpen}
          onOpenChange={setIsOpen}
        >
          <ContactForm
            isLoading={isPosting}
            onSubmit={onSubmit}
            submitText={t('cpr_management.contact_modal.button.create')}
          />
        </Dialog>
      )}
    </>
  );
};
