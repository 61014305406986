import CloseIcon from '@iconify/icons-material-symbols/close';
import FilterIcon from '@iconify/icons-material-symbols/filter-alt-outline';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { searchAtom } from '../../stores/search';
import { Button } from '../button/Button';
import { InputSearch } from '../input-search/InputSearch';
import { Stack } from '../stack/Stack';
import type { Filter } from '../data-filters/DataFilters';
import type { ComponentProps } from 'react';
import { IconButton } from '#pie/components/icon-button/IconButton';
import { Tag } from '#pie/components/tag/Tag';
import { Text } from '#pie/components/text/Text';
import { paramsAtom } from '#pie/stores/searchParams';

export interface Props<TFilter extends object> extends ComponentProps<'div'> {
  activeFilters: TFilter;
  filters: Filter<TFilter>[];
  setActiveFilters: (activeFilters: TFilter) => TFilter;
  setShowFilterPanel: (value: boolean) => void;
  showFilterPanel: boolean;
  showSearch?: boolean;
}

export const DataToolbar = <TFilter extends object>({
  activeFilters,
  filters,
  setActiveFilters,
  showFilterPanel,
  setShowFilterPanel,
  showSearch = true,
}: Props<TFilter>) => {
  const { t } = useTranslation();
  const setParams = useSetAtom(paramsAtom);
  const [search, setSearch] = useAtom(searchAtom);
  const currentFilters = filters.filter(filter => filter.values.length > 0);
  const hasFilters = Object.values(activeFilters).length > 0;

  const clearFilters = () => {
    setParams(new URLSearchParams());
  };

  return (
    <header className="px-3" role="toolbar">
      <Stack direction="row" gap="md" className="items-center">
        {showSearch && (
          <InputSearch
            value={search || ''}
            onChange={e => setSearch(e.target.value)}
            placeholder={t('common.search.placeholder')}
            onClear={() => setSearch('')}
          />
        )}
        <div className="flex flex-wrap items-center gap-2">
          {currentFilters.length > 0 && (
            <>
              <Text variant="sm" className="font-bold">
                {t('common.filtered_on')}
              </Text>
              {currentFilters.map(filter => (
                <Tag key={filter.id as string}>
                  {filter.label}
                  <IconButton
                    size={16}
                    icon={CloseIcon}
                    aria-label={t('common.button.remove_filter')}
                    onClick={() => setActiveFilters({ ...activeFilters, [filter.id]: [] })}
                  />
                </Tag>
              ))}
            </>
          )}
          {hasFilters && (
            <Button iconStart={CloseIcon} onClick={() => clearFilters()} size="sm" variant="ghostPrimaryLight">
              {t('common.button.clear_filters')}
            </Button>
          )}
          <Button
            aria-label={t('common.button.show_filters')}
            iconStart={FilterIcon}
            onClick={() => setShowFilterPanel(!showFilterPanel)}
            size="sm"
            variant="ghostPrimaryLight"
          >
            {t('common.button.show_filters')}
          </Button>
        </div>
      </Stack>
    </header>
  );
};
