import checkIcon from '@iconify/icons-material-symbols/check';
import { Icon } from '../../icon/Icon';
import type { PolymorphicProps } from '#pie/constants/interfaces';
import type { ElementType, PropsWithChildren } from 'react';
import { cn, tv } from '#pie/utils/TailwindUtils';

export interface Props extends PropsWithChildren {
  step: number;
  active?: boolean;
  done?: boolean;
}

export const StepperItem = <TComponent extends ElementType = 'button'>({
  active,
  as,
  children,
  className,
  done,
  step,
  onClick,
  ...props
}: PolymorphicProps<TComponent, Props>) => {
  const Component = as ?? 'button';
  const s = stepper({ active, done });
  return (
    <Component
      type="button"
      className={cn(s.base(), className)}
      disabled={active || !onClick}
      onClick={onClick}
      aria-label={`Step ${step}`}
      {...props}
    >
      <span className={s.ball()}>{done ? <Icon icon={checkIcon} className={s.icon()} aria-label="done" /> : step}</span>
      {children}
    </Component>
  );
};

const stepper = tv({
  base: 'flex items-center gap-2 font-semibold  text-neutral-400  group flex-shrink-0 text-sm align-middle',
  slots: {
    ball: 'h-7 w-7 rounded-full border border-action font-semibold justify-center items-center flex',
    icon: 'h-4 w-4',
  },
  variants: {
    active: {
      true: {
        ball: 'border-secondary shadow-focusInput group-hover:text-neutral-900 group-hover:border-secondary',
        base: 'text-neutral-900 hover:text-neutral-900',
      },
    },
    done: {
      true: {
        ball: 'bg-secondary border-secondary text-white group-hover:border-secondary group-hover:text-white',
      },
    },
  },
});
