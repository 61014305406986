import { Link } from 'react-router-dom';
import { Image } from '../image/Image';
import { Skeleton } from '../skeleton/Skeleton';
import { SkeletonLines } from '../skeleton-lines/SkeletonLines';
import { Stack } from '../stack/Stack';
import { Tag } from '../tag/Tag';
import { Text } from '../text/Text';
import type { PolymorphicProps } from '#pie/constants/interfaces';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import type { ElementType, ReactNode } from 'react';
import { getRelativeDate } from '#pie/i18n/dateFormats';
import { ConditionalWrapper } from '#pie/utils/ConditionalWrapper';
import { cn, tv } from '#pie/utils/TailwindUtils';

interface Props {
  title: string;
  description: ReactNode;
  categories?: string[];
  imageUrl?: string;
  date: Date;
  isLoading?: false;
}

interface LoadingProps extends Omit<Partial<Props>, 'isLoading'> {
  isLoading: true;
}

export type NewsCardProps<TComponent extends ElementType = typeof Link> = PolymorphicProps<
  TComponent,
  VariantProps<typeof newsCard>
> &
  (Props | LoadingProps);

export function NewsCard<TComponent extends ElementType = typeof Link>({
  title,
  description,
  categories,
  imageUrl,
  date,
  isLoading,
  as,
  to,
  className,
  ...props
}: NewsCardProps<TComponent>) {
  const style = newsCard();
  const Component = as ?? Link;

  return (
    <ConditionalWrapper
      condition={!isLoading && to}
      wrapper={children => (
        <Component to={to} className={style.base({ className })} {...props}>
          {children}
        </Component>
      )}
      elseWrapper={children => <div className={style.base({ className })}>{children}</div>}
    >
      {isLoading ? (
        <Skeleton className={style.image()} />
      ) : (
        !!imageUrl && <Image src={imageUrl} alt={title} className={style.image()} />
      )}

      <Stack gap="md" divider="line">
        <Stack gap="lg">
          <Stack gap="sm">
            <Text as="h4" variant="h4" className={style.title()}>
              {isLoading ? <Skeleton /> : title}
            </Text>
            <Text className="line-clamp-3">{isLoading ? <SkeletonLines lines={3} /> : description}</Text>
          </Stack>
          {((categories && categories.length > 0) || isLoading) && (
            <ul aria-label="tags" className={cn('flex flex-wrap items-center gap-2', style.categories())}>
              {(isLoading ? Array(2).fill('loading') : categories!).map((category: string, idx) => (
                <li key={idx}>
                  <Tag isActive={false}>{isLoading ? <Skeleton /> : category}</Tag>
                </li>
              ))}
            </ul>
          )}
        </Stack>
        <Text as="time" variant="sm" className="text-neutral-500">
          {isLoading ? <Skeleton className="w-12" /> : getRelativeDate(date)}
        </Text>
      </Stack>
    </ConditionalWrapper>
  );
}

export const newsCard = tv({
  base: 'rounded relative bg-transparent p-6 pb-4 shadow-sm flex flex-col gap-4 w-full',
  slots: {
    categories: 'flex',
    image: 'rounded w-full aspect-video h-auto',
    title: 'text-primary-dark line-clamp-2',
  },
});
