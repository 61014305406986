import chevronLeftIcon from '@iconify/icons-material-symbols/chevron-left';
import chevronRightIcon from '@iconify/icons-material-symbols/chevron-right';
import { Button } from '../button/Button';
import { InputDropdown } from '../input-dropdown/InputDropdown';
import { Skeleton } from '../skeleton/Skeleton';
import { Text } from '../text/Text';
import type { RowData, Table } from '@tanstack/react-table';
import { cn, tv } from '#pie/utils/TailwindUtils';

export interface PaginationProps<T extends RowData> {
  pageSizes?: number[];
  totalCount?: number;
  table: Table<T>;
  isLoading?: boolean;
}

export function Pagination<T extends RowData>({
  table,
  totalCount,
  pageSizes = [5, 10, 25, 50],
  isLoading,
}: PaginationProps<T>) {
  const styles = tableStyles();

  return (
    <div className={styles.base()}>
      <span className="flex items-center gap-3">
        <label className="contents">
          <Text variant="sm">Regels per pagina</Text>
          <InputDropdown
            value={table.getState().pagination.pageSize.toString()}
            onChange={value => {
              table.setPageSize(Number(value));
            }}
            name="page"
            options={pageSizes.map(pageSize => ({
              label: pageSize.toString(),
              value: pageSize.toString(),
            }))}
            required
          />
        </label>
        {isLoading ? (
          <Skeleton className="inline-flex w-20" />
        ) : (
          totalCount && <Text variant="sm">{totalCount} resultaten</Text>
        )}
      </span>
      <div className="flex items-center gap-3">
        <Button
          aria-label="Vorige pagina"
          iconStart={chevronLeftIcon}
          isDisabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
          variant="icon"
        />
        <input
          aria-label="Pagina"
          type="number"
          min="1"
          max={table.getPageCount()}
          value={table.getState().pagination.pageIndex + 1}
          onChange={e => {
            table.setPageIndex(+(e.target as HTMLInputElement).value - 1);
          }}
          className={cn('min-w-[4em]', styles.input())}
        />
        <Button
          aria-label="Volgende pagina"
          iconStart={chevronRightIcon}
          isDisabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
          variant="icon"
        />
        {(table.getPageCount() >= 0 || isLoading) && (
          <Text variant="sm" className="flex-1 whitespace-nowrap">
            van {isLoading ? <Skeleton className="inline-flex w-2" /> : table.getPageCount()}
          </Text>
        )}
      </div>
    </div>
  );
}

export const tableStyles = tv({
  base: 'bg-white rounded flex justify-between px-3',
  slots: {
    button:
      'rounded border border-neutral-200 px-4 py-3 hover:text-primary-light hover:bg-primary-lighter hover:cursor-pointer',
    icon: 'hover:text-primary-light',
    input: 'rounded border border-neutral-200 bg-white p-3',
  },
});
