import { format as formatDate } from 'date-fns';
import nlNL from 'date-fns/locale/nl';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = Record<PropertyKey, any>;
export type SetTypeForFields<T extends AnyObject, Fields extends Array<keyof T>, Type> = {
  [K in keyof T]: K extends Fields[number] ? Type : T[K];
};

/** Map given object fields to Date */
export const parseDates = <T extends AnyObject | undefined, Fields extends Array<keyof NonNullable<T>>>(
  obj: T,
  dateFields?: Fields
) => {
  if (obj === undefined) {
    return undefined as T extends undefined ? undefined : SetTypeForFields<NonNullable<T>, Fields, Date>;
  }

  return Object.fromEntries(
    Object.entries(obj).map(([field, value]) => [
      field,
      dateFields?.includes(field as keyof NonNullable<T>) && value ? new Date(value as string) : value,
    ])
  ) as T extends undefined ? undefined : SetTypeForFields<NonNullable<T>, Fields, Date>;
};

export const formatNl = (
  date: Date,
  format: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
  // TODO: or use intlFormat?
) => formatDate(date, format, { locale: nlNL, ...options });
