import type { PolymorphicProps } from '#pie/constants/interfaces';
import type { ElementType, PropsWithChildren } from 'react';

export interface StackItemProps extends PropsWithChildren {
  className?: string;
}

export function StackItem<TComponent extends ElementType>({
  as,
  ...props
}: PolymorphicProps<TComponent, StackItemProps>) {
  const Component = as ?? 'div';
  return <Component {...props} />;
}
