import Bolt from '@iconify/icons-material-symbols/bolt-outline';
import BreakingNewsIcon from '@iconify/icons-material-symbols/breaking-news-alt-1-outline';
import MoreHorizontalIcon from '@iconify/icons-material-symbols/more-horiz';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '../button/Button';
import { Card } from '../card/Card';
import { Divider } from '../divider/Divider';
import { Icon } from '../icon/Icon';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';
import { useUserHasRole } from '#pie/auth/useUserHasRole';
import { useStoredParams } from '#pie/stores/searchParams';

export const TicketSelection = () => {
  const canCreateNonConformity = useUserHasRole({ marketRole: 'MV' });
  const params = useStoredParams('/tickets');
  const { t } = useTranslation();

  return (
    <Card className="max-w-4xl p-6">
      <Stack gap="lg">
        <div>
          <Text as="h2" variant="h5" className="text-primary-dark">
            {t('tickets_create.ticket_type.title')}
          </Text>
        </div>
        <Stack gap="md">
          <Link to="ean">
            <div className="text-primary-dark hover:outline-primary border-action flex items-center gap-3 rounded border py-3 pl-3 hover:outline hover:outline-2">
              <div className="rounded-full bg-neutral-200 p-2">
                <Icon icon={Bolt} />
              </div>
              <Text as="h2" variant="base" className="text-primary-dark font-bold">
                {t('tickets_create.ticket_type.ean')}
              </Text>
            </div>
          </Link>
          <Link to="other">
            <div className="text-primary-dark hover:outline-primary border-action flex items-center gap-3 rounded border py-3 pl-3 hover:outline hover:outline-2">
              <div className="rounded-full bg-neutral-200 p-2">
                <Icon icon={MoreHorizontalIcon} />
              </div>
              <Text as="h2" variant="base" className="text-primary-dark font-bold">
                {t('tickets_create.ticket_type.other')}
              </Text>
            </div>
          </Link>
          {canCreateNonConformity && (
            <Link to="non-conformity">
              <div className="text-primary-dark hover:outline-primary border-action flex items-center gap-3 rounded border py-3 pl-3 hover:outline hover:outline-2">
                <div className="rounded-full bg-neutral-200 p-2">
                  <Icon icon={BreakingNewsIcon} />
                </div>
                <Text as="h2" variant="base" className="text-primary-dark font-bold">
                  {t('tickets_create.ticket_type.non_conformity')}
                </Text>
              </div>
            </Link>
          )}
        </Stack>
        <Divider />
        <div>
          <Button as={Link} to={`/tickets${params}`} variant="ghost">
            {t('common.button.cancel')}
          </Button>
        </div>
      </Stack>
    </Card>
  );
};
