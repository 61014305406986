/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type SurveyDialogView = (typeof SurveyDialogView)[keyof typeof SurveyDialogView];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SurveyDialogView = {
  All: 'All',
  Unread: 'Unread',
} as const;
