import { createColumnHelper, functionalUpdate } from '@tanstack/react-table';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { SelectDto } from '#edsn/api/pie-bff';
import { useGetCprContactFilters } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { InputSearch } from '#pie/components/input-search/InputSearch';
import { Stack } from '#pie/components/stack/Stack';
import { Table } from '#pie/components/table/Table';
import { Text } from '#pie/components/text/Text';
import { cprContactGroupSortingAtom } from '#pie/stores/cprContactGroup';
import { searchAtom } from '#pie/stores/search';

export const ContactPersonRegisterContactGroup = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCprContactFilters();
  const helper = useMemo(() => createColumnHelper<SelectDto>(), []);
  const [sorting, setSorting] = useAtom(cprContactGroupSortingAtom);
  const [search, setSearch] = useAtom(searchAtom);
  const contactGroups = data?.contactGroups ?? [];
  const filteredContactGroups = search
    ? contactGroups.filter(m => m.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    : contactGroups;

  return (
    <section className="flex items-start gap-6">
      <Card className="flex-1 overflow-x-hidden p-4">
        <Stack gap="md">
          <header className="px-3" role="toolbar">
            <Stack direction="row" gap="md" className="items-center">
              <InputSearch
                value={search || ''}
                onChange={e => setSearch(e.target.value)}
                placeholder={t('common.search.placeholder')}
                onClear={() => setSearch('')}
              />
            </Stack>
          </header>

          {!isLoading && !filteredContactGroups?.length ? (
            <Text className="p-3">{t('common.no_results')}</Text>
          ) : (
            <Table
              rowLinks={row => ({ 'aria-label': row.original.label, to: row.original.id })}
              columns={[
                helper.accessor('label', {
                  cell: info => (
                    <Link className="text-primary link" to={info.row.original.id} aria-label={info.getValue()}>
                      {info.getValue()}
                    </Link>
                  ),
                  header: t('cpr_overview.table.header.contact_group'),
                }),
              ]}
              sortDescFirst
              getRowId={m => m.id}
              data={filteredContactGroups}
              onSortingChange={sortingUpdater => {
                const newSortVal = functionalUpdate(sortingUpdater, sorting);
                setSorting(newSortVal);
              }}
              pagination={{ pageIndex: 0, pageSize: 20 }}
              sorting={sorting}
              isLoading={isLoading}
            />
          )}
        </Stack>
      </Card>
    </section>
  );
};
