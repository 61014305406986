// The jotai atoms orchestrate that window location search params are parsed and synced into the jotai store. This state is used on the ticket overview page (React Table and filter panel)
// When you interact with the ticket table or filters, the state is synced back into the window location.
// The state is mapped to query params for the GET /ticket call.

import { z } from 'zod';
import { TicketOrigin, TicketStatus, TicketView } from '#edsn/api/pie-bff';
import type { TicketFilter, TicketListDto } from '#edsn/api/pie-bff';
import { createFiltersAtom } from './createFiltersAtom';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';
import type { ZodType } from 'zod';
import { getValues } from '#pie/utils/ZodUtils';

// Schemas to validate the search params
const ticketStatuses = Object.values(TicketStatus) as [TicketStatus, ...TicketStatus[]];
const ticketOrigins = Object.values(TicketOrigin) as [TicketOrigin, ...TicketOrigin[]];

export const defaultTicketFilters = {
  ticketView: TicketView.InProgress,
};

export const ticketFiltersSchema: ZodType<TicketFilter, z.ZodTypeDef, unknown> = z
  .object({
    ean18: z.string().optional(),
    handlerIds: z.array(z.string()).optional(),
    issuerIds: z.array(z.string()).optional(),
    mainCategoryIds: z.array(z.string()).optional(),
    origins: z.array(z.enum(ticketOrigins)).optional(),
    regionalGridOperatorIds: z.array(z.string()).optional(),
    statuses: z.array(z.enum(ticketStatuses)).optional(),
    subCategoryIds: z.array(z.string()).optional(),
    teamIds: z.array(z.string().nullable()).optional(),
    ticketView: z.enum(getValues(TicketView)),
  })
  .catch(defaultTicketFilters);

export const ticketFiltersAtom = createFiltersAtom<TicketFilter>({
  defaultFilters: defaultTicketFilters,
  filtersSchema: ticketFiltersSchema,
});

export const defaultTicketSorting: DefaultSorting<TicketListDto> = {
  desc: true,
  id: 'createdOn',
};

export const ticketSortingAtom = createSortingAtom({ defaultSorting: defaultTicketSorting });
