import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import type { ContactDto, CreateUpdateContactDto } from '#edsn/api/pie-bff';
import { getManagementContact, usePutCprManagementContactContactId } from '#edsn/api/pie-bff';
import { useToast } from '../toast/ToastContext';
import { ContactForm } from './ContactForm';
import type { SubmitHandler } from 'react-hook-form';
import { Dialog } from '#pie/components/layout/dialog/Dialog';

export interface UpdateContactModalProps {
  data?: ContactDto;
  onClose: () => void;
}

export const UpdateContactModal = ({ data: defaultValues, onClose }: UpdateContactModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const isOpen = !!defaultValues;
  const setIsOpen = (val: boolean) => {
    if (!val) {
      onClose();
    }
  };

  const { mutate, isLoading: isPosting } = usePutCprManagementContactContactId({
    mutation: {
      onError: () => {
        addToast({
          message: t('cpr_contact_group_detail.update_user.toast.error.message'),
          title: t('cpr_contact_group_detail.update_user.toast.error.title'),
          type: 'error',
        });
      },
      onSuccess: (_, variables) => {
        const { firstName, lastName } = variables.data;
        const name = `${firstName} ${lastName}`;
        gtag('event', 'update_contact', {});
        queryClient.invalidateQueries([getManagementContact.name]);
        queryClient.invalidateQueries(['/cpr-management-contact']);
        addToast({
          message: t('cpr_contact_group_detail.update_user.toast.success.message', { name }),
          title: t('cpr_contact_group_detail.update_user.toast.success.title'),
          type: 'success',
        });
        onClose();
      },
    },
  });

  const onSubmit: SubmitHandler<CreateUpdateContactDto> = data => {
    mutate({ data, id: defaultValues!.id });
  };

  return (
    <>
      {isOpen && (
        <Dialog
          title={t('cpr_management.contact_modal.button.update')}
          size="md"
          open={isOpen}
          onOpenChange={setIsOpen}
        >
          <ContactForm
            isLoading={isPosting}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            submitText={t('cpr_management.contact_modal.button.update')}
          />
        </Dialog>
      )}
    </>
  );
};
