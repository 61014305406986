import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { menuStyles } from '../Menu';
import type { ComponentProps } from 'react';
import { Icon, type IconProps } from '#pie/components/icon/Icon';
import { cn } from '#pie/utils/TailwindUtils';

interface DropdownMenuProps extends ComponentProps<typeof DropdownMenu.Item> {
  icon?: IconProps['icon'];
}

export const MenuItem: React.FC<DropdownMenuProps> = ({ children, icon, className, ...props }) => {
  const styles = menuStyles();
  return (
    <DropdownMenu.Item className={cn(styles.item(), className)} {...props}>
      {icon && <Icon className={styles.icon()} icon={icon} />}
      {children}
    </DropdownMenu.Item>
  );
};
