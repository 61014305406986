import defaultTheme from 'tailwindcss/defaultTheme';
import { colors } from './colors/colors';
import type { Config } from 'tailwindcss';

export const screens = { ...defaultTheme.screens, '3xl': '1920px' };

export const tailwindTheme = {
  colors,
  container: {
    center: true,
    padding: '2rem',
  },
  extend: {
    animation: {
      'slide-down': 'slide-down-and-fade 400ms ease',
      'slide-left': 'slide-left-and-fade 400ms ease',
      'slide-right': 'slide-right-and-fade 400ms ease',
      'slide-up': 'slide-up-and-fade 400ms ease',
    },
    borderColor: {
      // TODO: Replace this with a color from the design system when there's a better design
      action: '#C7CFDD',
      input: '#C7CFDD',
    },
    boxShadow: {
      DEFAULT: '0 16px 32px 0 rgba(0, 0, 0, 0.03), 0 8px 16px 0 rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.04);',
      focusInput: '0 0 0 3px #e8f5ff',
      focusInputError: '0 0 0 2px #f4ebe9',
      // TODO: Replace this with a color from the design system when there's a better design
      // to make borders of radio checkbox components thicker as they're less visible due to their round shape
      inputEmphasis: '0 0 0 0.25px inset #C7CFDD',

      lg: '0 16px 48px 0 rgba(0, 0, 0, 0.06), 0 8px 32px 0 rgba(0, 0, 0, 0.06), 0 4px 16px 0 rgba(0, 0, 0, 0.06);',
      sm: '0 8px 16px rgba(0, 0, 0, 0.03), 0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.04)',
    },
    keyframes: {
      'slide-down-and-fade': {
        '0%': { opacity: '0', transform: 'translateY(-2px)' },
        '100%': { opacity: '1', transform: 'translateY(0px)' },
      },
      'slide-left-and-fade': {
        '0%': { opacity: '0', transform: 'translateX(2px)' },
        '100%': { opacity: '1', transform: 'translateX(0px)' },
      },
      'slide-right-and-fade': {
        '0%': { opacity: '0', transform: 'translateX(-2px)' },
        '100%': { opacity: '1', transform: 'translateX(0px)' },
      },
      'slide-up-and-fade': {
        '0%': { opacity: '0', transform: 'translateY(2px)' },
        '100%': { opacity: '1', transform: 'translateY(0px)' },
      },
    },
    outlineColor: {
      action: '#C7CFDD',
      input: '#C7CFDD',
    },
    zIndex: {
      '60': '60',
      '70': '70',
    },
  },
  fontFamily: {
    sans: ['"Source Sans Pro"', 'Helvetica', 'sans-serif'],
  },
  fontSize: {
    base: ['1rem', { fontWeight: '400', lineHeight: '1.5rem' }],

    display: ['2.5rem', { fontWeight: '700' }],
    h1: ['2.25rem', { fontWeight: '700', lineHeight: '1.25em' }],
    h2: ['2rem', { fontWeight: '700', lineHeight: '1.25em' }],
    h3: ['1.75rem', { fontWeight: '700', lineHeight: '1.25em' }],
    h4: ['1.5rem', { fontWeight: '700', lineHeight: '1.25em' }],
    h5: ['1.25rem', { fontWeight: '700', lineHeight: '1.25em' }],
    h6: ['1.125rem', { fontWeight: '700', lineHeight: '1.25em' }],

    lg: ['1.25rem', { fontWeight: '400', lineHeight: '1.688rem' }],
    sm: ['0.875rem', { fontWeight: '400', lineHeight: '1.313rem' }],
    subtitle: ['0.75rem', { fontWeight: '700' }],
    xl: ['1.125rem', { fontWeight: '400', lineHeight: '1.875rem' }],
    xs: ['0.75rem', { fontWeight: '400', lineHeight: '1.125rem' }],
    xxs: ['0.625rem', { fontWeight: '400', lineHeight: '0.938rem' }],
  },
  screens,
} satisfies Config['theme'];
