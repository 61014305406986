import { useTranslation } from 'react-i18next';
import { useGetConnectionInformation } from '#edsn/api/pie-bff';
import { FailureInfo } from '../failure-info/FailureInfo';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';

interface Props {
  electricityEan?: string | null;
  gasEan?: string | null;
}

export const EanFailure = ({ electricityEan, gasEan }: Props) => {
  const { t } = useTranslation();
  const { data: elecData } = useGetConnectionInformation(
    { ean18: electricityEan! },
    { query: { enabled: !!electricityEan } }
  );
  const { data: gasData } = useGetConnectionInformation({ ean18: gasEan ?? '' }, { query: { enabled: !!gasEan } });

  const hasElecFailure = !!(elecData?.failureEventCode && electricityEan);
  const hasGasFailure = !!(gasData?.failureEventCode && gasEan);

  return (
    (hasElecFailure || hasGasFailure) && (
      <Stack as="section" gap="md">
        <Text as="h2" variant="h5">
          {t('ticket_create.ean.error')}
        </Text>
        {/* when hasElecFailure is true, we know that there is a electricityEan */}
        {hasElecFailure && <FailureInfo ean18={electricityEan!} connection={elecData} />}
        {hasGasFailure && <FailureInfo ean18={gasEan!} connection={gasData} />}
      </Stack>
    )
  );
};
