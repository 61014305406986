import { Icon, type IconProps } from '../../icon/Icon';
import type { PropsWithChildren } from 'react';
import { StackItem } from '#pie/components/stack/Stack';
import { cn } from '#pie/utils/TailwindUtils';

export interface MetaInfoItemProps extends PropsWithChildren {
  icon?: IconProps['icon'];
  label?: string;
  className?: string;
}

export function MetaInfoItem({ icon, className, children, label }: MetaInfoItemProps) {
  return (
    <StackItem as="li" className={cn('text-small flex items-center text-neutral-500', className)}>
      {!!icon && <Icon icon={icon} size={16} className="mr-1" />}
      <span>
        {label && <span className="text-neutral-600">{label}: </span>}
        {children}
      </span>
    </StackItem>
  );
}
