import type { ReactNode } from 'react';
import { cn } from '#pie/utils/TailwindUtils';

export interface PageProps {
  children?: ReactNode[] | ReactNode;
  className?: string;
}

export function Page({ children, className }: PageProps) {
  return <div className={cn('px-6', className)}>{children}</div>;
}

export * from './page-header/PageHeader';
