import { type ComponentProps, type KeyboardEventHandler, type PropsWithChildren, useRef } from 'react';
import { Button } from '../button/Button';
import type { IconProps } from '../icon/Icon';
import { cn } from '#pie/utils/TailwindUtils';

export interface InputUploadButtonProps extends PropsWithChildren, Omit<ComponentProps<'input'>, 'onChange'> {
  className?: string;
  variant?: 'grey' | 'ghost';
  icon: IconProps['icon'];
  disabled?: boolean;
  multiple?: boolean;
  acceptedFiles?: string;
  onChange?: (value: File[]) => void;
}

export function InputUploadButton({
  className,
  variant,
  icon,
  children,
  disabled,
  multiple,
  acceptedFiles,
  onChange,
  ...props
}: InputUploadButtonProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown: KeyboardEventHandler<HTMLLabelElement> = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      fileInputRef.current?.click();
    }
  };

  return (
    <Button
      as="label"
      className={cn('relative cursor-pointer px-3', className)}
      iconStart={icon}
      isDisabled={disabled}
      onKeyDown={handleKeyDown}
      role="button"
      size="lg"
      tabIndex={disabled ? -1 : 0}
      variant={variant}
    >
      <input
        accept={acceptedFiles}
        className="absolute inset-0 z-[-1] opacity-0"
        disabled={disabled}
        multiple={multiple}
        onChange={event => event.target.files && onChange?.([...event.target.files])}
        ref={fileInputRef}
        tabIndex={-1}
        type="file"
        {...props}
      />
      {children}
    </Button>
  );
}
