import type { CprManagementContactDto } from '#edsn/api/pie-bff';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';

export const defaultCprManagementContactDetailSorting: DefaultSorting<CprManagementContactDto> = {
  desc: false,
  id: 'name',
};

export const cprManagementContactDetailSortingAtom = createSortingAtom({
  defaultSorting: defaultCprManagementContactDetailSorting,
});
