import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { CommentReferenceType, useGetSurveyId } from '#edsn/api/pie-bff';
import { ErrorPage } from '../error-page/ErrorPage';
import { SurveyDialogueReadToggle } from './SurveyDialogueReadToggle';
import { Card } from '#pie/components/card/Card';
import { Comments } from '#pie/components/comments/Comments';
import { CopyButton } from '#pie/components/copy-button/CopyButton';
import { DescriptionList, DescriptionListItem } from '#pie/components/description-list/DescriptionList';
import { MetaInfo, MetaInfoItem } from '#pie/components/meta-info/MetaInfo';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Skeleton } from '#pie/components/skeleton/Skeleton';
import { SkeletonLines } from '#pie/components/skeleton-lines/SkeletonLines';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { getFormattedDate } from '#pie/i18n/dateFormats';
import { parseDates } from '#pie/utils/date';

export const SurveyDialogueDetail = () => {
  const id = useParams()['id']!;
  const { t } = useTranslation();
  const { data: rawData, isLoading } = useGetSurveyId(id!);
  const data = parseDates(rawData, ['createdOn']);

  if (!data && !isLoading) {
    return (
      <ErrorPage
        heading={t('survey_dialogue_detail.error_page.not_found.title')}
        message={t('survey_dialogue_detail.error_page.not_found.message')}
      />
    );
  }

  return (
    <>
      <PageHead title={data?.ticketTitle} />
      <Page>
        <PageHeader
          details={
            <MetaInfo className="pt-4 text-sm">
              <MetaInfoItem label={t('common.submitted_on')}>
                {isLoading ? (
                  <Skeleton className="w-[6rem]" />
                ) : (
                  `${data?.createdOn && getFormattedDate(data.createdOn, false)} door ${data?.createdBy?.label}`
                )}
              </MetaInfoItem>
            </MetaInfo>
          }
          title={
            isLoading ? <Skeleton className="w-[50%]" /> : data?.subject && t(`survey_dialogue.subject.${data.subject}`)
          }
          backHref={`/ticketfeedback`}
          isLoading={isLoading}
        >
          <div className="flex gap-4">
            <SurveyDialogueReadToggle id={id} />
          </div>
        </PageHeader>
        <div className="flex flex-col gap-6 px-0 py-6 md:flex-row">
          <Stack gap="lg" className="flex-[2]">
            <Stack gap="sm">
              <Text as="h2" variant="h5">
                {t('survey_dialogue_detail.explanation')}
              </Text>
              <Text className="whitespace-break-spaces">
                {isLoading ? (
                  <>
                    <SkeletonLines lines={5} />
                    <Skeleton className="w-[50%]" />
                  </>
                ) : (
                  data?.explanation
                )}
              </Text>
            </Stack>
            <Comments
              referenceId={id!}
              referenceType={CommentReferenceType.SurveyDialogue}
              canComment
              canAddAttachments={false}
            />
          </Stack>
          <aside className="flex flex-1 flex-col gap-6">
            <Card
              size="md"
              shadow={false}
              heading={<Text variant="h6">{t('survey_dialogue_detail.feedback_details')}</Text>}
            >
              <DescriptionList className="flex-col">
                <DescriptionListItem title={t('survey_dialogue_detail.status')}>
                  {isLoading ? (
                    <Skeleton className="w-[50%]" />
                  ) : (
                    data?.status && t(`survey_dialogue.status.${data.status}`)
                  )}
                </DescriptionListItem>
                <DescriptionListItem title={t('survey_dialogue_detail.handling_ticket')}>
                  {isLoading ? (
                    <Skeleton className="w-[50%]" />
                  ) : (
                    data?.effortScore && t(`survey_dialogue.effort_score.${data.effortScore}`)
                  )}
                </DescriptionListItem>
              </DescriptionList>
            </Card>
            <Card
              size="md"
              shadow={false}
              heading={<Text variant="h6">{t('survey_dialogue_detail.ticket_details')}</Text>}
            >
              <DescriptionList className="flex-col">
                <DescriptionListItem title={t('common.ticket_number')}>
                  {isLoading ? (
                    <Skeleton className="w-[40%]" />
                  ) : (
                    data?.ticketNumber && (
                      <CopyButton content={data.ticketNumber}>
                        <Link to={`/tickets/${data.ticketId}`}>{data.ticketNumber}</Link>
                      </CopyButton>
                    )
                  )}
                </DescriptionListItem>
                <DescriptionListItem title={t('common.handler')}>
                  {isLoading ? <Skeleton className="w-[50%]" /> : data?.regionalGridOperatorHandler?.label}
                </DescriptionListItem>
                <DescriptionListItem title={t('common.regional_grid_operator')}>
                  {isLoading ? <Skeleton className="w-[50%]" /> : data?.regionalGridOperator?.label}
                </DescriptionListItem>
              </DescriptionList>
            </Card>
          </aside>
        </div>
      </Page>
    </>
  );
};
