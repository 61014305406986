import { Skeleton } from '../skeleton/Skeleton';
import type { ComponentProps } from 'react';
import { cn } from '#pie/utils/TailwindUtils';

interface Props extends ComponentProps<'span'> {
  lines: number;
}

export const SkeletonLines: React.FC<Props> = ({ className, lines = 1, ...props }) => {
  return (
    <span
      role="progressbar"
      aria-live="polite"
      aria-busy="true"
      className={cn('w-full select-none overflow-hidden whitespace-pre-line', className)}
      {...props}
    >
      {new Array(lines).fill(null).map((_, index) => (
        <Skeleton key={`skeleton-${index}`} />
      ))}
    </span>
  );
};
