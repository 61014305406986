import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { Button } from '../button/Button';
import { Dialog } from '../layout/dialog/Dialog';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';

export const CancelCreateTicketDialog = ({ shouldBlock }: { shouldBlock: boolean }) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => shouldBlock && currentLocation.pathname !== nextLocation.pathname
  );

  const { t } = useTranslation();

  return (
    <Dialog open={blocker.state === 'blocked'} title="Ticket aanmaken annuleren" onOpenChange={() => blocker.reset?.()}>
      <Text className="mb-6">{t('ticket_create.cancel_ticket_description')}</Text>
      <Stack direction="row" gap="md">
        <Button className="flex-1" onClick={() => blocker.reset?.()} type="submit" variant="ghost">
          {t('ticket_create.cancel_button_cancel')}
        </Button>
        <Button className="flex-1" onClick={() => blocker.proceed?.()} variant="danger">
          {t('ticket_create.cancel_button_confirm')}
        </Button>
      </Stack>
    </Dialog>
  );
};
