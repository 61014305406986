/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type EffortScore = (typeof EffortScore)[keyof typeof EffortScore];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EffortScore = {
  HighlyDissatisfied: 'HighlyDissatisfied',
  Dissatisfied: 'Dissatisfied',
  Neutral: 'Neutral',
  Satisfied: 'Satisfied',
  HighlySatisfied: 'HighlySatisfied',
} as const;
