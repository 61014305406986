import { useTranslation } from 'react-i18next';
import { useGetTicket } from '#edsn/api/pie-bff';
import type { TicketsColumnName } from '#pie/components/ticket-table/TicketTable';
import { Table, useTableState } from '#pie/components/table/Table';
import { ticketsColumnParse } from '#pie/components/ticket-table/TicketTable';
import { parseDates } from '#pie/utils/date';

export const TicketTableEan: React.FC<{ ean?: string }> = ({ ean }) => {
  const { t } = useTranslation();
  const { onPaginationChange, onSortingChange, pagination, sorting } = useTableState({
    defaultSort: [
      {
        desc: true,
        id: 'createdOn',
      },
    ],
    pageSizes: [5, 10],
  });

  const { data, isLoading } = useGetTicket(
    {
      filter: {
        ean18: ean,
        ticketView: 'All',
      },
      pagination,
      sorting: sorting[0] && { descending: sorting[0].desc, field: sorting[0].id },
    },
    {
      query: {
        select: data => ({
          ...data,
          items: data.items?.map(item =>
            parseDates(item, [
              'createdOn',
              'eventDate',
              'modifiedOn',
              'handledByRegionalGridOperatorDate',
              'processDate',
            ])
          ),
        }),
      },
    }
  );

  const columns: readonly TicketsColumnName[] = [
    'ticketNumber',
    'title',
    'regionalGridOperator',
    'status',
    'createdOn',
  ];

  const pageCount = data?.totalCount && data.totalCount ? Math.ceil(data.totalCount / pagination.pageSize) : -1;

  return isLoading || (ean && !!(data?.totalCount && data?.totalCount > 0)) ? (
    <Table
      label={`Tickets voor EAN ${ean}`}
      pagination={pagination}
      sorting={sorting}
      rowLinks={row => ({
        'aria-label': row.original.ticketNumber,
        target: '_blank',
        to: `/tickets/${row.original.id}`,
      })}
      columns={columns.map(columnName => ticketsColumnParse({ t })[columnName])}
      data={data?.items}
      isLoading={isLoading && 5}
      onPaginationChange={onPaginationChange}
      onSortingChange={onSortingChange}
      totalCount={data?.totalCount}
      getSortedRowModel={undefined}
      getFilteredRowModel={undefined}
      pageCount={pageCount}
    />
  ) : (
    <>Er zijn geen eerdere tickets bekend.</>
  );
};
