import { z } from 'zod';
import type { KnowledgeArticleFilter, KnowledgeArticleListDto } from '#edsn/api/pie-bff';
import { createFiltersAtom } from './createFiltersAtom';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';
import type { ZodType } from 'zod';

export const defaultKnowledgeArticleFilters = {
  mainCategoryIds: [''],
  subCategoryIds: [''],
};
export const knowledgeArticleFiltersSchema: ZodType<KnowledgeArticleFilter, z.ZodTypeDef, unknown> = z
  .object({
    mainCategoryIds: z.array(z.string()).optional(),
    subCategoryIds: z.array(z.string()).optional(),
  })
  .catch(defaultKnowledgeArticleFilters);

export const knowledgeArticleFiltersAtom = createFiltersAtom<KnowledgeArticleFilter>({
  defaultFilters: defaultKnowledgeArticleFilters,
  filtersSchema: knowledgeArticleFiltersSchema,
});

export const defaultKnowledgeArticleSorting: DefaultSorting<KnowledgeArticleListDto> = {
  desc: true,
  id: 'createdOn',
};

export const knowledgeArticleSortingAtom = createSortingAtom({ defaultSorting: defaultKnowledgeArticleSorting });
