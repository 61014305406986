import type { Tag } from '#edsn/pie-public-trpc';

export const getTagList = (tags: Tag[], numberOfVisibleTags = 2) => {
  if (numberOfVisibleTags < 0) {
    throw new Error('The number of visible tags should be positive');
  }
  return tags
    .slice(0, numberOfVisibleTags)
    .map(({ title }) => title)
    .concat(tags?.length > numberOfVisibleTags ? `+${tags?.length - numberOfVisibleTags}` : []);
};
