import * as Sentry from '@sentry/react';

export const initSentry = (
  options: Required<Pick<Sentry.BrowserOptions, 'dsn'>> & Pick<Sentry.BrowserOptions, 'integrations'>,
  maskAllText = true
) =>
  Sentry.init({
    environment: import.meta.env.MODE,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({ maskAllText })],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 1.0,
    ...options,
  });
