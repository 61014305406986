import { z } from 'zod';
import { type CprContactDto, type CprContactFilter, MarketRole } from '#edsn/api/pie-bff';
import { createFiltersAtom } from './createFiltersAtom';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';
import type { ZodType } from 'zod';
import { getValues } from '#pie/utils/ZodUtils';

export const defaultCprContactGroupDetailFilters = {
  marketPartyIds: [],
  marketRoles: [],
  organisationIds: [],
} satisfies z.infer<typeof cprContactGroupDetailFiltersSchema>;

export const cprContactGroupDetailFiltersSchema: ZodType<CprContactFilter, z.ZodTypeDef, unknown> = z
  .object({
    marketPartyIds: z.array(z.string()).optional(),
    marketRoles: z.array(z.enum(getValues(MarketRole))).optional(),
    organisationIds: z.array(z.string()).optional(),
  })
  .catch(defaultCprContactGroupDetailFilters);

export const cprContactGroupDetailFiltersAtom = createFiltersAtom<CprContactFilter>({
  defaultFilters: defaultCprContactGroupDetailFilters,
  filtersSchema: cprContactGroupDetailFiltersSchema,
});

export const defaultCprContactGroupDetailSorting: DefaultSorting<CprContactDto> = {
  desc: false,
  id: 'marketParty.name',
};

export const cprContactGroupDetailSortingAtom = createSortingAtom({
  defaultSorting: defaultCprContactGroupDetailSorting,
});
