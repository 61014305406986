import { parseEnv, z } from 'znv';

export const {
  VITE_API_URI,
  MODE,
  VITE_CHAT_APP_ID,
  VITE_CHAT_COLOR_OVERRIDE,
  VITE_CHAT_ORG_ID,
  VITE_CHAT_ORG_URL,
  VITE_CHAT_WIDGET_URI,
  VITE_CMS_TOKEN,
  VITE_CMS_URL,
  VITE_GOOGLE_ANALYTICS_METRIC_ID,
  VITE_IDP_URI,
  VITE_MOCK_API,
  VITE_MOCK_CMS,
  VITE_MOCK_IDP,
  VITE_MOCK_TRPC,
  VITE_NON_CONFORMITY_CATEGORY_ID,
  VITE_PUBLIC_URL,
  VITE_SENTRY_DSN,
  VITE_TRPC_API_URI,
} = parseEnv(import.meta.env, {
  MODE: z.enum(['acc', 'dev', 'lcl', 'test', 'prd', 'production', 'development']),
  VITE_API_URI: z.string().url(),
  VITE_CHAT_APP_ID: z.string().optional(),
  VITE_CHAT_COLOR_OVERRIDE: z.string().min(4).max(9).regex(/^#/).optional(),
  VITE_CHAT_ORG_ID: z.string().optional(),
  VITE_CHAT_ORG_URL: z.string().url().optional(),
  VITE_CHAT_WIDGET_URI: z.string().url().optional(),
  VITE_CMS_TOKEN: z.string(),
  VITE_CMS_URL: z.string().url(),
  VITE_GOOGLE_ANALYTICS_METRIC_ID: z.string().optional(),
  VITE_IDP_URI: z.string().url(),
  VITE_MOCK_API: z.boolean().optional().default(false),
  VITE_MOCK_CMS: z.boolean().optional().default(false),
  VITE_MOCK_IDP: z.boolean().optional().default(false),
  VITE_MOCK_TRPC: z.boolean().optional().default(false),
  VITE_NON_CONFORMITY_CATEGORY_ID: z.string(),
  VITE_PUBLIC_URL: z.string().url(),
  VITE_SENTRY_DSN: z.string().url().optional(),
  VITE_TRPC_API_URI: z.string().url(),
});
