import type { SelectDto } from '#edsn/api/pie-bff';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';

export const defaultCprContactGroupSorting: DefaultSorting<SelectDto> = {
  desc: false,
  id: 'label',
};

export const cprContactGroupSortingAtom = createSortingAtom({ defaultSorting: defaultCprContactGroupSorting });
