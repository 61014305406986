import { zodResolver } from '@hookform/resolvers/zod';
import addIcon from '@iconify/icons-material-symbols/add';
import infoIcon from '@iconify/icons-material-symbols/info-outline';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import type { CommentReferenceType, CreateCommentDto } from '#edsn/api/pie-bff';
import { getGetCommentQueryKey, usePostCommentCreate } from '#edsn/api/pie-bff';
import { Button } from '../button/Button';
import { Divider } from '../divider/Divider';
import { FileAnchor } from '../file-anchor/FileAnchor';
import { validation } from '../form/formHelpers';
import { styles } from '../input-text/InputText';
import { InputUploadButton } from '../input-upload-button/InputUploadButton';
import { Message } from '../message/Message';
import { Text } from '../text/Text';
import { UserAvatar } from '../user-avatar/UserAvatar';
import type { ClipboardEvent, DragEvent } from 'react';
import { MAX_FILE_SIZE } from '#pie/constants/maxFileSize';
import { encodeHtml } from '#pie/utils/encodeHtml';
import { formatBytes } from '#pie/utils/files';
import { isValidXmlCharacters } from '#pie/utils/isValidXmlCharacters';
import { cn } from '#pie/utils/TailwindUtils';
import { typedFormFields } from '#pie/utils/typedFormFields';

export interface ReplyFieldProps {
  referenceId: string;
  referenceType: CommentReferenceType;
  className?: string;
  startExpanded?: boolean;
  maxFileSize?: number;
  canAddAttachments?: boolean;
}

const replySchema = (maxFileSize: number) =>
  z.object({
    description: z.string().refine(value => isValidXmlCharacters(value), {
      message: t('zod.errors.invalid_xml_characters'),
    }),
    files: z
      .array(
        z.object({
          file: z
            .instanceof(File)
            .refine(
              file => file.size <= maxFileSize,
              t('zod.errors.file_too_large', { size: formatBytes(maxFileSize) })
            ),
        })
      )
      .max(20, t('zod.errors.max_files', { max: 20 })),
  });

type ReplyForm = z.infer<ReturnType<typeof replySchema>>;

const { FormField, FormTextArea } = typedFormFields<ReplyForm>();

export function ReplyField({
  className,
  referenceType,
  startExpanded = false,
  referenceId,
  maxFileSize = MAX_FILE_SIZE,
  canAddAttachments = true,
}: ReplyFieldProps) {
  const queryClient = useQueryClient();
  const [collapsed, setCollapsed] = useState(!startExpanded);

  const resolver = zodResolver(replySchema(maxFileSize));
  const formMethods = useForm<ReplyForm>({
    resolver,
  });
  const {
    control,
    reset,
    formState: { errors, isValid, isSubmitted },
    handleSubmit,
  } = formMethods;

  const { remove, replace, fields, append } = useFieldArray<ReplyForm>({
    control,
    name: 'files',
  });

  const {
    mutateAsync: commentMutateAsync,
    isSuccess: commentIsSuccess,
    isError: commentIsError,
    isLoading: commentIsLoading,
  } = usePostCommentCreate({
    mutation: {
      onSuccess: () => {
        replace([]);
        gtag('event', 'comment');
      },
    },
  });

  const addFiles = (files: File[]) => {
    if (files.length) {
      const _files = files.map((file: File) => {
        return { file };
      });
      append(_files);
    }
  };

  const onSubmit: SubmitHandler<ReplyForm> = async ({ description }) => {
    try {
      const data: CreateCommentDto = {
        description: encodeHtml(description),
        referenceId,
        referenceType: referenceType,
      };
      if (canAddAttachments) {
        data.attachments = fields.filter(({ file }) => file).map(({ file }) => file);
      }
      await commentMutateAsync({
        data,
      });
      queryClient.invalidateQueries(
        getGetCommentQueryKey({
          referenceId,
          referenceType: referenceType,
        })
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    if (commentIsSuccess) {
      reset();
      setCollapsed(true);
    }
  }, [commentIsSuccess, reset]);

  const inputStyles = styles({ isError: false });

  const onPaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    if (e.clipboardData.files.length) {
      addFiles([...e.clipboardData.files]);
    }
  };

  const onDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files.length) {
      addFiles([...e.dataTransfer.files]);
    }
  };

  return (
    <div className={cn('flex items-start gap-4', className)}>
      <UserAvatar className="mt-2" />
      <FormProvider {...formMethods}>
        <form
          className="flex w-full flex-col gap-6"
          onSubmit={handleSubmit(onSubmit)}
          onDragOver={e => e.preventDefault()}
          onDrop={canAddAttachments && !collapsed ? onDrop : undefined}
        >
          {collapsed ? (
            <button
              onClick={() => setCollapsed(false)}
              className={cn(inputStyles.input(), 'h-12')}
              aria-label="Opmerking invoeren"
            />
          ) : (
            <>
              <FormTextArea
                rules={{ required: validation.required }}
                aria-label="opmerking"
                name="description"
                className="py-0"
                rows={8}
                autoFocus={!startExpanded}
                showWordCount
                maxLength={2000}
                fullWidth
                onPaste={canAddAttachments ? onPaste : undefined}
              />

              {canAddAttachments && (
                <FormField name="files" label="Voeg bijlages toe">
                  {fields.length > 0 && (
                    <div className="mb-4 flex flex-col">
                      {fields.map(({ file }, idx) => (
                        <div key={idx}>
                          {idx !== 0 && <Divider className="my-3" />}
                          <FileAnchor fileName={file.name} fileSize={file.size} onDelete={() => remove(idx)} />
                          {errors.files?.[idx] && (
                            <Message variant="error" icon={infoIcon} className="mt-5">
                              <Text>{errors.files?.[idx]?.file?.message}</Text>
                            </Message>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  <InputUploadButton icon={addIcon} variant="ghost" className="w-fit" multiple onChange={addFiles}>
                    Bestanden kiezen
                  </InputUploadButton>
                </FormField>
              )}

              {commentIsError && (
                <Message variant="error" icon={infoIcon}>
                  <Text>Opmerking kon niet geplaatst worden door een fout.</Text>
                </Message>
              )}

              <Button
                className="w-fit"
                isDisabled={isSubmitted && !isValid}
                isLoading={commentIsLoading}
                type="submit"
                variant="secondary"
              >
                Plaats opmerking
              </Button>
            </>
          )}
        </form>
      </FormProvider>
    </div>
  );
}
