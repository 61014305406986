import type { CommentDto } from '#edsn/api/pie-bff/generated/model';

export const createComment = (overrides?: Partial<CommentDto>): CommentDto => ({
  attachments: [
    {
      bytes: 123,
      fileName: 'Example document',
      id: '0',
    },
    {
      bytes: 123,
      fileName: 'Name file',
      id: '1',
    },
  ],
  createdBy: {
    id: 'af2ceb09-03ff-a1cf-a6e9-82ead914d5be',
    label: 'Yfke Berg',
  },
  createdOn: '2023-07-29T04:40:47.6982497Z',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris suscipit bibendum ex a pharetra. Integer aliquam leo dui, et sodales velit imperdiet eget. Phasellus mollis leo arcu, non dapibus orci suscipit in. Quisque in turpis tortor. Sed non elit ullamcorper sapien sodales suscipit vel id turpis. Donec ultricies lobortis aliquet. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed lacinia, sem quis mattis ultrices, magna massa fringilla erat, sit amet vulputate sapien dui sit amet massa. Proin posuere ex vehicula diam tincidunt, quis volutpat lorem interdum. Nulla volutpat malesuada ex sit amet ultricies. Aenean id porttitor dui. Vivamus at mattis neque, a luctus nisi. Vivamus eu felis rutrum, viverra lacus vitae, consequat turpis. Nunc ac est sed nulla sollicitudin pulvinar eu in neque.Aenean at enim egestas justo dignissim dapibus eu in ex. Vestibulum luctus pretium ipsum faucibus eleifend. Aliquam turpis magna, vehicula sit amet imperdiet pellentesque, facilisis ac sapien. Nullam in lectus vel dui malesuada varius. Etiam sagittis mi non accumsan interdum. Curabitur in massa libero. Sed fringilla nec ligula eu faucibus. Proin nibh enim, imperdiet id vestibulum efficitur, dignissim eu lectus. Etiam vel ex eros. Aliquam facilisis tincidunt neque et convallis. Integer faucibus congue pretium. Integer in odio libero.',
  id: 'f1e123c1-a41f-a18c-59e4-35702712f78c',
  ...overrides,
});
