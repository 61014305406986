import { useOidc } from '@axa-fr/react-oidc';
import { HeaderProfile } from './profile/HeaderProfile';

export const Header = () => {
  const { isAuthenticated } = useOidc();

  return (
    <header className="flex gap-6 border-b border-b-neutral-200 bg-white px-6 py-3">
      {isAuthenticated && <HeaderProfile />}
    </header>
  );
};
