import type { FooterNavigationSummaryQuery, FooterPartnersSummaryQuery } from '../generated/graphql';

export const createGQLFooterNavigation = (): FooterNavigationSummaryQuery => ({
  navigationNodes: [
    {
      __typename: 'footerMain_Node',
      id: '1',
      nodeUri: 'voor-wie',
      title: 'Voor wie',
    },
    {
      __typename: 'footerMain_Node',
      id: '2',
      nodeUri: 'voor-wie/over-het-platform',
      title: 'Over het platform',
    },
    {
      __typename: 'footerMain_Node',
      id: '3',
      nodeUri: 'voor-wie/marktpartijen',
      title: 'Marktpartijen',
    },
    {
      __typename: 'footerMain_Node',
      id: '4',
      nodeUri: 'voor-wie/marktpartijen/meetbedrijven',
      title: 'Meetbedrijven',
    },
    {
      __typename: 'footerMain_Node',
      id: '5',
      nodeUri: 'voor-wie/marktpartijen/congestie-service-providers',
      title: 'Congestie Service Providers',
    },
    {
      __typename: 'footerMain_Node',
      id: '6',
      nodeUri: 'voor-wie/marktpartijen/onafhankelijke-dienstaanbieder',
      title: 'Onafhankelijke dienstaanbieder',
    },
    {
      __typename: 'footerMain_Node',
      id: '7',
      nodeUri: 'voor-wie/particulier',
      title: 'Particulier',
    },
    {
      __typename: 'footerMain_Node',
      id: '8',
      nodeUri: 'services',
      title: 'Diensten',
    },
    {
      __typename: 'footerMain_Node',
      id: '9',
      nodeUri: 'diensten/registreren-marktpartijen',
      title: 'Registreren marktpartijen',
    },
    {
      __typename: 'footerMain_Node',
      id: '10',
      nodeUri: 'diensten/dataverzoeken-en-open-data',
      title: 'Dataverzoeken en open data',
    },
    {
      __typename: 'footerMain_Node',
      id: '11',
      nodeUri: 'diensten/belangstelling-registreren-flex-aanbod',
      title: 'Belangstelling registreren flex aanbod',
    },
    {
      __typename: 'footerMain_Node',
      id: '12',
      nodeUri: 'diensten/contactpersoon-registratie',
      title: 'Contactpersoon registratie',
    },
    {
      __typename: 'footerMain_Node',
      id: '13',
      nodeUri: 'diensten/interactie-en-meldingen-marktpartijen',
      title: 'Interactie en meldingen marktpartijen',
    },
    {
      __typename: 'footerMain_Node',
      id: '14',
      nodeUri: 'themas',
      title: "Thema's",
    },
    {
      __typename: 'footerMain_Node',
      id: '15',
      nodeUri: 'nieuws',
      title: 'Nieuws',
    },
    {
      __typename: 'footerMain_Node',
      id: '16',
      nodeUri: 'over-ons',
      title: 'Over ons',
    },
  ],
});

export const createGQLFooterSubNavigation = (): FooterPartnersSummaryQuery => ({
  footerPartners: new Array(8).fill(null).map((_, i) => ({
    __typename: 'footerPartners_Node',
    id: i.toString(),
    image: [{ url: 'https://picsum.photos/id/22/367/267' }],
    nodeUri: 'https://www.facebook.com/',
    title: 'Facebook',
  })),
  footerSub: [
    {
      __typename: 'footerSub_Node',
      id: '1',
      nodeUri: '/privacy',
      title: 'Privacy',
    },
    {
      __typename: 'footerSub_Node',
      id: '2',
      nodeUri: '/disclaimer',
      title: 'Disclaimer',
    },
  ],
});
