import type { CprManagementContactDto } from '#edsn/api/pie-bff';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';

export const defaultCprManagementContactSorting: DefaultSorting<CprManagementContactDto> = {
  desc: false,
  id: 'contactGroup.name',
};

export const cprManagementContactSortingAtom = createSortingAtom({
  defaultSorting: defaultCprManagementContactSorting,
});
