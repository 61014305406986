import { useEffect, useMemo, useState } from 'react';
import { useGetMainCategory } from '#edsn/api/pie-bff';
import type { zodResolver } from '@hookform/resolvers/zod';
import type { z } from 'zod';

export const useTemplate = <T extends Parameters<typeof zodResolver>[0]>({
  watch,
  getValues,
  setValue,
}: {
  watch: (field: keyof z.infer<T>) => string;
  getValues: (field: keyof z.infer<T>) => string;
  setValue: (field: keyof z.infer<T>, value: string) => void;
}) => {
  const { data: categoryData, isLoading } = useGetMainCategory();
  const categoryId = watch('mainCategoryId');
  const subCategoryId = watch('subCategoryId');
  const subCategories = useMemo(
    () => categoryData?.items.find(c => c.id === categoryId)?.subCategories,
    [categoryId, categoryData?.items]
  );
  const selectedSubCategory = useMemo(
    () => subCategories?.find(m => m.id === subCategoryId),
    [subCategoryId, subCategories]
  );

  const [showConfirm, setShowConfirm] = useState(false);
  const onConfirmClick = () => {
    closeConfirm();
    if (selectedSubCategory?.template) {
      setValue('description', selectedSubCategory.template);
    }
  };

  const closeConfirm = () => setShowConfirm(false);

  useEffect(() => {
    const template = selectedSubCategory?.template;
    if (template) {
      if (getValues('description')) {
        setShowConfirm(true);
      } else {
        setValue('description', template);
      }
    }
  }, [selectedSubCategory, getValues, setValue]);

  return { closeConfirm, isLoading, onConfirmClick, showConfirm };
};
