import type { PropsWithChildren } from 'react';
import { cn } from '#pie/utils/TailwindUtils';

export interface DescriptionListItemProps extends PropsWithChildren {
  title: string;
  full?: boolean;
}

export function DescriptionListItem({ title, children, full = false }: DescriptionListItemProps) {
  return (
    <>
      <dt className={cn('min-w-[14rem] font-bold', full && 'col-span-full')} aria-label={title}>
        {title}
      </dt>
      <dd
        className={cn('flex-start -mt-5 flex xl:mt-0', full && 'col-span-full')}
        aria-label={`Definition of ${title}`}
      >
        {children}
      </dd>
    </>
  );
}
