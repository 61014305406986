import IconClose from '@iconify/icons-material-symbols/close';
import * as Popup from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import type { PropsWithChildren } from 'react';
import { Divider } from '#pie/components/divider/Divider';
import { Icon } from '#pie/components/icon/Icon';
import { Text } from '#pie/components/text/Text';
import { cn } from '#pie/utils/TailwindUtils';

export interface DrawerProps extends PropsWithChildren {
  title: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  className?: string;
}

export function Drawer({ title, children, open, onOpenChange, className }: DrawerProps) {
  return (
    <Popup.Root open={open} onOpenChange={onOpenChange}>
      <AnimatePresence>
        {open && (
          <Popup.Portal forceMount>
            <Popup.Overlay data-testid="overlay" className="z-60 fixed inset-0 bg-neutral-900/25" asChild>
              <motion.div
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              />
            </Popup.Overlay>

            <Popup.Content
              asChild
              className={cn(
                'z-70 fixed right-0 top-0 h-full min-w-[362px] overflow-y-auto bg-white px-6 py-4 shadow-lg',
                className
              )}
            >
              <motion.div
                data-testid="content"
                initial={{ x: '100%' }}
                animate={{ x: 0 }}
                exit={{ x: '100%' }}
                transition={{ bounce: 0, duration: 0.2, type: 'spring', velocity: 200 }}
              >
                <div className="flex items-center justify-between">
                  <Popup.Title asChild>
                    <Text variant="h4" className="text-primary-dark">
                      {title}
                    </Text>
                  </Popup.Title>

                  {onOpenChange && (
                    <button onClick={() => onOpenChange(!open)} aria-label="Sluiten">
                      <Icon icon={IconClose} />
                    </button>
                  )}
                </div>
                <Divider className="my-5" />
                {children}
              </motion.div>
            </Popup.Content>
          </Popup.Portal>
        )}
      </AnimatePresence>
    </Popup.Root>
  );
}
