import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { KnowledgeArticleListDto } from '#edsn/api/pie-bff';
import { createKnowledgeListArticle } from '#edsn/api/pie-bff/mocks/knowledgeBase';
import { Button } from '../button/Button';
import { Card } from '../card/Card';
import { Skeleton } from '../skeleton/Skeleton';
import { SkeletonLines } from '../skeleton-lines/SkeletonLines';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';

type RelatedKnowledgeArticlesCardProps = {
  isLoading?: boolean;
  articles?: KnowledgeArticleListDto[];
};

export const RelatedKnowledgeArticlesCard = ({ articles = [], isLoading }: RelatedKnowledgeArticlesCardProps) => {
  const { t } = useTranslation();
  return (
    <Card
      heading={
        <Text as="h6" variant="h6">
          {t('common.related.knowledge_articles')}
        </Text>
      }
      size="md"
      shadow={false}
    >
      <Stack gap="md" divider="line">
        {!isLoading && articles.length === 0 ? (
          <Text>{t('common.related.no_results')}</Text>
        ) : (
          (isLoading ? [createKnowledgeListArticle()] : articles).map(article => (
            <Stack as="article" key={article.id} gap="xs" className="items-start">
              <Stack divider="dot" direction="row" gap="sm" key={article.id}>
                <Text>{isLoading ? <Skeleton className="w-20" /> : article.articlePublicNumber}</Text>
                {article.mainCategory && (
                  <Text>{isLoading ? <Skeleton className="w-32" /> : article.mainCategory.label}</Text>
                )}
              </Stack>
              {isLoading ? (
                <SkeletonLines lines={2} />
              ) : (
                <Button as={Link} variant="linkPrimary" to={`/kennisbank/${article.id}`} target="_blank">
                  {article.title}
                </Button>
              )}
            </Stack>
          ))
        )}
      </Stack>
    </Card>
  );
};
