import type { NotificationSummaryQuery } from '../generated/graphql';

export const createGQLNotificationBanner = (): NotificationSummaryQuery => ({
  globalSet: {
    description:
      'Om 21:00 uur is er een update op Mijn Partners in Energie. De website zal tijdelijk niet bereikbaar zijn. De verwachting is dat deze update 5 minuten duurt.',
    isEnabled: true,
    notificationType: 'info',
  },
});
