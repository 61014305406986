import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { AuthorizationRoles, SelectDto } from '#edsn/api/pie-bff';
import { getContactDetails } from '#edsn/api/pie-bff';
import { AuthorizationTag } from '#pie/components/authorization-tag/AuthorizationTag';
import { Card } from '#pie/components/card/Card';
import { DataTable } from '#pie/components/data-table/DataTable';
import { InputSearch } from '#pie/components/input-search/InputSearch';
import { Stack } from '#pie/components/stack/Stack';
import { StatusTag } from '#pie/components/status-tag/StatusTag';
import { useDataTable } from '#pie/hooks/useDataTable';
import { contactDetailsSortingAtom } from '#pie/stores/contactDetails';
import { searchAtom } from '#pie/stores/search';
import { emptyFilterAtom } from '#pie/stores/searchOnlyFilter';

export const UserManagement = () => {
  const { t } = useTranslation();

  const tableProps = useDataTable({
    columns: helper => [
      helper.accessor('fullName', {
        cell: info => (
          <Link className="text-primary link" to={info.row.original.id} aria-label={info.getValue()}>
            {info.getValue()}
          </Link>
        ),
        header: t('common.name'),
        meta: { columnWidth: 250 },
      }),
      helper.accessor('email', {
        header: t('common.email'),
        meta: { columnWidth: 250 },
      }),
      helper.accessor('teams', {
        cell: info => {
          const teams = info.getValue() as SelectDto[];
          return teams.map(m => m.label).join(', ');
        },
        header: t('common.teams'),
        meta: { columnWidth: 350 },
      }),
      helper.accessor('roles', {
        cell: info => {
          const roles = info.getValue() as AuthorizationRoles[];
          return (
            <div className="flex gap-1">
              {roles.slice(0, 3).map(m => (
                <AuthorizationTag role={m} />
              ))}
              {roles.length > 3 && <StatusTag status={'closed'}>+{roles.length - 3}</StatusTag>}
            </div>
          );
        },
        header: t('common.roles'),
      }),
    ],
    dateKeys: [],
    filtersAtom: emptyFilterAtom,
    query: getContactDetails,
    sortingAtom: contactDetailsSortingAtom,
  });

  const [search, setSearch] = useAtom(searchAtom);

  return (
    <section className="flex items-start gap-6">
      <Card className="flex-1 overflow-x-hidden p-4">
        <Stack gap="md">
          <header className="px-3" role="toolbar">
            <InputSearch
              value={search || ''}
              onChange={e => setSearch(e.target.value)}
              placeholder={t('common.search.placeholder')}
              onClear={() => setSearch('')}
            />
          </header>
          <DataTable
            rowLinks={row => ({ 'aria-label': row.original.fullName, to: row.original.id })}
            {...tableProps}
            idKey="id"
          />
        </Stack>
      </Card>
    </section>
  );
};
