import { useMemo } from 'react';
import { useGetMainCategory } from '#edsn/api/pie-bff';

export const useTicketCategoryIdKV = () => {
  const { data: categoryData } = useGetMainCategory();

  const categoryIdKV = useMemo(
    () => categoryData?.items.find(cat => cat.name === 'KV Disputen')?.id,
    [categoryData?.items]
  );

  return categoryIdKV;
};
