import type { PolymorphicProps } from '#pie/constants/interfaces';
import type { ElementType } from 'react';
import { type VariantProps, tv } from '#pie/utils/TailwindUtils';

const ElementMap = {
  base: 'p',
  display: 'p',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  lg: 'p',
  sm: 'p',
  subtitle: 'p',
  xl: 'p',
  xs: 'p',
  xxs: 'p',
} satisfies Record<Exclude<VariantProps<typeof text>['variant'], undefined>, ElementType>;

export type TextProps<TComponent extends ElementType = 'p'> = PolymorphicProps<TComponent, VariantProps<typeof text>>;

export function Text<TComponent extends ElementType = 'p'>({
  as,
  children,
  variant,
  className,
  ...props
}: TextProps<TComponent>) {
  const Component = as ?? ElementMap[variant ?? 'base'];

  return (
    <Component className={text({ className, variant })} {...props}>
      {children}
    </Component>
  );
}

export const text = tv({
  defaultVariants: {
    variant: 'base',
  },

  variants: {
    variant: {
      base: 'text-base',
      display: 'text-display',
      h1: 'text-h1 text-primary-dark',
      h2: 'text-h2 text-primary-dark',
      h3: 'text-h3 text-primary-dark',
      h4: 'text-h4 text-primary-dark',
      h5: 'text-h5 text-primary-dark',
      h6: 'text-h6 text-primary-dark',
      lg: 'text-lg',
      sm: 'text-sm',
      subtitle: 'text-subtitle',
      xl: 'text-xl',
      xs: 'text-xs',
      xxs: 'text-xxs',
    },
  },
});
