import type { GetCommentParams } from '#edsn/api/pie-bff';
import { useGetComment } from '#edsn/api/pie-bff';
import { Divider } from '../divider/Divider';
import { ReplyField } from '../reply-field/ReplyField';
import { Stack, StackItem } from '../stack/Stack';
import type { ComponentProps } from 'react';
import { Comment } from '#pie/components/comment/Comment';
import { Text } from '#pie/components/text/Text';

interface CommentsProps extends GetCommentParams, Pick<ComponentProps<typeof ReplyField>, 'canAddAttachments'> {
  canComment?: boolean;
}

export function Comments({ canComment, ...commentParams }: CommentsProps) {
  const { data, error, isLoading } = useGetComment(commentParams);

  if (error) {
    return 'ERROR';
  }

  const hasComments = !!(data?.items && data.items.length > 0);

  const reply = data?.totalCount === 1 ? 'Reactie' : 'Reacties';

  return isLoading || (data && data.totalCount > 0) || canComment ? (
    <section className="flex flex-col gap-6">
      <Text variant="h5">{`${hasComments ? `${data.totalCount} ` : ''}${reply}`}</Text>
      {canComment && <ReplyField {...commentParams} />}
      {(hasComments || isLoading) && (
        <>
          <Stack as="ul" gap="lg" divider="line">
            {isLoading
              ? new Array(3).fill(null).map((_, index) => (
                  <StackItem as="li" key={`comment${index}`}>
                    <Comment isLoading />
                  </StackItem>
                ))
              : data?.items?.map(comment => (
                  <StackItem as="li" key={comment.id}>
                    <Comment comment={comment} />
                  </StackItem>
                ))}
          </Stack>
          <Divider className="my-2" />
        </>
      )}
    </section>
  ) : null;
}
