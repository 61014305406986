import {
  type ControllerRenderProps,
  type FieldValues,
  type Path,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import type { ComponentProps } from 'react';
import { InputDropdown, type InputDropdownProps } from '#pie/components/input-dropdown/InputDropdown';
import { InputField } from '#pie/components/input-field/InputField';

type FormDropdownProps<T extends FieldValues> = Omit<
  InputDropdownProps,
  'id' | 'isError' | 'required' | keyof ControllerRenderProps<T, Path<T>>
> &
  Pick<UseControllerProps<T>, 'disabled' | 'name' | 'rules' | 'defaultValue'> &
  Pick<ComponentProps<typeof InputField>, 'label' | 'className'> & { onChange?: (value: string) => void };

export function FormDropdown<T extends FieldValues>({
  name,
  rules,
  defaultValue,
  onChange,
  label,
  disabled,
  className,
  ...props
}: FormDropdownProps<T>) {
  const {
    fieldState: { error },
    field: { onChange: onFieldChange, ...field },
  } = useController({
    defaultValue,
    name,
    rules,
  });

  return (
    <InputField name={name} isRequired={!!rules?.required} label={label} error={error?.message} className={className}>
      <InputDropdown
        isError={!!error}
        required={!!rules?.required}
        onChange={value => {
          onFieldChange(value);
          onChange?.(value);
        }}
        {...field}
        disabled={disabled}
        {...props}
      />
    </InputField>
  );
}
