import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateUpdateContactDto } from '#edsn/api/pie-bff';
import { Button } from '../button/Button';
import { Stack } from '../stack/Stack';
import type { SubmitHandler, UseFormProps } from 'react-hook-form';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormText } = typedFormFields<CreateUpdateContactDto>();

export interface ContactFormProps extends UseFormProps<CreateUpdateContactDto> {
  onSubmit: SubmitHandler<CreateUpdateContactDto>;
  isLoading?: boolean;
  submitText: string;
}
export const ContactForm = ({ onSubmit, isLoading, submitText, ...props }: ContactFormProps) => {
  const { t } = useTranslation();

  const formMethods = useForm<CreateUpdateContactDto>(props);

  const { handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap="lg">
          <Stack gap="sm">
            <FormText
              fullWidth
              name="firstName"
              label={t('contact.firstName')}
              rules={{ required: t('zod.errors.required') }}
            />
            <FormText
              fullWidth
              name="lastName"
              label={t('contact.lastName')}
              rules={{ required: t('zod.errors.required') }}
            />
            <FormText fullWidth name="email" label={t('common.email')} type="email" />
            <FormText fullWidth name="phone" label={t('common.phone')} />
          </Stack>
          <Button isLoading={isLoading} type="submit" variant="secondary">
            {submitText}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
