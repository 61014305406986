import { HttpResponse, graphql } from 'msw';
import { createGQLFooterNavigation, createGQLFooterSubNavigation } from './footerNavigation';
import { createGQLMainMenu, createGQLTopbarNavigation } from './headerNavigation';
import { createGQLHome } from './home';
import { createStaticMessages } from './i18n';
import { createGQLNotificationBanner } from './notificationBanner';

export const handlers = [
  graphql.query('StaticMessagesSummary', () =>
    HttpResponse.json({
      data: { staticMessages: Object.entries(createStaticMessages()).map(([key, message]) => ({ key, message })) },
    })
  ),
  graphql.query('MainMenuSummary', () => HttpResponse.json({ data: createGQLMainMenu() })),
  graphql.query('TopbarSummary', () => HttpResponse.json({ data: createGQLTopbarNavigation() })),
  graphql.query('FooterNavigationSummary', () => HttpResponse.json({ data: createGQLFooterNavigation() })),
  graphql.query('FooterPartnersSummary', () => HttpResponse.json({ data: createGQLFooterSubNavigation() })),
  graphql.query('HomePage', () => HttpResponse.json({ data: createGQLHome() })),
  graphql.query('NotificationSummary', () => HttpResponse.json({ data: createGQLNotificationBanner() })),
];
