import * as TabsPrimitive from '@radix-ui/react-tabs';
import * as React from 'react';
import { cn } from '#pie/utils/TailwindUtils';

export const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'data-[state=active]:text-secondary data-[state=active]:border-b-secondary p-4 data-[state=active]:-mb-[2px] data-[state=active]:border-b-2 data-[state=active]:font-bold',
      className
    )}
    {...props}
  />
));
