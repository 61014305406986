import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Stack, StackItem } from '../stack/Stack';
import { MenuItem } from './item/MenuItem';
import type { ComponentProps, PropsWithChildren } from 'react';
import { tv } from '#pie/utils/TailwindUtils';

export interface Item extends Omit<ComponentProps<typeof MenuItem>, 'children'> {
  label: string;
}

interface MenuProps
  extends PropsWithChildren,
    ComponentProps<typeof DropdownMenu.Root>,
    Pick<ComponentProps<typeof DropdownMenu.Trigger>, 'disabled'> {
  items: Item[];
  align?: ComponentProps<typeof DropdownMenu.Content>['align'];
}

export const Menu: React.FC<MenuProps> = ({ children, align, items, disabled, ...props }) => {
  const styles = menuStyles();
  return (
    <DropdownMenu.Root {...props}>
      <DropdownMenu.Trigger disabled={disabled}>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Content className={styles.content()} align={align}>
        <Stack as="ul" divider="line">
          {items?.map(item => (
            <StackItem as="li" key={item.label}>
              <MenuItem aria-label={item.label} {...item}>
                {item.label}
              </MenuItem>
            </StackItem>
          ))}
        </Stack>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export const menuStyles = tv({
  slots: {
    content:
      'min-w-[--radix-select-trigger-width] bg-white shadow py-3 px-2 my-1 border border-neutral-300 rounded z-50',
    icon: '-ml-2',
    item: 'rounded text-sm py-3 text-neutral-500 flex items-center gap-2 cursor-pointer hover:text-secondary focus:text-secondary focus:outline-none px-3 ',
  },
});

export { MenuItem } from './item/MenuItem';
