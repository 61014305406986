import { useTranslation } from 'react-i18next';
import type { ConnectionInformationDto } from '#edsn/api/pie-bff';
import { DescriptionList, DescriptionListItem } from '../description-list/DescriptionList';
import { CollapsibleCard } from '#pie/components/collapsible-card/CollapsibleCard';
import { productIcons } from '#pie/constants/productIcons';
import { products } from '#pie/constants/products';
import { formatNl } from '#pie/utils/date';

export interface ConnectionInfoProps {
  connection: ConnectionInformationDto;
  ean18: string;
}

export function ConnectionInfo({
  connection: {
    administrativeStatus,
    deviceInUseDate,
    deviceNumber,
    deviceStatus,
    deviceType,
    failureEventCode,
    isLargeConsumerOrDeviceless,
    product,
    rawRegionalGridOperatorEan,
    regionalGridOperator,
    supplier,
    operationalStatus,
  },
  ean18,
}: ConnectionInfoProps) {
  const { t } = useTranslation();
  const status = failureEventCode ? 'Storing' : 'Geen storing bekend';
  return (
    <CollapsibleCard
      title={
        product === 'Gas' ? t('ticket_detail.connection_info_gas') : t('ticket_detail.connection_info_electricity')
      }
      icon={productIcons[product]}
      status={status}
      variant={failureEventCode ? 'malfunction' : 'no_malfunction'}
      aria-label={`${ean18} ${status}`}
    >
      <DescriptionList className="jflex-colj">
        <DescriptionListItem title="Eigenaar RNB">
          {rawRegionalGridOperatorEan} {regionalGridOperator?.name}
        </DescriptionListItem>
        <DescriptionListItem title="Productsoort">{products[product]}</DescriptionListItem>
        <DescriptionListItem title="Fysieke status">{deviceStatus}</DescriptionListItem>
        <DescriptionListItem title="GV aansluiting en/of meterloze aansluiting">
          {isLargeConsumerOrDeviceless ? 'ja' : 'nee'}
        </DescriptionListItem>
        <DescriptionListItem title="Meternummer">{deviceNumber}</DescriptionListItem>
        <DescriptionListItem title="Meterplaatsingsdatum">
          {deviceInUseDate && formatNl(new Date(deviceInUseDate), 'P')}
        </DescriptionListItem>
        <DescriptionListItem title="Metertype">{deviceType}</DescriptionListItem>
        <DescriptionListItem title="Uitleesbaarheid slimme meter">{operationalStatus}</DescriptionListItem>
        <DescriptionListItem title="Administratieve status">{administrativeStatus}</DescriptionListItem>
        <DescriptionListItem title="Leverancier op de aansluiting">{supplier}</DescriptionListItem>
      </DescriptionList>
    </CollapsibleCard>
  );
}
