/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type Product = (typeof Product)[keyof typeof Product];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Product = {
  None: 'None',
  Gas: 'Gas',
  Electricity: 'Electricity',
  Both: 'Both',
} as const;
