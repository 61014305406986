import { useQueries } from '@tanstack/react-query';
import { fetchInstance } from '#edsn/api/pie-bff';
import type { User } from 'oidc-client-ts';
import { VITE_IDP_URI } from '#pie/env';

// TODO: get from ForgeRock somehow?
interface UserInfo {
  family_name: string;
  given_name: string;
  name: string;
  roles: string[];
  email: string;
  sub: string;
  subname: string;
}

type TokenInfo = User & {
  marktfacilitering: {
    marktpartij: {
      kvk: string;
      ean13: string;
      naam: string;
      rol: string;
      status: null;
      organisatieonderdeel: { ean13: string; naam: string }[];
    };
  };
};

export const useUserInfo = () =>
  useQueries({
    queries: [
      {
        queryFn() {
          return fetchInstance<UserInfo>({
            method: 'GET',
            url: `${VITE_IDP_URI}/userinfo`,
            withEan: false,
          });
        },
        queryKey: ['userinfo'],
      },
      {
        queryFn() {
          return fetchInstance<TokenInfo>({
            method: 'GET',
            url: `${VITE_IDP_URI}/tokeninfo`,
            withEan: false,
          });
        },
        queryKey: ['tokeninfo'],
      },
    ],
  });
