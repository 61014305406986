import request from 'graphql-request';
import { StaticMessagesSummaryDocument } from '#edsn/api/pie-cms';
import { DEFAULT_LOCALE, Locale } from '#edsn/shared';
import en from './locales/en-GB/common.json';
import nl from './locales/nl-NL/common.json';
import type { QueryClient } from '@tanstack/react-query';
import type { TypeDefinitionNode } from 'graphql';
import type { ResourceKey } from 'i18next';
import { VITE_CMS_TOKEN, VITE_CMS_URL } from '#pie/env';

const resources = {
  [Locale.Dutch]: nl,
  [Locale.English]: en,
};

export const loadStaticMessages =
  (queryClient: QueryClient, cache: Partial<{ [key in Locale]: ResourceKey }> = {}) =>
  async (locale: string): Promise<ResourceKey> => {
    const typedLocale = Object.values(Locale).includes(locale as Locale) ? (locale as Locale) : DEFAULT_LOCALE;
    const data = cache[typedLocale];
    if (data) {
      return data;
    }

    const base = structuredClone(resources[typedLocale]);
    const staticMessages = await loadStaticMessagesFromCms(queryClient, locale);

    const result = (staticMessages ?? []).reduce<ResourceKey>((res, cur) => {
      if (cur?.key && typeof res === 'object' && cur.message) {
        setPropertyByPath(res, cur.key, cur.message);
      }
      return res;
    }, base);

    cache[typedLocale] = result;

    return result;
  };

const loadStaticMessagesFromCms = async (queryClient: QueryClient, locale: string) => {
  try {
    const { staticMessages } = await queryClient.fetchQuery(
      [(StaticMessagesSummaryDocument.definitions[0] as TypeDefinitionNode).name.value, locale],

      () => {
        return request(
          VITE_CMS_URL,
          StaticMessagesSummaryDocument,
          { category: 'pie', locale },
          { authorization: `Bearer ${VITE_CMS_TOKEN}` }
        );
      }
    );

    return staticMessages;
  } catch (e) {
    return [];
  }
};

const setPropertyByPath = (obj: object, path: string, value: string): object => {
  const [head, ...rest] = path.split('.');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (obj as any)[head] = rest.length ? setPropertyByPath(obj[head as keyof object] ?? {}, rest.join('.'), value) : value;
  return obj;
};
