import CloseIcon from '@iconify/icons-material-symbols/close';
import ProgressActivityIcon from '@iconify/icons-material-symbols/progress-activity';
import { type InputHTMLAttributes, forwardRef } from 'react';
import { Icon, type IconProps } from '../icon/Icon';
import { Text } from '../text/Text';
import { type VariantProps, cn, tv } from '#pie/utils/TailwindUtils';

export interface InputTextProps
  extends Omit<VariantProps<typeof styles>, 'iconStart' | 'iconEnd'>,
    Omit<InputHTMLAttributes<HTMLInputElement>, 'disabled'> {
  iconStart?: IconProps['icon'];
  iconClass?: string;
  showWordCount?: number;
  onClear?: () => void;
}

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(function InputText(
  { className, isError, disabled, fullWidth, iconClass, iconStart, isLoading, showWordCount, onClear, ...props },
  ref
) {
  const hasClear = !!onClear;
  const s = styles({ disabled, fullWidth, iconStart: !!iconStart, isError, isLoading });

  return (
    <div className={s.base({ className })}>
      {showWordCount !== undefined && (
        <Text className={s.wordCount()}>
          {props.value?.toString().length || 0}/{showWordCount}
        </Text>
      )}
      <input ref={ref} data-testid={`textbox-${props.name}`} disabled={disabled} className={s.input()} {...props} />

      {(iconStart || isLoading) && (
        <div className={cn(s.iconWrapper(), s.iconStart({ className: iconClass }))}>
          <Icon
            icon={isLoading ? ProgressActivityIcon : iconStart!}
            className={cn(s.icon(), s.iconLoading())}
            size={16}
          />
        </div>
      )}
      {hasClear && props.value && (
        <div className={'absolute right-3 top-1/2 -translate-y-1/2'}>
          <button aria-label="Invoer wissen" onClick={onClear} className="">
            <Icon size={16} icon={CloseIcon} className="h-6 w-6 rounded-xl bg-neutral-200 p-1 text-center" />
          </button>
        </div>
      )}
    </div>
  );
});

export const styles = tv({
  defaultVariants: {
    iconPosition: 'right',
    isDisabled: false,
    isError: false,
  },

  slots: {
    base: 'relative',
    icon: '',
    iconEnd: ' right-4',
    iconLoading: '',
    iconStart: 'left-4',
    iconWrapper: 'pointer-events-none absolute select-none -translate-y-1/2 top-1/2 flex',
    input:
      'rounded border bg-white px-4 py-[11px] text-paragraph text-body outline-none placeholder:text-neutral-400 min-h-12',
    wordCount: 'text-right absolute right-0 bottom-full mb-2 text-neutral-500',
  },

  variants: {
    disabled: {
      true: {
        iconEnd: 'text-neutral-500',
        iconStart: 'text-neutral-500',
        input: 'cursor-not-allowed border-neutral-300 bg-neutral-100 text-neutral-500',
      },
    },
    fullWidth: {
      true: { base: 'w-full', input: 'w-full' },
    },

    iconEnd: {
      true: { input: 'pl-11' },
    },

    iconStart: {
      true: { input: 'pl-11' },
    },

    isError: {
      false: {
        iconEnd: 'text-neutral-600',
        iconStart: 'text-neutral-600',
        input: 'border-input focus:border-primary focus:shadow-focusInput',
      },
      true: {
        iconEnd: 'text-error',
        iconStart: 'text-error',
        input: 'border-error  focus:border-error focus:shadow-focusInputError',
      },
    },
    isLoading: {
      true: { iconLoading: 'animate-spin', input: 'text-transparent' },
    },
  },
});
