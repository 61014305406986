import { zodResolver } from '@hookform/resolvers/zod';
import i18next from 'i18next';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { Product, getConnectionInformation } from '#edsn/api/pie-bff';
import { useEanFormContext } from './TicketCreateEan';
import type { PartialEanSchema, StepProps } from './TicketCreateEan';
import { Card } from '#pie/components/card/Card';
import { Divider } from '#pie/components/divider/Divider';
import { ErrorMessage } from '#pie/components/form/errorMaps';
import { Page, PageHeader } from '#pie/components/page/Page';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { isValidEan } from '#pie/utils/isValidEan';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const schema = () =>
  z.object({
    electricityEan: z
      .string()
      .refine(value => isValidEan(value, { optional: true }), i18next.t(ErrorMessage.ean18))
      .optional(),
    gasEan: z
      .string()
      .refine(value => isValidEan(value, { optional: true }), i18next.t(ErrorMessage.ean18))
      .optional(),
  });

type CodesForm = z.infer<ReturnType<typeof schema>>;

const { FormText } = typedFormFields<CodesForm>();

export const TicketCreateEanCodes = ({ wizardActions, onSubmit, stepper }: StepProps) => {
  const { t } = useTranslation();
  const { data, setStepShouldBlock, setIsLoading } = useEanFormContext();

  const refinedSchema = schema()
    .refine(check => check.electricityEan || check.gasEan, {
      message: i18next.t(ErrorMessage.either),
      path: ['electricityEan'],
    })
    .refine(check => check.electricityEan || check.gasEan, {
      message: i18next.t(ErrorMessage.either),
      path: ['gasEan'],
    });
  const resolver = zodResolver(refinedSchema);

  const formMethods = useForm<CodesForm>({ defaultValues: data, resolver });
  const {
    handleSubmit,
    formState: { dirtyFields },
    setError,
  } = formMethods;

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) setStepShouldBlock(true);
  }, [dirtyFields]);

  const preSubmit = async (data: PartialEanSchema) => {
    setIsLoading(true);

    try {
      const [elecData, gasData] = await Promise.all([
        data.electricityEan && getConnectionInformation({ ean18: data.electricityEan }),
        data.gasEan && getConnectionInformation({ ean18: data.gasEan }),
      ]);

      const hasInvalidElectricityEan = elecData && elecData.product === Product.Gas;
      const hasInvalidGasEan = gasData && gasData.product === Product.Electricity;

      if (hasInvalidElectricityEan)
        setError('electricityEan', {
          message: t('ticket_create.errors.ean_not_electricity'),
        });
      if (hasInvalidGasEan)
        setError('gasEan', {
          message: t('ticket_create.errors.ean_not_gas'),
        });

      setIsLoading(false);

      if (hasInvalidElectricityEan || hasInvalidGasEan) return;
    } catch (error) {
      // Do nothing, we'll just let the user continue
      // eslint-disable-next-line no-console
      console.warn(error);

      setIsLoading(false);
    }

    onSubmit(data);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(preSubmit)}>
        <Page>
          <PageHeader
            title={t('ticket_create.ean.page_title')}
            previousText={t('common.steps.previous')}
            backHref="/tickets/nieuw"
            details={stepper}
          />
          <Card className="mb-6 max-w-4xl p-6" shadow>
            <Stack gap="lg">
              <Stack gap="sm">
                <Text as="h2" variant="h5" className="text-primary-dark">
                  {t('ticket_create.codes.enter_electricity_gas_ean')}
                </Text>
              </Stack>
              <Stack gap="sm">
                <FormText label={t('common.electricity')} name="electricityEan" fullWidth />
                <FormText label={t('common.gas')} name="gasEan" fullWidth />
              </Stack>
              <Divider />
              <Stack direction="row" className="justify-between" gap="lg">
                {wizardActions}
              </Stack>
            </Stack>
          </Card>
        </Page>
      </form>
    </FormProvider>
  );
};
