import * as RadioGroup from '@radix-ui/react-radio-group';
import { forwardRef } from 'react';
import { button } from '../button/Button';
import { type VariantProps, cn, tv } from '#pie/utils/TailwindUtils';

export type Option = {
  label: string;
  value: string;
};

export interface InputRatingProps
  extends Omit<RadioGroup.RadioGroupProps, 'onChange' | 'disabled' | 'defaultValue'>,
    VariantProps<typeof inputRating> {
  defaultValue?: string;
  options: Option[];
  className?: string;
  onChange?: (value: string) => void;
}

export const InputRating = forwardRef<HTMLDivElement, InputRatingProps>(function InputRating(
  { className, defaultValue, options, onChange, disabled, value, ...props },
  ref
) {
  const s = inputRating({ disabled });

  return (
    <RadioGroup.Root
      className={s.base({ className })}
      defaultValue={defaultValue?.toString()}
      value={value?.toString()}
      onValueChange={value => {
        onChange?.(value);
      }}
      disabled={disabled}
      ref={ref}
      orientation="horizontal"
      {...props}
    >
      {options.map(option => {
        const optionId = `${props.name}-${option.value}`;
        return (
          <RadioGroup.Item
            key={optionId}
            id={optionId}
            value={option.value.toString()}
            data-testid={`rating-${option.value.toString()}`}
            className={s.item()}
          >
            <span>{option.label}</span>
          </RadioGroup.Item>
        );
      })}
    </RadioGroup.Root>
  );
});

const inputRating = tv({
  base: 'grid grid-flow-col grid-auto gap-2 auto-cols-fr',
  slots: {
    item: cn(
      button({ variant: 'ghost' }).base(),
      `px-3 aria-checked:bg-primary aria-checked:text-white aria-checked:border-primary text-center`
    ),
    radio: 'w-6 h-6 bg-neutral-200 rounded-full focus:ring-2 focus:ring-primary',
  },
  variants: {
    disabled: {
      true: {
        item: cn(button({ isDisabled: true, variant: 'ghost' }).base(), `aria-checked:opacity-50`),
      },
    },
  },
});
