import { atom } from 'jotai';
import { z } from 'zod';
import { SearchParam, paramsAtom } from './searchParams';

export const searchSchema = z.string().optional().catch(undefined);

export const searchAtom = atom<string | undefined, [string | undefined], string | undefined>(
  get => {
    const searchParams = get(paramsAtom);

    const search = searchSchema.parse(searchParams?.get(SearchParam.search));
    return search;
  },
  (_get, set, searchState) => {
    set(paramsAtom, searchParams => {
      const params = new URLSearchParams(searchParams);
      const search = searchSchema.parse(searchState);

      if (search) {
        params.set(SearchParam.search, search);
      } else {
        params.delete(SearchParam.search);
      }

      params.delete(SearchParam.pageIndex);

      return params;
    });

    return searchState;
  }
);
