/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type CacheKey = (typeof CacheKey)[keyof typeof CacheKey];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CacheKey = {
  UserSpecific: 'UserSpecific',
  OrganizationSpecific: 'OrganizationSpecific',
  GridOperatorsAll: 'GridOperatorsAll',
  MainCategoriesAll: 'MainCategoriesAll',
  TicketSurvey: 'TicketSurvey',
  RejectionCategoriesAll: 'RejectionCategoriesAll',
  KnowledgeArticlesAll: 'KnowledgeArticlesAll',
  Cpr: 'Cpr',
} as const;
