import { Button } from '../button/Button';
import { Icon, type IconProps } from '../icon/Icon';
import type { PolymorphicProps } from '#pie/constants/interfaces';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import type { ElementType } from 'react';
import { tv } from '#pie/utils/TailwindUtils';

export interface IconButtonProps extends VariantProps<typeof iconButton>, Pick<IconProps, 'icon' | 'size'> {
  className?: string;
}

export function IconButton<TComponent extends ElementType = typeof Button>({
  icon,
  size,
  variant = 'grey',
  isActive,
  as,
  className,
  ...props
}: PolymorphicProps<TComponent, IconButtonProps>) {
  const Component = as ?? Button;
  const style = iconButton({ isActive, variant });

  return (
    <Component className={style.base({ className })} {...props}>
      <Icon icon={icon} size={size} className={style.icon()} />
    </Component>
  );
}

const iconButton = tv({
  base: 'inline-flex group bg-transparent p-1 rounded-full text-white border-none hover:bg-inherit focus:outline-primary-light focus:bg-primary-lighter focus:text-primary-lighter',
  compoundVariants: [
    {
      class: {
        base: 'bg-primary-lighter text-primary-light',
        icon: 'stroke-primary-light',
      },
      isActive: true,
      variant: 'grey',
    },
  ],
  slots: {
    icon: 'stroke-transparent stroke-2',
  },
  variants: {
    isActive: {
      true: {
        base: 'bg-transparent focus:text-inherit',
        icon: 'stroke-transparent',
      },
    },
    variant: {
      grey: {
        base: 'hover:text-primary-light',
        icon: 'text-neutral-600 group-hover:text-primary-light',
      },
      primary: 'text-primary focus:text-inherit',
      text: {
        base: 'hover:text-primary-light text-current',
        icon: 'group-hover:text-primary-light group-focus:text-primary-light',
      },
    },
  },
});
