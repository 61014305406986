import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useGetTeam } from '#edsn/api/pie-bff';
import { useCurrentOrganisation } from '#pie/auth';
import { Card } from '#pie/components/card/Card';
import { DescriptionList, DescriptionListItem } from '#pie/components/description-list/DescriptionList';
import { Skeleton } from '#pie/components/skeleton/Skeleton';
import { Text } from '#pie/components/text/Text';
import { useUserInfo } from '#pie/hooks/useUserInfo';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const assigneeValidationSchema = z.object({
  teamId: z.string().optional(),
});

type AssigneeForm = z.infer<typeof assigneeValidationSchema>;

const { FormDropdown } = typedFormFields<AssigneeForm>();

export const TicketAssigneeCard: React.FC = () => {
  const { t } = useTranslation();
  const { currentAccount } = useCurrentOrganisation();
  const [{ data: userInfo, isLoading: isLoadingUserInfo }] = useUserInfo();
  const { data: teamsData, isLoading: isLoadingTeams } = useGetTeam();

  return (
    <Card heading={<Text variant="h6">{t('common.submitter')}</Text>} size="md" shadow={false}>
      <DescriptionList>
        <DescriptionListItem title={t('common.customer')}>{currentAccount?.name}</DescriptionListItem>
        <DescriptionListItem title={t('common.submitter')}>
          {isLoadingUserInfo ? <Skeleton /> : userInfo?.given_name}
        </DescriptionListItem>
        <FormDropdown
          className="col-span-full"
          label={t('common.team')}
          fullWidth
          name="teamId"
          options={
            teamsData?.items.map(team => ({
              label: team.name,
              value: team.id,
            })) || []
          }
          isLoading={isLoadingTeams}
        />
      </DescriptionList>
    </Card>
  );
};
