/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type TicketView = (typeof TicketView)[keyof typeof TicketView];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TicketView = {
  All: 'All',
  Open: 'Open',
  Closed: 'Closed',
  FromGridOperator: 'FromGridOperator',
  Telemetry: 'Telemetry',
  InProgress: 'InProgress',
  Unread: 'Unread',
  Reaction: 'Reaction',
  NonConformity: 'NonConformity',
  Handled: 'Handled',
} as const;
