import type { ConnectionInformationDto } from '#edsn/api/pie-bff';
import { CollapsibleCard } from '../collapsible-card/CollapsibleCard';
import { CopyButton } from '../copy-button/CopyButton';
import { DescriptionList, DescriptionListItem } from '../description-list/DescriptionList';
import { productIcons } from '#pie/constants/productIcons';
import { formatNl } from '#pie/utils/date';

export interface FailureInfoProps {
  connection: ConnectionInformationDto;
  ean18: string;
}

export function FailureInfo({
  connection: {
    failureEventCode,
    failureEventRemark,
    failureEventDate,
    product,
    ticketNumber,
    website,
    regionalGridOperator,
  },
  ean18,
}: FailureInfoProps) {
  return (
    <CollapsibleCard
      title={
        <CopyButton as="span" role="button" content={ean18} aria-label={ean18}>
          {ean18}
        </CopyButton>
      }
      icon={productIcons[product]}
      status="Storing"
      variant="malfunction"
      hasBorder
      aria-label={`${ean18} Storing`}
    >
      <DescriptionList className="flex-col">
        <DescriptionListItem title="Storingscode">{failureEventCode ?? '-'}</DescriptionListItem>
        <DescriptionListItem title="Status storing">{failureEventRemark}</DescriptionListItem>
        <DescriptionListItem title="Datum storing devestigd">
          {failureEventDate ? formatNl(new Date(failureEventDate), 'P') : '-'}
        </DescriptionListItem>
        {regionalGridOperator?.phone && (
          <DescriptionListItem title="Telefoonnummer">{regionalGridOperator?.phone}</DescriptionListItem>
        )}
        <DescriptionListItem title="Ticketnummer">{ticketNumber ?? '-'}</DescriptionListItem>
        {website && <DescriptionListItem title="Link naar website">{website}</DescriptionListItem>}
      </DescriptionList>
    </CollapsibleCard>
  );
}
