import { useTranslation } from 'react-i18next';
import { Card } from '#pie/components/card/Card';
import { DescriptionList, DescriptionListItem } from '#pie/components/description-list/DescriptionList';
import { Text } from '#pie/components/text/Text';

interface Props {
  electricityEan?: string;
  gasEan?: string;
}

export const TicketConnectionCard = ({ gasEan, electricityEan }: Props) => {
  const { t } = useTranslation();
  return electricityEan || gasEan ? (
    <Card heading={<Text variant="h6">{t('ticket_create.ean_codes.title')}</Text>} size="md" shadow={false}>
      <DescriptionList>
        {electricityEan && (
          <DescriptionListItem title={t('common.electricity_ean')}>{electricityEan}</DescriptionListItem>
        )}
        {gasEan && <DescriptionListItem title={t('common.gas_ean')}>{gasEan}</DescriptionListItem>}
      </DescriptionList>
    </Card>
  ) : null;
};
