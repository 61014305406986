/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type MarketRole = (typeof MarketRole)[keyof typeof MarketRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MarketRole = {
  UNKNOWN: 'UNKNOWN',
  CSP: 'CSP',
  EPV: 'EPV',
  GDS: 'GDS',
  LNB: 'LNB',
  LV: 'LV',
  MV: 'MV',
  ODA: 'ODA',
  PV: 'PV',
  RNB: 'RNB',
  BSP: 'BSP',
} as const;
