import WarningIcon from '@iconify/icons-material-symbols/warning';
import { Icon } from '../icon/Icon';
import { Text } from '../text/Text';
import type { ReactNode } from 'react';
import { cn } from '#pie/utils/TailwindUtils';

export interface InputFieldProps {
  children: ReactNode;
  label?: string;
  error?: string;
  name: string;
  isRequired?: boolean;
  className?: string;
}

export const InputField = ({ children, label, error, name, className, isRequired, ...props }: InputFieldProps) => {
  return (
    <div {...props} className={cn('w-full', className)}>
      {!!label && (
        <Text
          as="label"
          htmlFor={name}
          className={cn('text-primary-dark mb-2 flex font-bold', isRequired && 'after:content-["*"]')}
        >
          {label}
        </Text>
      )}

      {children}

      {!!error && (
        <div className="text-error mt-2.5 flex items-center gap-2">
          <Icon size={16} icon={WarningIcon} />

          <Text as="span">{error}</Text>
        </div>
      )}
    </div>
  );
};
