export const isValidEan = (
  value?: string,
  { length = 18, optional = true }: { length?: 13 | 18; optional?: boolean } = {}
) => {
  if (!value) {
    return optional ? true : false;
  }

  if (value.length !== length) {
    return false;
  }

  let isValid = false;
  if (length === 13) {
    const checksum = parseInt(value[12]);
    const sum = [...value.substring(0, 12)]
      .map(num => parseInt(num))
      .reduce((res, cur, index) => (index % 2 === 0 ? res + cur : res + 3 * cur), 0);
    const digitToCheck = (10 - (sum % 10)) % 10;
    isValid = digitToCheck === checksum;
  } else {
    isValid = value.split('').reduce((res, cur, i) => res + +cur * ((length - i) % 2 === 0 ? 3 : 1), 0) % 10 === 0;
  }

  return isValid;
};
