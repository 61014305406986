import {
  type MainCategoryDto,
  type MainCategoryDtoListResultDto,
  type RejectionCategoryDto,
  type RejectionCategoryDtoListResultDto,
  type RejectionDto,
  type RejectionReasonDto,
  type SelectDto,
  type SubCategoryDto,
  type TicketFiltersDto,
  type TicketListDto,
  type TicketListDtoListResultDto,
  TicketOrigin,
  TicketStatus,
} from '../generated/model';
import { createRegionalGridOperator, generateEan } from './connection';

export const createSubCategory = (overrides: Partial<SubCategoryDto> = {}): SubCategoryDto => ({
  id: 'c6f7e4a2-7dc6-ea11-a812-000d3a21145f',
  marketRoles: [],
  name: 'KV Disputen sub',
  usage: 'Both',
  ...overrides,
});

export const createMainCategory = (overrides: Partial<MainCategoryDto> = {}): MainCategoryDto => ({
  id: '301fd09c-7dc6-ea11-a812-000d3a21145f',
  marketRoles: [],
  name: 'KV Disputen',
  subCategories: [
    createSubCategory({
      usage: 'LargeConsumption',
    }),
    createSubCategory({
      id: 'd6f7e4a2-7dc6-ea11-a812-000d3a21145f',
      name: 'Bewijslast opgevraagd',
      usage: 'SmallConsumption',
    }),
  ],
  usage: 'Both',
  ...overrides,
});

export const createMainCategories = (): MainCategoryDtoListResultDto => ({
  items: [
    createMainCategory(),
    createMainCategory({
      id: '311fd09c-7dc6-ea11-a812-000d3a21145f',
      name: 'Allocatie',
      subCategories: [
        createSubCategory({
          id: 'e6f7e4a2-7dc6-ea11-a812-000d3a21145f',
          name: 'Missende berichten',
        }),
        createSubCategory({
          id: 'f6f7e4a2-7dc6-ea11-a812-000d3a21145f',
          name: 'Meetdata',
          template: 'Template',
        }),
      ],
      usage: 'LargeConsumption',
    }),
    createMainCategory({
      id: 'c24b70d0-69ba-ec11-983f-000d3a4c82a2',
      name: 'Non conformiteit',
      subCategories: [createSubCategory({ id: 'e6f7e4a2-7dc6-ea11-a812-000d3a21145g', name: 'GV Non conformiteit' })],
    }),
    createMainCategory({
      id: 'c24b70d0-69ba-ec11-983f-000d3a4c1234',
      name: 'Kleinverbruik',
      subCategories: [createSubCategory({ id: 'e6f7e4a2-7dc6-ea11-a812-000d3a214321', name: 'Kleinverbruik sub' })],
      usage: 'SmallConsumption',
    }),
  ],
  totalCount: 2,
});

export const createSelectedSubCategory = (): SelectDto => {
  const { id, name } = createSubCategory();
  return {
    id,
    label: name,
  };
};

export const createSelectedMainCategory = (overrides: Partial<SelectDto> = {}): SelectDto => {
  const { id, name } = createMainCategory();
  return {
    id,
    label: name,
    ...overrides,
  };
};

export const createRejection = (overrides: Partial<RejectionDto> = {}): RejectionDto => ({
  rejectionCategory: { id: createRejectionCategory().id, label: createRejectionCategory().name },
  rejectionReason: { id: createRejectionReason().id, label: createRejectionReason().name },
  ...overrides,
});

export const createRejections = (): RejectionDto[] => [
  createRejection(),
  createRejection({
    rejectionCategory: { id: '2', label: 'Rejection category 2' },
    rejectionReason: { id: '2', label: 'Rejection reason 2' },
  }),
];

export const createRejectionCategories = (): RejectionCategoryDtoListResultDto => ({
  items: [
    createRejectionCategory(),
    createRejectionCategory({
      id: '2',
      name: 'Rejection category 2',
      reasons: [
        createRejectionReason({ id: '3', name: 'Rejection reason 3' }),
        createRejectionReason({ id: '4', name: 'Rejection reason 4' }),
      ],
    }),
  ],
  totalCount: 2,
});

export const createRejectionCategory = (overrides: Partial<RejectionCategoryDto> = {}): RejectionCategoryDto => ({
  id: '1',
  name: 'Rejection category 1',
  reasons: [createRejectionReason(), createRejectionReason({ id: '2', name: 'Rejection reason 2' })],
  ...overrides,
});

export const createRejectionReason = (overrides: Partial<RejectionReasonDto> = {}): RejectionReasonDto => ({
  id: '1',
  name: 'Rejection reason 1',
  ...overrides,
});

export const createSelectedContact = (overrides: Partial<SelectDto> = {}): SelectDto => ({
  id: 'f5029edf-f2ad-3c47-1457-fd6bb75b3c01',
  label: 'Piet de Vries',
  ...overrides,
});

export const createSelectedOrigin = (): SelectDto => ({
  id: TicketOrigin.Phone,
  label: TicketOrigin.Phone,
});

export const createSelectedTeam = (overrides: Partial<SelectDto> = {}): SelectDto => ({
  id: '086dc17c-2900-4df7-be25-2045ea78bf85',
  label: 'Pikablue',
  ...overrides,
});

export const createSelectedRegionalGridOperator = (): SelectDto => {
  const { id, name } = createRegionalGridOperator();
  return {
    id,
    label: name,
  };
};

export const createTickets = (overrides: Partial<TicketListDtoListResultDto> = {}): TicketListDtoListResultDto => ({
  items: [
    // Just some rows. Doesn't necessarily reflect current number of rows per page
    createTicket(),
    createTicket({
      canClose: true,
      createdOn: '2022-11-29T03:39:31',
      electricityEan: undefined,
      id: '2',
      issuer: undefined,
      regionalGridOperator: undefined,
      status: TicketStatus.Afgehandeld,
      ticketNumber: 'T-2022-12-105811',
    }),
    createTicket({
      createdOn: '2022-11-28T03:39:31',
      eventDate: undefined,
      gasEan: undefined,
      handler: undefined,
      hasUnreadComments: true,
      id: '3',
      mainCategory: undefined,
      processDate: undefined,
      status: TicketStatus.ReactieVereist,
      subCategory: undefined,
      ticketNumber: 'T-2022-12-105812',
    }),
  ],
  totalCount: 3,
  ...overrides,
});

export const createTicket = (overrides: Partial<TicketListDto> = {}): TicketListDto => ({
  canClose: false,
  createdOn: '2022-11-27T03:39:31.8785914',
  description: 'Een omschrijving van het ticket',
  disputeDate: '2023-04-01T14:00:00',
  electricityEan: generateEan(18, 'electricity'),
  eventDate: '2023-03-02T12:00:00',
  gasEan: generateEan(18, 'gas'),
  handledByRegionalGridOperatorDate: '2023-03-02T12:00:00',
  handler: createSelectedContact({
    id: 'afd5e7af-2681-a5dc-3902-6c75cc096a98',
    label: 'Jan de Jong',
  }),
  hasUnreadComments: false,
  id: '86ce5cdb-03bb-a83c-3363-50b132480f06',
  issuer: createSelectedContact(),
  mainCategory: createSelectedMainCategory(),
  modifiedOn: '2022-12-06T03:39:07.8928434',
  organisationId: 'e8db3c2b-acdd-4e88-bb74-514736bf87a1',
  origin: TicketOrigin.Phone,
  processDate: '2023-03-02T12:00:00',
  regionalGridOperator: createSelectedRegionalGridOperator(),
  status: TicketStatus.InBehandeling,
  subCategory: createSelectedSubCategory(),
  team: createSelectedTeam(),
  ticketNumber: 'T-2022-12-105810',
  title: 'Onderwerp van het ticket',
  ...overrides,
});

export const createTicketFilters = (): TicketFiltersDto => ({
  handlers: [createSelectedContact()],
  mainCategories: [createSelectedMainCategory()],
  origins: [createSelectedOrigin()],
  regionalGridOperators: [createSelectedRegionalGridOperator()],
  subCategories: [createSelectedSubCategory()],
  teams: [createSelectedTeam()],
});
