import type { PolymorphicProps } from '#pie/constants/interfaces';
import type { ElementType, ReactNode } from 'react';
import { cn, tv } from '#pie/utils/TailwindUtils';

export interface StackProps {
  children?: ReactNode[] | ReactNode;
  className?: string;
  direction?: 'column' | 'row' | 'columnReverse' | 'rowReverse';
  gap?: 'lg' | 'md' | 'sm' | 'xs';
  divider?: 'dot' | 'line';
}

export function Stack<TComponent extends ElementType>({
  as,
  children,
  className,
  direction,
  gap,
  divider,
}: PolymorphicProps<TComponent, StackProps>) {
  const Component = as ?? 'div';
  const styles = stack({ direction, divider, gap });
  return <Component className={cn(styles, className)}>{children}</Component>;
}

const stack = tv({
  base: 'flex',
  compoundVariants: [
    { class: 'divide-x', direction: 'row', divider: 'line' },
    { class: '[&>*+*]:before:mr-2', direction: 'row', divider: 'line', gap: 'sm' },
    { class: '[&>*+*]:before:mr-4', direction: 'row', divider: 'line', gap: 'md' },
    { class: '[&>*+*]:before:mr-6', direction: 'row', divider: 'line', gap: 'lg' },
    { class: '[&>*+*]:before:mr-2', direction: 'row', divider: 'dot', gap: 'sm' },
    { class: '[&>*+*]:before:mr-4', direction: 'row', divider: 'dot', gap: 'md' },
    { class: '[&>*+*]:before:mr-6', direction: 'row', divider: 'dot', gap: 'lg' },
    { class: 'divide-y', direction: 'column', divider: 'line' },
    { class: '[&>*+*]:pt-2', direction: 'column', divider: 'line', gap: 'sm' },
    { class: '[&>*+*]:pt-4', direction: 'column', divider: 'line', gap: 'md' },
    { class: '[&>*+*]:pt-6', direction: 'column', divider: 'line', gap: 'lg' },
    { class: '[&>*]:flex [&>*+*]:before:self-center', direction: 'row', divider: 'dot' },
    { class: '[&>*+*]:before:mx-auto', direction: 'column', divider: 'dot' },
    { class: '[&>*+*]:before:mb-2', direction: 'column', divider: 'dot', gap: 'sm' },
    { class: '[&>*+*]:before:mb-4', direction: 'column', divider: 'dot', gap: 'md' },
    { class: '[&>*+*]:before:mb-6', direction: 'column', divider: 'dot', gap: 'lg' },
  ],
  defaultVariants: {
    direction: 'column',
  },
  variants: {
    direction: {
      column: 'flex-col',
      columnReverse: 'flex-col-reverse',
      row: 'flex-row',
      rowReverse: 'flex-row-reverse',
    },
    divider: {
      dot: '[&>*+*]:before:block [&>*+*]:before:h-0.5 [&>*+*]:before:w-0.5 [&>*+*]:before:rounded-full [&>*+*]:before:bg-neutral-500',
      line: 'divide-neutral-200',
    },
    gap: {
      lg: 'gap-6',
      md: 'gap-4',
      sm: 'gap-2',
      xs: 'gap-1',
    },
  },
});

export { StackItem } from './item/StackItem';
