import IconDelete from '@iconify/icons-material-symbols/delete-outline';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteTeamId } from '#edsn/api/pie-bff';
import type { TeamDto } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { Dialog } from '#pie/components/layout/dialog/Dialog';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { useToast } from '#pie/components/toast/ToastContext';

interface RemoveTeamButtonnProps {
  data: TeamDto;
}

export const RemoveTeamButton = ({ data }: RemoveTeamButtonnProps) => {
  const { addToast } = useToast();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useDeleteTeamId({
    mutation: {
      onError: () => {
        addToast({
          message: `${t('organisation_management.tab.team_management.error.message')}`,
          title: `${t('organisation_management.tab.team_management.error.title')}`,
          type: 'error',
        });
      },
      onSuccess: () => {
        gtag('event', 'delete_team', {});
        addToast({
          message: `${t('organisation_management.tab.team_management.success.message', { name: data.name })}`,
          title: `${t('organisation_management.tab.team_management.success.title')}`,
          type: 'success',
        });
        setOpenDeleteDialog(false);
        queryClient.invalidateQueries({
          predicate: m => {
            return m.queryKey.some(n => typeof n === 'string' && n.includes('/team'));
          },
        });
      },
    },
  });

  return (
    <>
      <Button
        iconStart={IconDelete}
        variant="link"
        onClick={() => setOpenDeleteDialog(true)}
        className="ml-auto"
        aria-label="delete-team"
      />
      <Dialog
        title={t('organisation_management.tab.team_management.delete.button.delete')}
        open={openDeleteDialog}
        onOpenChange={setOpenDeleteDialog}
      >
        <Stack gap="lg">
          <Text>{t('organisation_management.tab.team_management.delete.title', { team: data.name })}</Text>
          <div className="grid-auto grid auto-cols-fr grid-flow-col gap-2">
            <Button
              variant="ghost"
              onClick={() => {
                setOpenDeleteDialog(false);
              }}
            >
              {t('organisation_management.tab.team_management.delete.button.cancel')}
            </Button>
            <Button isLoading={isLoading} variant="danger" onClick={() => mutate({ id: data.id })}>
              {t('organisation_management.tab.team_management.delete.button.delete')}
            </Button>
          </div>
        </Stack>
      </Dialog>
    </>
  );
};
