import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getGetContactDetailsIdQueryKey, useGetContactDetailsId, usePutContactDetailsId } from '#edsn/api/pie-bff';
import { DeleteOrganisationUserModal } from './DeleteOrganisationUserModal';
import { FormOrganisationUser } from './FormOrganisationUser';
import type { ComponentProps } from 'react';
import { useCurrentOrganisation } from '#pie/auth';
import { useToast } from '#pie/components/toast/ToastContext';

export const UpdateOrganisationUser = () => {
  const userId = useParams()['userId'];

  if (!userId) {
    throw new Error('user id is missing');
  }

  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { currentAccount } = useCurrentOrganisation();
  const { data: user, isLoading } = useGetContactDetailsId(userId);

  /* c8 ignore start */
  if (!isLoading && !user?.id) {
    throw new Error('user does not exist');
  }
  /* c8 ignore end */

  const isCurrentUser = user?.id === currentAccount?.userId;

  const { mutate, isLoading: isMutateLoading } = usePutContactDetailsId({
    mutation: {
      onError: async error => {
        switch (!(error instanceof Error) && error.code) {
          case 'Contact.ActiveExists': {
            addToast({
              message: t('organisation_management.user_management.update.toast.error.active_exists.message'),
              title: t('organisation_management.user_management.update.toast.error.active_exists.title'),
              type: 'error',
            });
            break;
          }
          default: {
            addToast({
              message: t('organisation_management.user_management.update.toast.error.message'),
              title: t('organisation_management.user_management.update.toast.error.title'),
              type: 'error',
            });
          }
        }
      },
      onSuccess: () => {
        addToast({
          message: t('organisation_management.user_management.update.toast.succes.message'),
          title: t('organisation_management.user_management.update.toast.succes.title'),
          type: 'success',
        });
        queryClient.invalidateQueries(getGetContactDetailsIdQueryKey(userId));
      },
    },
  });

  const handleOnSubmit: ComponentProps<typeof FormOrganisationUser>['onSubmit'] = data => {
    mutate({ data, id: userId });
  };

  return (
    !isLoading && (
      <FormOrganisationUser
        onSubmit={handleOnSubmit}
        isLoading={isLoading || isMutateLoading}
        defaultValues={{
          ...user,
          authorizationRoles: user?.roles,
          email: user?.email || '',
          teamIds: user?.teams.map(team => team.id),
        }}
        action={<DeleteOrganisationUserModal userId={userId} disabled={isCurrentUser} />}
      />
    )
  );
};
