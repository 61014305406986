import { createMainCategory, createSelectedMainCategory, createSelectedSubCategory } from './ticket';
import type {
  KnowledgeArticleDetailDto,
  KnowledgeArticleListDto,
  KnowledgeArticleListDtoListResultDto,
  SelectDto,
} from '../generated/model';

export const createKnowledgeArticleListResult = (
  overrides: Partial<KnowledgeArticleListDtoListResultDto> = {}
): KnowledgeArticleListDtoListResultDto => ({
  items: [
    createKnowledgeListArticle(),
    createKnowledgeListArticle({ articlePublicNumber: 'KA-0100102', id: '1' }),
    createKnowledgeListArticle({ articlePublicNumber: 'KA-0100103', id: '2' }),
  ],
  totalCount: 3,
  ...overrides,
});

export const createKnowledgeListArticle = (
  overrides: Partial<KnowledgeArticleListDto> = {}
): KnowledgeArticleListDto => ({
  articlePublicNumber: 'KA-0100101',
  createdOn: '2021-09-01T00:00:00Z',
  id: '0',
  mainCategory: createSelectedMainCategory(),
  modifiedOn: '2021-10-01T00:00:00Z',
  subCategory: createSelectedSubCategory(),
  title: 'Onze klant geeft een afwijkende meterstand door, heeft er een meterwissel plaatsgevonden?',
  ...overrides,
});

export const createKnowledgeArticleDetail = (
  overrides: Partial<KnowledgeArticleDetailDto> = {}
): KnowledgeArticleDetailDto => ({
  ...createKnowledgeListArticle(),
  attachments: [
    {
      bytes: 123,
      fileName: 'Example document',
      id: '0',
    },
    {
      bytes: 123,
      fileName: 'Name file',
      id: '1',
    },
  ],
  content: `Dolore laborum officia sunt esse laboris consectetur consectetur do. Est amet enim aute occaecat. Aute excepteur commodo est laboris nostrud irure sunt ea nisi ut. Nostrud proident non eiusmod laborum magna minim occaecat consectetur minim.

Sit exercitation excepteur et cupidatat. Ad deserunt ea non aliquip minim. Officia irure id irure non nisi proident veniam officia sunt et. Eiusmod sunt labore cillum exercitation laborum esse sit laboris mollit sit anim nisi sit velit quis. Id voluptate veniam ut consequat dolore duis incididunt non aute duis sunt.

<section>
        <h1>Section 1</h1>
        <p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit</strong>.

Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. <u>Underlined text example</u>.</p>
        <p><em>Duis vulputate commodo lectus</em>, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui.</p>
        <div>This is a <span style="color: blue;">span</span> inside a div. <br> Here's a line break.</div>
        <figure>
            <img src="https://via.placeholder.com/150" alt="Placeholder Image">
            <figcaption>Example of a placeholder image.</figcaption>
        </figure>
    </section>
    <section>
        <h2>Section 2</h2>
        <p>Quisque at <a href="#">dolor gravida</a> tortor varius dictum. Proin ut ligula vel nunc egestas porttitor. Donec dictum metus in sapien.</p>
        <img src="/msdyn_knowledgearticleimages(123)" />
        <blockquote>"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."</blockquote>
        <ul>
            <li>Unordered list item 1</li>
            <li>Unordered list item 2</li>
            <li>Unordered list item 3</li>
        </ul>
        <ol>
            <li>Ordered list item 1</li>
            <li>Ordered list item 2</li>
            <li>Ordered list item 3</li>
        </ol>
    </section>
    <section>
        <h3>Section 3</h3>
        <p>Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. <strong>Strong text example</strong>.</p>
    </section>`,
  ...overrides,
});

export const createKnowledgeArticleCategory = (overrides: Partial<SelectDto> = {}): SelectDto => {
  const category = createMainCategory();
  return { id: category.id, label: category.name, ...overrides };
};
