import { z } from 'zod';
import { type CprContactDto, type CprContactFilter, MarketRole } from '#edsn/api/pie-bff';
import { createFiltersAtom } from './createFiltersAtom';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';
import type { ZodType } from 'zod';
import { getValues } from '#pie/utils/ZodUtils';

export const defaultCprContactFilters = {
  contactGroupIds: [],
  marketPartyIds: [],
  marketRoles: [],
  organisationIds: [],
} satisfies z.infer<typeof cprContactFiltersSchema>;

export const cprContactFiltersSchema: ZodType<CprContactFilter, z.ZodTypeDef, unknown> = z
  .object({
    contactGroupIds: z.array(z.string()).optional(),
    marketPartyIds: z.array(z.string()).optional(),
    marketRoles: z.array(z.enum(getValues(MarketRole))).optional(),
    organisationIds: z.array(z.string()).optional(),
  })
  .catch(defaultCprContactFilters);

export const cprContactFiltersAtom = createFiltersAtom<CprContactFilter>({
  defaultFilters: defaultCprContactFilters,
  filtersSchema: cprContactFiltersSchema,
});

export const defaultCprContactSorting: DefaultSorting<CprContactDto> = {
  desc: false,
  id: 'name',
};

export const cprContactSortingAtom = createSortingAtom({ defaultSorting: defaultCprContactSorting });
