import { createTRPCReact, httpLink } from '@trpc/react-query';
import type { AppRouter } from '#edsn/pie-public-trpc';
import type { HeaderSchema } from '#edsn/pie-public-trpc/trpc';
import type { Locale } from '#edsn/shared';
import { VITE_TRPC_API_URI } from '#pie/env';

export const trpc = createTRPCReact<AppRouter>({});

export function getBatchLink({ locale, urlPathname }: { locale: Locale; urlPathname: string }) {
  return httpLink<AppRouter>({
    headers: {
      'x-edsn-locale': locale,
      'x-edsn-path': urlPathname,
    } satisfies HeaderSchema,
    url: VITE_TRPC_API_URI + '/api/trpc',
  });
}
