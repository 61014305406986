import CsvIcon from '@iconify/icons-material-symbols/csv';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { usePostCprContactExport } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { cprOrganisationDetailFiltersAtom, cprOrganisationDetailSortingAtom } from '#pie/stores/cprOrganisationDetail';
import { paginationAtom } from '#pie/stores/pagination';
import { searchAtom } from '#pie/stores/search';
import { downloadBlob } from '#pie/utils/downloadBlob';

export const CprOrganisationDetailExport = () => {
  const { t } = useTranslation();
  const organisationId = useParams()['organisationId']!;
  const pagination = useAtomValue(paginationAtom);
  const filter = useAtomValue(cprOrganisationDetailFiltersAtom);
  const sorting = useAtomValue(cprOrganisationDetailSortingAtom);
  const search = useAtomValue(searchAtom);
  const debouncedSearch = useDebounce(search, 500);
  const sortCol = sorting[0];

  const { mutate, isLoading: isDownloadingCsv } = usePostCprContactExport({
    mutation: {
      onSuccess: (data: Blob) => {
        gtag('event', 'export', {
          event_category: 'cpr_organisation_detail',
          event_label: 'csv',
        });
        downloadBlob(data, `Cpr_organisaties_detail_${format(new Date(), 'yyyy-MM-dd')}.csv`);
      },
    },
  });

  return (
    <Button
      className="mr-4"
      iconStart={CsvIcon}
      isLoading={isDownloadingCsv}
      variant="ghost"
      onClick={() =>
        mutate({
          data: {
            filter: { ...filter, organisationIds: [organisationId] },
            pagination,
            search: debouncedSearch,
            sorting: sortCol && { descending: sortCol.desc, field: sortCol.id },
          },
        })
      }
    >
      {t('cpr_organisation_detail_overview.header.button.export')}
    </Button>
  );
};
