import infoIcon from '@iconify/icons-material-symbols/info-outline';
import { type ComponentProps, useState } from 'react';
import { IconButton } from '../icon-button/IconButton';
import { Dialog } from '../layout/dialog/Dialog';
import type { Tooltip } from '../tooltip/Tooltip';

interface Props
  extends Omit<ComponentProps<typeof Tooltip>, 'children' | 'message'>,
    Pick<ComponentProps<typeof Dialog>, 'title'> {
  children: string;
}

export const InfoDialog = ({ children, title }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton variant="text" icon={infoIcon} onClick={() => setOpen(true)} size={16} />
      <Dialog title={title} open={open} onOpenChange={setOpen}>
        {children}
      </Dialog>
    </>
  );
};
