import spinnerIcon from '@iconify/icons-material-symbols/progress-activity';
import { Icon } from '../icon/Icon';

export const AuthLoading = () => (
  <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-neutral-100">
    <Icon
      role="progressbar"
      aria-label="loading"
      icon={spinnerIcon}
      size={48}
      className="animate-spin text-neutral-100"
    />
  </div>
);
