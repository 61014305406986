import * as Switch from '@radix-ui/react-switch';
import { forwardRef } from 'react';
import { Text } from '../text/Text';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import { tv } from '#pie/utils/TailwindUtils';

export interface InputToggleProps
  extends Omit<Switch.SwitchProps, 'onChange' | 'value'>,
    Omit<VariantProps<typeof inputToggle>, 'checked'> {
  label?: string;
  onChange?: (value?: boolean) => void;
}

export const InputToggle = forwardRef<HTMLButtonElement, InputToggleProps>(function InputToggle(
  { label, isError, onCheckedChange, onChange, ...props },
  ref
) {
  const { disabled, id, checked } = props;
  const styles = inputToggle({ checked: !!checked, disabled, isError });

  return (
    <label className={styles.wrapper()} htmlFor={id}>
      <Switch.Root
        ref={ref}
        onCheckedChange={checked => onChange?.(checked)}
        aria-label={label}
        className={styles.root()}
        {...props}
      >
        <Switch.Thumb className={styles.thumb()} />
      </Switch.Root>

      {!!label && <Text className={styles.label()}>{label}</Text>}
    </label>
  );
});

const inputToggle = tv({
  slots: {
    label: 'text-neutral-600 cursor-pointer',
    root: 'relative h-7 w-12 rounded-[14px] bg-neutral-300 p-1 ',
    thumb:
      'block h-5 w-5 translate-x-0 rounded-full bg-white transition-transform duration-300 data-[state=checked]:translate-x-full',
    wrapper: 'flex items-center gap-3',
  },
  variants: {
    checked: {
      true: { root: 'bg-primary-light focus-visible:outline-primary shadow-none' },
    },
    disabled: {
      true: {
        label: 'text-neutral-500 cursor-not-allowed',
        root: 'cursor-not-allowed opacity-50',
        wrapper: 'cursor-not-allowed',
      },
    },
    isError: {
      true: {
        label: 'text-error',
        root: 'data-[state=checked]:bg-error data-[state=checked]:outline-error bg-error-light outline-error',
      },
    },
  },
});
