import type { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
  elseWrapper?: (children: React.ReactNode) => React.ReactNode;
}

export const ConditionalWrapper: React.FC<Props> = ({ condition, wrapper, elseWrapper, children }) =>
  condition ? wrapper(children) : elseWrapper ? elseWrapper(children) : children;
