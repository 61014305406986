import { Fragment } from 'react';
import { Divider } from '../divider/Divider';
import { StepperItem, type Props as StepperItemProps } from './stepper-item/StepperItem';

interface StepperProps {
  steps: Omit<StepperItemProps, 'step'>[];
}

export const Stepper = ({ steps, ...props }: StepperProps) => (
  <div className="flex items-center gap-3" aria-label="Steps" {...props}>
    {steps.map((step, index) => {
      const stepNumber = index + 1;
      return (
        <Fragment key={`step-${stepNumber}`}>
          {index !== 0 && <Divider className="w-7" />}
          <StepperItem step={stepNumber} aria-current={step.active ? 'step' : undefined} {...step} />
        </Fragment>
      );
    })}
  </div>
);

export * from './stepper-item/StepperItem';
