import IconClose from '@iconify/icons-material-symbols/close';
import xor from 'lodash.xor';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionItem } from '../accordion/Accordion';
import { CollapseStack } from '../collapse-stack/CollapseStack';
import { Icon } from '../icon/Icon';
import { InputCheckbox } from '../input-checkbox/InputCheckbox';
import { InputSearch } from '../input-search/InputSearch';
import { Drawer } from '../layout/drawer/Drawer';
import { Text } from '../text/Text';
import type { mapToFilterOptions } from '#pie/utils/mapToFilterOptions';
import type { ComponentProps } from 'react';
import { ConditionalWrapper } from '#pie/utils/ConditionalWrapper';
import { cn } from '#pie/utils/TailwindUtils';

export interface Filter<TFilter extends object> {
  id: keyof TFilter;
  label: string;
  options: ReturnType<typeof mapToFilterOptions>;
  values: string[];
}

export interface Props<TFilter extends object> extends ComponentProps<'div'> {
  filters: Filter<TFilter>[];
  activeFilters: TFilter;
  setActiveFilters: (activeFilters: TFilter) => TFilter;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  drawer?: boolean;
}

export const DataFilters = <TFilter extends object>({
  filters,
  activeFilters,
  setActiveFilters,
  open = false,
  onOpenChange,
  drawer = false,
  className,
  ...props
}: Props<TFilter>) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<Record<string, string>>({});

  return (
    <ConditionalWrapper
      condition={drawer}
      // TODO: remove ignore when we start using the drawer functionality in knowledge base
      /* c8 ignore start */
      wrapper={children => (
        <Drawer
          title={t('common.filter.title')}
          aria-label="filter-panel"
          open={open}
          onOpenChange={onOpenChange}
          {...props}
        >
          {children}
        </Drawer>
      )}
      /* c8 ignore end */
      elseWrapper={children =>
        open ? (
          <div role="search" aria-label="filter-panel" className={cn('w-[362px]', className)} {...props}>
            <div className="mb-4 flex items-center justify-between">
              <Text as="h2" variant="h4" className="text-primary-dark">
                {t('common.filter.title')}
              </Text>

              <button onClick={() => onOpenChange(!open)} aria-label="Sluiten">
                <Icon icon={IconClose} className="text-primary" />
              </button>
            </div>
            {children}
          </div>
        ) : null
      }
    >
      <Accordion
        type="multiple"
        defaultValue={filters.filter(filter => filter.values.length).map(({ id }) => id as string)}
      >
        {filters.map(({ id, label, options, values }) => {
          /* c8 ignore next */
          if (typeof id !== 'string') throw new Error('Filter id must be a string');

          return (
            <AccordionItem key={id} title={label} value={id}>
              {options.length > 15 && (
                <InputSearch
                  value={search[id] || ''}
                  onChange={e => setSearch(prev => ({ ...prev, [id]: e.target.value }))}
                  placeholder={t('common.filter.search_placeholder')}
                  onClear={() => setSearch(prev => ({ ...prev, [id]: '' }))}
                  fullWidth
                  className="mb-4"
                />
              )}
              <CollapseStack collapseAt={4}>
                {options
                  .filter(option => option.label.toLowerCase().includes(search[id]?.toLowerCase() ?? ''))
                  .map(option => {
                    const optionId = `${id}-${option.value}`;
                    return (
                      <InputCheckbox
                        key={optionId}
                        id={optionId}
                        label={option.label}
                        value={option.value}
                        checked={values.includes(option.value)}
                        onCheckedChange={checked => {
                          gtag('event', 'filter_changed', {
                            checked,
                            filter: id,
                            value: option.value,
                          });
                          setActiveFilters({
                            ...activeFilters,
                            [id]: xor(activeFilters[id as keyof TFilter], [option.value]),
                          });
                        }}
                      />
                    );
                  })}
              </CollapseStack>
            </AccordionItem>
          );
        })}
      </Accordion>
    </ConditionalWrapper>
  );
};
