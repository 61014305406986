import { useTranslation } from 'react-i18next';
import { CacheKey, usePostCacheClear } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { Page, PageHeader } from '#pie/components/page/Page';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { useToast } from '#pie/components/toast/ToastContext';

export const ClearCache = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const { mutate, isLoading } = usePostCacheClear();
  const postClearCache = (cacheKey: CacheKey) => {
    mutate(
      { data: { cacheKey } },
      {
        onError: () => {
          addToast({
            message: t('clear_cache.toast.error.message'),
            title: t('clear_cache.toast.error.title'),
            type: 'error',
          });
        },
        onSuccess: () => {
          addToast({
            message: t('clear_cache.toast.success.message'),
            title: t('clear_cache.toast.success.title'),
            type: 'success',
          });
        },
      }
    );
  };
  return (
    <Page>
      <PageHeader title="Clear Cache" />
      <div className="container">
        <Stack gap="md" divider="line">
          <div>
            <Text as="h1" variant="h3">
              {t('clear_cache.block.user.title')}
            </Text>
            <Text className="my-4">{t('clear_cache.block.user.description')}</Text>
            <Button isLoading={isLoading} onClick={() => postClearCache(CacheKey.UserSpecific)}>
              {t(`clear_cache.button.${CacheKey.UserSpecific}`)}
            </Button>
          </div>
          <div>
            <Text as="h1" variant="h3">
              {t('clear_cache.block.organisation.title')}
            </Text>
            <Text className="my-4">{t('clear_cache.block.organisation.description')}</Text>
            <Button isLoading={isLoading} onClick={() => postClearCache(CacheKey.OrganizationSpecific)}>
              {t(`clear_cache.button.${CacheKey.OrganizationSpecific}`)}
            </Button>
          </div>
          <div>
            <Text as="h1" variant="h3">
              {t('clear_cache.block.global.title')}
            </Text>
            <Text className="my-4">{t('clear_cache.block.global.description')}</Text>
            <Stack direction="row" gap="md">
              <Button isLoading={isLoading} onClick={() => postClearCache(CacheKey.GridOperatorsAll)}>
                {t(`clear_cache.button.${CacheKey.GridOperatorsAll}`)}
              </Button>
              <Button isLoading={isLoading} onClick={() => postClearCache(CacheKey.MainCategoriesAll)}>
                {t(`clear_cache.button.${CacheKey.MainCategoriesAll}`)}
              </Button>
              <Button isLoading={isLoading} onClick={() => postClearCache(CacheKey.TicketSurvey)}>
                {t(`clear_cache.button.${CacheKey.TicketSurvey}`)}
              </Button>
              <Button isLoading={isLoading} onClick={() => postClearCache(CacheKey.RejectionCategoriesAll)}>
                {t(`clear_cache.button.${CacheKey.RejectionCategoriesAll}`)}
              </Button>
              <Button isLoading={isLoading} onClick={() => postClearCache(CacheKey.KnowledgeArticlesAll)}>
                {t(`clear_cache.button.${CacheKey.KnowledgeArticlesAll}`)}
              </Button>
              <Button isLoading={isLoading} onClick={() => postClearCache(CacheKey.Cpr)}>
                {t(`clear_cache.button.${CacheKey.Cpr}`)}
              </Button>
            </Stack>
          </div>
        </Stack>
      </div>
    </Page>
  );
};
