import crossIcon from '@iconify/icons-material-symbols/cancel-outline';
import checkIcon from '@iconify/icons-material-symbols/check-circle-outline';
import closeIcon from '@iconify/icons-material-symbols/close';
import infoIcon from '@iconify/icons-material-symbols/info-outline';
import { motion } from 'framer-motion';
import { forwardRef, useEffect, useRef } from 'react';
import { Button } from '../button/Button';
import { Icon } from '../icon/Icon';
import { IconButton } from '../icon-button/IconButton';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';
import type { ButtonProps } from '../button/Button';
import type { ElementType, ReactNode } from 'react';
import { tv } from '#pie/utils/TailwindUtils';

export interface ToastProps {
  title: string;
  message?: string;
  action?: ReactNode;
  type?: 'success' | 'error' | 'info';
  onClose: () => void;
}

export const Toast = forwardRef<HTMLDivElement, ToastProps>(
  ({ title, message, action, type = 'info', onClose, ...props }, ref) => {
    const s = styles({ type });

    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
      timeout.current = setTimeout(onClose, 3000);

      return () => {
        timeout.current && clearTimeout(timeout.current);
      };
    }, [onClose]);

    const handleMouseEnter = () => {
      timeout.current && clearTimeout(timeout.current);
    };

    const handleMouseLeave = () => {
      timeout.current = setTimeout(onClose, 3000);
    };

    const icon = type === 'success' ? checkIcon : type === 'error' ? crossIcon : infoIcon;

    return (
      <motion.div
        layout
        className={s.base()}
        initial={{ opacity: 0, x: 0, y: -100 }}
        animate={{ opacity: 1, x: 0, y: 0 }}
        exit={{ opacity: 0, x: '100%', y: 0 }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        role="alert"
        aria-label={title}
        ref={ref}
        {...props}
      >
        <Stack direction="row" gap="lg" className="items-start">
          <Stack direction="row" gap="sm">
            <Icon icon={icon} className={s.icon()} size={16} />
            <Stack className="items-start" gap="md">
              <Stack className="max-w-[240px]">
                <Text as="h5" variant="sm" className="font-bold">
                  {title}
                </Text>
                <Text variant="xs" className="whitespace-pre-line text-neutral-500">
                  {message}
                </Text>
              </Stack>
              {action}
            </Stack>
          </Stack>
          <IconButton aria-label="Close toast" icon={closeIcon} size={16} onClick={onClose} />
        </Stack>
      </motion.div>
    );
  }
);

export const ToastButton = <TComponent extends ElementType = 'button'>(props: ButtonProps<TComponent>) => (
  <Button variant="ghost" size="sm" {...props} />
);

const styles = tv({
  base: 'rounded p-4 pl-3 relative bg-white shadow-lg before:content-[""] before:absolute before:top-0 before:left-0 before:w-1 before:h-full before:bg-primary-500 overflow-hidden',
  slots: {
    icon: 'p-1 mt-[-0.1em] box-content',
  },
  variants: {
    type: {
      error: {
        base: 'before:bg-error',
        icon: 'text-error',
      },
      info: {
        base: ' before:bg-primary',
        icon: 'text-primary',
      },
      success: {
        base: ' before:bg-success',
        icon: 'text-success',
      },
    },
  },
});
