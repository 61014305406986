import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ManagementContactFilter } from '#edsn/api/pie-bff';
import { useGetManagementContact } from '#edsn/api/pie-bff';
import { FormCombobox } from '../form/form-combobox/FormCombobox';
import type { Props as InputComboboxProps } from '../input-combobox/InputCombobox';
import type { ComponentProps } from 'react';

interface Props
  extends Omit<Partial<ComponentProps<typeof FormCombobox>>, 'options' | 'name' | 'onLoadMore' | 'totalCount'> {
  label: string;
  name?: string;
  filter: ManagementContactFilter;
}

export const FormContactCombobox = ({ name = '', filter, ...props }: Props) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<InputComboboxProps['options']>(undefined);
  const [search, setSearch] = useState<string | undefined>(props.defaultValue);
  const [pageIndex, setPageIndex] = useState(0);

  const { data, isLoading } = useGetManagementContact({
    filter,
    pagination: {
      pageIndex,
      pageSize: 20,
    },
    search,
  });

  useEffect(() => {
    const optionMap = new Map();

    // Add existing options to the map
    (options || []).forEach(option => {
      optionMap.set(option.value, option);
    });

    // Add items from data to the map
    (data?.items || []).forEach(item => {
      optionMap.set(item.id, { label: item.fullName, value: item.id });
    });

    // Convert the map values to an array
    setOptions(Array.from(optionMap.values()));
  }, [data]);

  const onSearch = (searchTerm: typeof search) => {
    setOptions(undefined);
    setSearch(searchTerm);
    setPageIndex(0);
  };

  return (
    <FormCombobox
      name={name}
      options={options}
      onSearch={onSearch}
      isLoading={isLoading}
      shouldFilter={false}
      placeholder={t('cpr_contact_group_detail.add_user.contact.placeholder')}
      onLoadMore={() => setPageIndex(pageIndex + 1)}
      totalCount={data?.totalCount}
      {...props}
    />
  );
};
