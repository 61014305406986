/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type TicketOrigin = (typeof TicketOrigin)[keyof typeof TicketOrigin];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TicketOrigin = {
  UNKNOWN: 'UNKNOWN',
  Chat: 'Chat',
  Email: 'Email',
  Phone: 'Phone',
  Web: 'Web',
  GridOperator: 'GridOperator',
} as const;
