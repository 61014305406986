import { AuthorizationRoles } from '../generated/model';
import type { ContactDetailsDto, ContactDetailsDtoListResultDto, SelectDto } from '../generated/model';

export const createContactsDetails = (
  overrides: Partial<ContactDetailsDtoListResultDto> = {}
): ContactDetailsDtoListResultDto => ({
  items: [
    createContactDetails(),
    createContactDetails({
      email: 'pdevries@dexample.com',
      fullName: 'Piet de Vries',
      id: 'd34e5b5d-43ff-4cac-9087-3f843bd2c738',
    }),
  ],
  totalCount: 2,
  ...overrides,
});

export const createContactDetails = (overrides: Partial<ContactDetailsDto> = {}): ContactDetailsDto => ({
  contactGroups: [
    createContactGroup(),
    createContactGroup({ id: '20c07f28-008b-46c8-a276-ae73bfca17fb', label: 'Zuivere teruglevering' }),
  ],
  email: 'jdejong@dexample.com',
  firstName: 'Jan',
  fullName: 'Jan de Jong',
  id: 'afd5e7af-2681-a5dc-3902-6c75cc096a98',
  isActive: true,
  lastName: 'jong',
  phone: '0612341234',
  roles: [
    AuthorizationRoles.Tickets,
    AuthorizationRoles.KnowledgeArticles,
    AuthorizationRoles.CprRead,
    AuthorizationRoles.DataRequest,
  ],
  teams: [createTeam(), createTeam({ id: 'b8808194-2ace-4d34-a548-e17b96d5e9e8', label: 'Dreamteam' })],
  ...overrides,
});

export const createTeam = (overrides: Partial<SelectDto> = {}): SelectDto => ({
  id: '086dc17c-2900-4df7-be25-2045ea78bf85',
  label: 'Pikablue',
  ...overrides,
});

export const createContactGroup = (overrides: Partial<SelectDto> = {}): SelectDto => ({
  id: 'c17cebd6-433e-471a-875c-6354dc3e6aa3',
  label: 'Algemene groep',
  ...overrides,
});
