import { Icon as Iconify, type IconifyIcon, type IconProps as IconifyProps } from '@iconify/react';
import { type VariantProps, tv } from '#pie/utils/TailwindUtils';

export interface IconProps extends VariantProps<typeof icon>, IconifyProps {
  icon: IconifyIcon;
}

export function Icon({ className, size = 24, ...props }: IconProps) {
  return <Iconify {...props} className={icon({ className, size })} />;
}

const icon = tv({
  base: 'inline-flex fill-current align-middle',
  variants: {
    size: {
      12: 'text-[12px]',
      16: 'text-[16px]',
      24: 'text-[24px]',
      32: 'text-[32px]',
      48: 'text-[48px]',
    },
  },
});
