import { type AttachmentDto, useGetAttachmentId } from '#edsn/api/pie-bff';
import { FileAnchor } from '../file-anchor/FileAnchor';
import { downloadBlob } from '#pie/utils/downloadBlob';

export interface AttachmentAnchorProps {
  className?: string;
  attachment: AttachmentDto;
}

export const AttachmentAnchor: React.FC<AttachmentAnchorProps> = ({ className, attachment }) => {
  const { refetch, isFetching } = useGetAttachmentId(attachment.id, {
    query: {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  });

  const handleClick = async () => {
    const { data } = await refetch();
    if (data) {
      downloadBlob(data, attachment.fileName);
    }
  };

  return (
    <FileAnchor
      fileName={attachment.fileName}
      fileSize={attachment.bytes}
      onClick={handleClick}
      isLoading={isFetching}
    />
  );
};
