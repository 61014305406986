import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { type ComponentProps, useState } from 'react';
import type { DataFilters } from '#pie/components/data-filters/DataFilters';
import type { mapToFilterOptions } from '#pie/utils/mapToFilterOptions';
import type { WritableAtom } from 'jotai';

export interface Props<TFilterData extends object, TFilter extends object> {
  filtersQuery: () => Promise<TFilterData>;
  filtersAtom: WritableAtom<TFilter, [TFilter], TFilter>;
  filters: {
    id: keyof TFilter;
    label: string;
    options:
      | ((data: TFilterData | undefined) => ReturnType<typeof mapToFilterOptions>)
      | ReturnType<typeof mapToFilterOptions>;
  }[];
}

export const useDataFilters = <TFilterData extends object, TFilter extends object>({
  filtersQuery,
  filtersAtom,
  filters: definedFilters,
}: Props<TFilterData, TFilter>): ComponentProps<typeof DataFilters<TFilter>> => {
  const { data } = useQuery({
    /* c8 ignore next */
    queryFn: () => filtersQuery(),
    // TODO: Find a way to get the url of the query instead of using the name
    queryKey: [filtersQuery.name],
  });
  const [open, onOpenChange] = useState(false);
  const [activeFilters, setActiveFilters] = useAtom(filtersAtom);
  const filters: ComponentProps<typeof DataFilters<TFilter>>['filters'] = definedFilters.map(
    ({ id, options, label }) => ({
      id,
      label,
      options: Array.isArray(options) ? options : options(data),
      values: activeFilters[id] ?? [],
    })
  );

  return {
    activeFilters,
    filters,
    onOpenChange,
    open,
    setActiveFilters,
  };
};
