import { differenceInDays, format, isSameYear, isToday, isTomorrow, isYesterday } from 'date-fns';

export const getRelativeDate = (date: Date) => {
  const now = new Date();
  if (isToday(date)) {
    return `Vandaag ${format(date, 'HH:mm')}`;
  } else if (isYesterday(date)) {
    return `Gisteren ${format(date, 'HH:mm')}`;
  } else if (isTomorrow(date)) {
    return `Morgen ${format(date, 'HH:mm')}`;
  } else {
    const diffInDays = differenceInDays(now, date);
    if (diffInDays <= 6) {
      return `${format(date, 'EEEE HH:mm')}`;
    } else {
      if (isSameYear(now, date)) {
        return `${format(date, 'dd MMMM HH:mm')}`;
      } else {
        return `${format(date, 'dd MMMM yyyy HH:mm')}`;
      }
    }
  }
};

export const getFormattedDate = (date: Date, showTime = true) =>
  format(date, `dd-MM-yyyy ${showTime ? 'HH:mm' : ''}`).trim();
