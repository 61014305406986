import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useGetKnowledgeArticle } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { DataTable } from '#pie/components/data-table/DataTable';
import { KnowledgeArticleToolbar } from '#pie/components/knowledge-article-toolbar/KnowledgeArticleToolbar';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { useDebounce } from '#pie/hooks/useDebounce';
import { getFormattedDate } from '#pie/i18n/dateFormats';
import { knowledgeArticleFiltersAtom, knowledgeArticleSortingAtom } from '#pie/stores/knowledgeArticle';
import { paginationAtom } from '#pie/stores/pagination';
import { searchAtom } from '#pie/stores/search';
import { parseDates } from '#pie/utils/date';

export const KnowledgeBase = () => {
  const { t } = useTranslation();

  const pagination = useAtomValue(paginationAtom);
  const filter = useAtomValue(knowledgeArticleFiltersAtom);
  const sorting = useAtomValue(knowledgeArticleSortingAtom)[0];
  const search = useAtomValue(searchAtom);
  const debouncedSearch = useDebounce(search, 500);

  const { data, isLoading } = useGetKnowledgeArticle(
    {
      filter: Object.fromEntries(Object.entries(filter).map(([key, value]) => [key, value.filter(Boolean)])),
      pagination,
      search: debouncedSearch,
      sorting: { descending: sorting.desc, field: sorting.id },
    },
    {
      query: {
        select: data => ({
          ...data,
          items: data.items.map(item => ({ ...parseDates(item, ['modifiedOn', 'createdOn']) })),
        }),
      },
    }
  );

  return (
    <>
      <PageHead title={t('knowledge_base.page_title')} />
      <Page>
        <PageHeader title={t('knowledge_base.header.title')} className="mb-8"></PageHeader>
        <section className="flex items-start gap-6">
          <Card className="flex-1 overflow-x-hidden p-4">
            <Stack gap="md">
              <KnowledgeArticleToolbar />
              <DataTable
                data={data?.items}
                sortingAtom={knowledgeArticleSortingAtom}
                isLoading={isLoading}
                totalCount={data?.totalCount}
                rowLinks={row => ({
                  'aria-label': row.original.articlePublicNumber,
                  to: `/kennisbank/${row.original.id}`,
                })}
                columns={helper => [
                  helper.accessor('articlePublicNumber', {
                    header: t('knowledge_base.article_table.columns.id'),
                  }),
                  helper.accessor('mainCategory.label', {
                    header: t('common.main_category'),
                    meta: { columnWidth: 170 },
                  }),
                  helper.accessor('subCategory.label', {
                    header: t('common.sub_category'),
                    meta: { columnWidth: 170 },
                  }),
                  helper.accessor('title', {
                    header: t('knowledge_base.article_table.columns.title'),
                    meta: { columnWidth: 300 },
                  }),
                  helper.accessor('createdOn', {
                    cell: info => {
                      const date = info.getValue();
                      return date && getFormattedDate(date);
                    },
                    header: t('knowledge_base.article_table.columns.created_on'),
                  }),
                  helper.accessor('modifiedOn', {
                    cell: info => {
                      const date = info.getValue();
                      return date && getFormattedDate(date);
                    },
                    header: t('knowledge_base.article_table.columns.modified_on'),
                  }),
                ]}
              />
            </Stack>
          </Card>
        </section>
      </Page>
    </>
  );
};
