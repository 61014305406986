import { atom } from 'jotai';
import { z } from 'zod';
import { SearchParam, paramsAtom } from './searchParams';
import type { PaginationState } from '@tanstack/react-table';

export const pageIndexSchema = z.coerce.number().int().positive().catch(0);
export const pageSizeSchema = z.union([z.literal(10), z.literal(25), z.literal(50), z.literal(100)]).catch(10); // Cannot use enum

export const paginationAtom = atom<PaginationState, [PaginationState], PaginationState>(
  get => {
    const searchParams = get(paramsAtom);

    const pageIndex = pageIndexSchema.parse(searchParams?.get(SearchParam.pageIndex));
    const size = searchParams?.get(SearchParam.pageSize);
    const pageSize = pageSizeSchema.parse(size ? +size : undefined);

    return { pageIndex, pageSize };
  },
  (_get, set, paginationState) => {
    set(paramsAtom, searchParams => {
      const params = new URLSearchParams(searchParams);

      const pageIndex = pageIndexSchema.parse(paginationState.pageIndex);
      const pageSize = pageSizeSchema.parse(paginationState.pageSize);

      if (pageIndex) {
        params.set(SearchParam.pageIndex, pageIndex.toString());
      } else {
        params.delete(SearchParam.pageIndex);
      }

      params.set(SearchParam.pageSize, pageSize.toString());

      return params;
    });

    return paginationState;
  }
);
