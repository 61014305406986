import { useOidc } from '@axa-fr/react-oidc';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '#pie/components/button/Button';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';

export const AuthExpired = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const oidc = useOidc();
  const [params] = useSearchParams();

  useEffect(() => {
    const redirect = params.get('redirect');
    if (oidc.isAuthenticated && redirect && !redirect.includes('/auth-')) {
      navigate(redirect);
    }
  }, [oidc.isAuthenticated]);

  useEffect(() => {
    Sentry.captureException(new Error(`Auth expired`));
  }, []);

  return (
    <>
      <PageHead title={t('auth_expired.title')} noIndex />
      <Stack className="items-start px-8 py-4" gap="md">
        <Text as="h1" variant="h3" className="mb-4">
          {t('auth_expired.title')}
        </Text>
        <Text>{t('auth_expired.message')}</Text>
        <Button onClick={() => oidc.login('/', { prompt: 'login' })}>{t('common.login')}</Button>
      </Stack>
    </>
  );
};
