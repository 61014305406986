import { useTranslation } from 'react-i18next';
import { SurveyDialogueStatus } from '#edsn/api/pie-bff';
import { StatusTag } from '../status-tag/StatusTag';
import type { ComponentProps } from 'react';

export interface StatusTagProps {
  className?: string;
  status: SurveyDialogueStatus;
}

const twStatusMap: Record<SurveyDialogueStatus, ComponentProps<typeof StatusTag>['status']> = {
  [SurveyDialogueStatus.Reopened]: 'attention',
  [SurveyDialogueStatus.Closed]: 'closed',
  [SurveyDialogueStatus.InProgress]: 'progress',
  [SurveyDialogueStatus.New]: 'new',
};

export function SurveyDialogueStatusTag({ className, status }: StatusTagProps) {
  const { t } = useTranslation();
  return (
    <StatusTag status={twStatusMap[status]} className={className}>
      {t(`survey_dialogue.status.${status}`)}
    </StatusTag>
  );
}
