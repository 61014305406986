import { Stack } from '../stack/Stack';
import type { PropsWithChildren, ReactNode } from 'react';

export interface MetaInfoProps extends PropsWithChildren {
  children?: ReactNode[] | ReactNode;
  className?: string;
  hasMiddleDot?: boolean;
}

export function MetaInfo({ className, children, hasMiddleDot }: MetaInfoProps) {
  return (
    <Stack as="ul" direction="row" gap="sm" divider={hasMiddleDot ? 'dot' : undefined} className={className}>
      {children}
    </Stack>
  );
}

export { MetaInfoItem } from './item/MetaInfoItem';
