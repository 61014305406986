import { EffortScore, SurveyDialogueStatus, SurveySubject } from '../generated/model';
import { createSelectedContact, createSelectedRegionalGridOperator, createTicket } from './ticket';
import type { SurveyDialogueDto, SurveyDialogueDtoListResultDto } from '../generated/model';

const ticket = createTicket();

export const createSurveyDialagues = (
  overrides: Partial<SurveyDialogueDtoListResultDto> = {}
): SurveyDialogueDtoListResultDto => ({
  items: [createSurveyDialogue(), createSurveyDialogue(), createSurveyDialogue()],
  totalCount: 3,
  ...overrides,
});

export const createSurveyDialogue = (): SurveyDialogueDto => ({
  createdBy: createSelectedContact(),
  createdOn: '2022-11-27T03:39:31.8785914',
  effortScore: EffortScore.Satisfied,
  explanation: 'Een uitleg voor een tevredenheidsmeting',
  hasUnreadComments: false,
  id: '0',
  regionalGridOperator: createSelectedRegionalGridOperator(),
  regionalGridOperatorHandler: createSelectedRegionalGridOperator(),
  status: SurveyDialogueStatus.New,
  subject: SurveySubject.Employee,
  ticketId: ticket.id,
  ticketNumber: ticket.ticketNumber,
  ticketTitle: 'Tevredenheidsmeting titel',
});
