import addIcon from '@iconify/icons-material-symbols/add';
import infoIcon from '@iconify/icons-material-symbols/info-outline';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { Divider } from '#pie/components/divider/Divider';
import { FileAnchor } from '#pie/components/file-anchor/FileAnchor';
import { InputUploadButton } from '#pie/components/input-upload-button/InputUploadButton';
import { Message } from '#pie/components/message/Message';
import { Text } from '#pie/components/text/Text';
import { MAX_FILE_SIZE } from '#pie/constants/maxFileSize';
import { formatBytes } from '#pie/utils/files';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const attachmentValidationSchema = (t: ReturnType<typeof useTranslation>['t']) =>
  z.object({
    files: z
      .array(
        z.object({
          file: z.instanceof(File).refine(
            file => {
              return file.size <= MAX_FILE_SIZE;
            },
            t('zod.errors.file_too_large', { size: formatBytes(MAX_FILE_SIZE) })
          ),
        })
      )
      .max(20, t('zod.errors.max_files', { max: 20 }))
      .optional(),
  });

type AttachmentForm = z.infer<ReturnType<typeof attachmentValidationSchema>>;

const { FormField } = typedFormFields<AttachmentForm>();

export const TicketAttachmentFields = () => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<AttachmentForm>();

  const { remove, replace } = useFieldArray<AttachmentForm>({
    control,
    name: 'files',
  });

  const fields = watch('files') ?? [];

  return (
    <FormField name="files" label={t('common.attachments.add')}>
      {fields.length > 0 && (
        <div className="mb-4 flex flex-col">
          {fields.map(({ file }, idx) => (
            <div key={idx}>
              {idx !== 0 && <Divider className="my-3" />}
              <FileAnchor fileName={file.name} fileSize={file.size} onDelete={() => remove(idx)} />
              {errors.files?.[idx] && (
                <Message variant="error" icon={infoIcon} className="mt-5">
                  <Text>{errors.files?.[idx]?.file?.message}</Text>
                </Message>
              )}
            </div>
          ))}
        </div>
      )}
      <InputUploadButton
        icon={addIcon}
        variant="ghost"
        className="w-fit"
        multiple
        onChange={value => {
          const files = value.map((file: File) => {
            return { file };
          });
          replace([...fields, ...files]);
        }}
      >
        {t('common.attachments.choose_files')}
      </InputUploadButton>
    </FormField>
  );
};
