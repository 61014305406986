import { useController } from 'react-hook-form';
import type { ComponentProps } from 'react';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { InputField } from '#pie/components/input-field/InputField';
import { InputRadio } from '#pie/components/input-radio/InputRadio';

type FormRadioProps<T extends FieldValues> = ComponentProps<typeof InputRadio> &
  Pick<UseControllerProps<T>, 'disabled' | 'name' | 'rules' | 'defaultValue'> &
  Pick<ComponentProps<typeof InputField>, 'label'>;

export function FormRadio<T extends FieldValues>({
  name,
  disabled,
  rules,
  defaultValue,
  label,
  ...props
}: FormRadioProps<T>) {
  const {
    fieldState: { error },
    field,
  } = useController({
    defaultValue,
    disabled,
    name,
    rules,
  });

  return (
    <InputField name={name} isRequired={!!rules?.required} label={label} error={error?.message}>
      <InputRadio id={name} disabled={disabled} isError={!!error} {...field} {...props} />
    </InputField>
  );
}
