import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { NotificationSubcategoryDto } from '#edsn/api/pie-bff';
import { useGetNotification } from '#edsn/api/pie-bff';
import { AddNotificationButton } from '#pie/components/add-notification-button/AddNotificationButton';
import { Card } from '#pie/components/card/Card';
import { RemoveNotificationButton } from '#pie/components/remove-notification-button/RemoveNotificationButton';
import { Stack } from '#pie/components/stack/Stack';
import { Table } from '#pie/components/table/Table';
import { Text } from '#pie/components/text/Text';

export const NotificationsCategories = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetNotification();
  const helper = useMemo(() => createColumnHelper<NotificationSubcategoryDto>(), []);

  return (
    <Card
      size="md"
      heading={
        <Text as="h2" variant="h6" className="text-primary-dark">
          {t('organisation_management.tab.notifications.category_settings.title')}
        </Text>
      }
      shadow={false}
    >
      <Stack gap="md">
        <Text className="">{t('organisation_management.tab.notifications.category_settings.description')}</Text>
        {!isLoading && !data?.subcategories.map(m => m.id).length ? (
          <Text className="p-3">{t('common.no_results')}</Text>
        ) : (
          <Table
            isLoading={isLoading}
            columns={[
              helper.accessor('mainCategoryName', {
                header: t('common.main_category'),
                meta: { columnWidth: 200 },
              }),
              helper.accessor('subcategoryName', {
                header: t('common.sub_category'),
                meta: { columnWidth: 200 },
              }),
              helper.accessor('email', {
                cell: info => (
                  <div className="flex">
                    {info.getValue()}
                    <RemoveNotificationButton data={info.row.original} />
                  </div>
                ),
                header: t('common.email'),
              }),
            ]}
            getRowId={m => m.email}
            data={data?.subcategories}
            pagination={{ pageIndex: 0, pageSize: data?.subcategories.map(m => m.id).length || 0 }}
            sorting={[]}
          />
        )}
        <AddNotificationButton />
      </Stack>
    </Card>
  );
};
