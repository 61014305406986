import { useController } from 'react-hook-form';
import type { ComponentProps } from 'react';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { InputField } from '#pie/components/input-field/InputField';
import { InputToggle } from '#pie/components/input-toggle/InputToggle';

type FormToggleProps<T extends FieldValues> = {
  option: Omit<ComponentProps<typeof InputToggle>, 'name' | 'value'>;
} & Pick<UseControllerProps<T>, 'disabled' | 'name' | 'rules' | 'defaultValue'> &
  Pick<ComponentProps<typeof InputField>, 'label'>;

export function FormToggle<T extends FieldValues>({
  name,
  disabled,
  rules,
  defaultValue,
  label,
  option,
  ...props
}: FormToggleProps<T>) {
  const {
    fieldState: { error },
    field,
  } = useController({
    defaultValue,
    disabled,
    name,
    rules,
  });
  return (
    <InputField name={name} isRequired={!!rules?.required} label={label} error={error?.message} {...props}>
      <InputToggle id={name} isError={!!error} disabled={disabled} checked={field.value} {...field} {...option} />
    </InputField>
  );
}
