import { createContact } from './contact';
import type { TeamDetailsDto } from '../generated/model';

export const createTeamDetails = (overrides: Partial<TeamDetailsDto> = {}): TeamDetailsDto => ({
  contacts: [
    createContact(),
    createContact({
      email: 'pklaas@dexample.com',
      firstName: 'Piet',
      fullName: 'Piet Klaassen',
      id: 'afd5e7af-2681-a5dc-3902-6c75cc096a55',
      lastName: 'Klaassen',
      phone: '0689543784',
    }),
  ],
  id: '086dc17c-2900-4df7-be25-2045ea78bf85',
  name: 'Pikablue',
  ...overrides,
});

export const mockContacts = [
  createContact({ fullName: 'John Doe', id: '1' }),
  createContact({ fullName: 'Jane Smith', id: '2' }),
];
