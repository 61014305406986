import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useScreenSize } from './useScreenSize';

export const useSidebar = () => {
  const screenSize = useScreenSize();
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  /* c8 ignore start */
  useEffect(() => {
    if (screenSize === '2xl') {
      setIsOpen(true);
    }
  }, [screenSize, pathname]);
  /* c8 ignore end */

  return { isOpen, setIsOpen };
};
