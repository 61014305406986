import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useGetConnectionInformation } from '#edsn/api/pie-bff';
import { TicketAssigneeCard, assigneeValidationSchema } from '../TicketAssigneeCard';
import { TicketAttachmentFields, attachmentValidationSchema } from '../TicketAttachmentFields';
import { TicketConnectionCard } from '../TicketConnectionCard';
import { TicketDescription } from '../TicketDescription';
import { TicketGridOperatorsFields, gridOperatorValidationSchema } from '../TicketGridOperatorsFields';
import { TicketRejectionReasonFields, rejectionReasonValidationSchema } from '../TicketRejectionReasonFields';
import { useNonConformityFormContext } from './TicketCreateNonConformity';
import type { StepProps } from './TicketCreateNonConformity';
import { Card } from '#pie/components/card/Card';
import { ConnectionInfo } from '#pie/components/connection-info/ConnectionInfo';
import { Divider } from '#pie/components/divider/Divider';
import { EanFailure } from '#pie/components/ean-failure/EanFailure';
import { InputField } from '#pie/components/input-field/InputField';
import { InputText } from '#pie/components/input-text/InputText';
import { Page, PageHeader } from '#pie/components/page/Page';
import { RelatedKnowledgeArticlesCard } from '#pie/components/related-knowledge-articles-card/RelatedKnowledgeArticlesCard';
import { Skeleton } from '#pie/components/skeleton/Skeleton';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { VITE_NON_CONFORMITY_CATEGORY_ID } from '#pie/env';
import { useRelatedKnowledgeArticles } from '#pie/hooks/useRelatedKnowlegdeArticles';
import { isValidXmlCharacters } from '#pie/utils/isValidXmlCharacters';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const schema = (t: ReturnType<typeof useTranslation>['t']) =>
  z
    .object({
      description: z
        .string()
        .max(2000)
        .optional()
        .refine(value => isValidXmlCharacters(value), {
          message: t('zod.errors.invalid_xml_characters'),
        }),
      measureField: z.string().max(100),
      observationDate: z.string(),
      placeName: z.string().optional(),
    })
    .merge(gridOperatorValidationSchema)
    .merge(assigneeValidationSchema)
    .merge(attachmentValidationSchema(t))
    .merge(rejectionReasonValidationSchema);

type DetailsForm = z.infer<ReturnType<typeof schema>>;

const { FormText, FormDate } = typedFormFields<DetailsForm>();

export const TicketCreateNonConformityDetails = ({ wizardActions, onSubmit, onBack, stepper }: StepProps) => {
  const { t } = useTranslation();
  const { data, setStepShouldBlock } = useNonConformityFormContext();

  const { data: elecData, isLoading: isElecLoading } = useGetConnectionInformation(
    { ean18: data.electricityEan! },
    { query: { enabled: !!data.electricityEan } }
  );

  const defaultValues = {
    ...data,
    regionalGridOperatorId: elecData?.regionalGridOperator?.id || data.regionalGridOperatorId,
  };
  const formMethods = useForm<DetailsForm>({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    if (isDirty) setStepShouldBlock(true);
  }, [isDirty]);

  const { relatedArticles, relatedArticlesLoading } = useRelatedKnowledgeArticles(VITE_NON_CONFORMITY_CATEGORY_ID);

  return (
    <Page>
      <PageHeader
        title={t('ticket_create.non_conformity.page_title')}
        backOnClick={onBack}
        previousText={t('common.steps.previous')}
        details={stepper}
      />
      <FormProvider {...formMethods}>
        <form className="align-start flex flex-col gap-6 pb-8 md:flex-row" onSubmit={handleSubmit(onSubmit)}>
          <Card className="flex-[2] p-6" shadow>
            <Stack gap="lg">
              <EanFailure electricityEan={data.electricityEan} />
              <Stack gap="md">
                <Text as="h2" variant="h5">
                  {t('ticket_create.heading.ticket_about')}
                </Text>
                <TicketGridOperatorsFields disabled={!!defaultValues.regionalGridOperatorId} />
                <FormDate name="observationDate" label={t('common.observation_date')} rules={{ required: true }} />
                <FormText
                  name="measureField"
                  label={t('common.measure_field')}
                  rules={{ required: true }}
                  fullWidth
                  showWordCount={100}
                />
                <FormText name="placeName" label={t('common.place_name')} fullWidth showWordCount={100} />
              </Stack>
              <Stack gap="md">
                <Text as="h2" variant="h5">
                  {t('common.rejection_reason.title')}
                </Text>
                <TicketRejectionReasonFields />
              </Stack>
              <Stack gap="md">
                <Text as="h2" variant="h5">
                  {t('ticket_create.heading.details')}
                </Text>
                <InputField name="title" label={t('common.subject')}>
                  <InputText disabled value={`NC-${data.electricityEan}`} fullWidth />
                </InputField>
                <TicketDescription />
                <TicketAttachmentFields />
              </Stack>
              <Divider />
              <Stack direction="row" className="justify-between" gap="lg">
                {wizardActions}
              </Stack>
            </Stack>
          </Card>
          <Stack as="aside" gap="lg" className="flex-1">
            <TicketAssigneeCard />
            <TicketConnectionCard electricityEan={data.electricityEan} />
            {data?.electricityEan &&
              (isElecLoading ? (
                <Skeleton />
              ) : (
                elecData && <ConnectionInfo ean18={data.electricityEan} connection={elecData} />
              ))}
            <RelatedKnowledgeArticlesCard articles={relatedArticles} isLoading={relatedArticlesLoading} />
          </Stack>
        </form>
      </FormProvider>
    </Page>
  );
};
