import { atom } from 'jotai';
import { z } from 'zod';
import { SearchParam, paramsAtom } from './searchParams';
import type { ColumnSort, SortingState } from '@tanstack/react-table';

export const sortDescSchema = z.boolean().catch(false);
export const sortFieldSchema = z.string().nullable();

export const createSortingAtom = ({ defaultSorting }: { defaultSorting: ColumnSort }) =>
  atom<SortingState, [SortingState], SortingState>(
    get => {
      const searchParams = get(paramsAtom);

      const desc = sortDescSchema.parse(searchParams?.has(SearchParam.desc));
      const id = sortFieldSchema.parse(searchParams?.get(SearchParam.sortField));

      const defaultDesc = sortDescSchema.parse(defaultSorting.desc);
      const defaultId = sortFieldSchema.parse(defaultSorting.id)!;

      return id ? [{ desc, id }] : [{ desc: defaultDesc, id: defaultId }];
    },
    (get, set, sortingState) => {
      const tableSort = sortingState[0];
      set(paramsAtom, searchParams => {
        const params = new URLSearchParams(searchParams);
        const current = get(paramsAtom);

        if (tableSort) {
          const sortDesc = sortDescSchema.parse(tableSort.desc);
          const sortField = sortFieldSchema.parse(tableSort.id)!;

          if (sortField === defaultSorting.id && sortDesc === defaultSorting.desc) {
            params.delete(SearchParam.desc);
            params.delete(SearchParam.sortField);
          } else {
            if (sortDesc) {
              params.set(SearchParam.desc, '');
            } else {
              params.delete(SearchParam.desc);
            }
            params.set(SearchParam.sortField, sortField);
          }
        } else {
          if (current.get(SearchParam.sortField)) {
            params.set(SearchParam.desc, '');
            // if we were already sorting the default sorting then flip it so it won't get stuck
          } else {
            params.set(SearchParam.sortField, defaultSorting.id);
            if (defaultSorting.desc) {
              params.delete(SearchParam.desc);
            } else {
              params.set(SearchParam.desc, '');
            }
          }
        }

        params.delete(SearchParam.pageIndex);

        return params;
      });

      return sortingState;
    }
  );
