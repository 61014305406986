import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useGetSurveyId, usePutSurveyId } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';

interface Props {
  id: string;
}

export const SurveyDialogueReadToggle = ({ id }: Props) => {
  const { t } = useTranslation();
  const { data, isLoading: isDataLoading } = useGetSurveyId(id);
  const { mutateAsync, isLoading, variables } = usePutSurveyId();
  const queryClient = useQueryClient();
  const hasUnreadComments =
    variables?.data.hasUnreadComments !== undefined ? variables.data.hasUnreadComments : data?.hasUnreadComments;

  const markReadUnread = async () => {
    await mutateAsync(
      { data: { hasUnreadComments: !hasUnreadComments }, id },
      {
        onSettled: () =>
          queryClient.invalidateQueries({
            predicate: m => m.queryKey[0] === '/survey',
          }),
      }
    );
  };

  return (
    <Button isLoading={isLoading || isDataLoading} variant="ghostNeutral" onClick={markReadUnread}>
      {hasUnreadComments ? t('common.mark_as_read') : t('common.mark_as_unread')}
    </Button>
  );
};
