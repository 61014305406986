import type { HomePageQuery } from '..';

export const createGQLHome = () =>
  ({
    entry: {
      blocks: [
        {
          __typename: 'blocks_block_BlockType',
          blockContainer: true,
          blockGradient: false,
          blockSpacing: 'default',
          content: [
            {
              __typename: 'blocks_spacing_BlockType',
              blockSpacing: 'default',
            },
            {
              __typename: 'blocks_wysiwyg_BlockType',

              wysiwyg: {
                rawNodes: '[{"type":"heading","attrs":{"level":1},"content":[{"type":"text","text":"Heading1"}]}]',
              },
            },
            {
              __typename: 'blocks_spacing_BlockType',
              blockSpacing: 'small',
            },
            {
              __typename: 'blocks_video_BlockType',
              youtubeId: 'j-IofLo9me0',
            },
          ],
        },
        {
          __typename: 'blocks_block_BlockType',
          blockContainer: true,
          blockGradient: true,
          blockSpacing: 'default',
          content: [
            {
              __typename: 'blocks_spacing_BlockType',
              blockSpacing: 'default',
            },
            {
              __typename: 'blocks_cardImage_BlockType',
              aspectRatio: '3/2',
              button: [],
              description: 'Test',
              heading: 'Test',
              image: [
                {
                  alt: null,
                  url: 'https://picsum.photos/id/22/367/267',
                },
              ],
              reversed: false,
              subtitle: 'Test',
            },
            {
              __typename: 'blocks_spacing_BlockType',
              blockSpacing: 'default',
            },
          ],
        },
        {
          __typename: 'blocks_block_BlockType',
          blockContainer: false,
          blockGradient: false,
          blockSpacing: 'default',
          content: [
            {
              __typename: 'blocks_cta_BlockType',
              button: [
                {
                  __typename: 'button_Entry_LinkType',
                  buttonDisplay: 'inline',
                  buttonType: 'primary',
                  icon: '',
                  iconPosition: '',
                  linkText: 'Lees meer',
                  target: null,
                  text: 'Lees meer',
                  url: 'support',
                },
              ],
              description:
                'Over wie er toegang krijgt tot de centrale systemen in de energiemarkt zijn afspraken gemaakt. Zo zorgen we dat onze systemen veilig blijven. Wil je toegang tot de centrale systemen? Dan moet je je eerst registeren als marktpartij. Afhankelijk van je rol in de energiemarkt kunnen wij dan de juiste datadiensten leveren.',
              heading: 'Wil je gebruik maken van het platform?',
            },
            {
              __typename: 'blocks_spacing_BlockType',
              blockSpacing: 'default',
            },
          ],
        },
        {
          __typename: 'blocks_block_BlockType',
          blockContainer: true,
          blockGradient: false,
          blockSpacing: 'default',
          content: [
            {
              __typename: 'blocks_cardImage_BlockType',
              aspectRatio: '3/2',
              button: [
                {
                  __typename: 'button_Entry_LinkType',
                  buttonDisplay: 'inline',
                  buttonType: 'anchor',
                  icon: 'mdiChevronRight',
                  iconPosition: 'right',
                  linkText: 'Alles over congestiemanagement',
                  target: null,
                  text: 'Alles over congestiemanagement',
                  url: 'support',
                },
              ],
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum faucibus pharetra neque vitae ornare. Donec euismod risus ut dui suscipit tristique. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vestibulum a laoreet lacus. Duis in elit vitae dui scelerisque porta ut a nibh.',
              heading: 'Congestiemanagement',
              image: [
                {
                  alt: null,
                  url: 'https://picsum.photos/id/22/367/267',
                },
              ],
              reversed: true,
              subtitle: "Thema's",
            },
            {
              __typename: 'blocks_spacing_BlockType',
              blockSpacing: 'default',
            },
            {
              __typename: 'blocks_cardImage_BlockType',
              aspectRatio: '3/2',
              button: [
                {
                  __typename: 'button_Entry_LinkType',
                  buttonDisplay: 'inline',
                  buttonType: 'anchor',
                  icon: 'mdiChevronRight',
                  iconPosition: 'right',
                  linkText: 'Registreren als marktpartij',
                  target: null,
                  text: 'Registreren als marktpartij',
                  url: 'support',
                },
              ],
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum faucibus pharetra neque vitae ornare. Donec euismod risus ut dui suscipit tristique. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vestibulum a laoreet lacus. Duis in elit vitae dui scelerisque porta ut a nibh.',
              heading: 'Registreren als marktpartij',
              image: [
                {
                  alt: null,
                  url: 'https://picsum.photos/id/22/367/267',
                },
              ],
              reversed: false,
              subtitle: null,
            },
            {
              __typename: 'blocks_spacing_BlockType',
              blockSpacing: 'default',
            },
          ],
        },
      ],
      button: [
        {
          __typename: 'button_Entry_LinkType',
          buttonDisplay: 'inline',
          buttonType: 'primary',
          icon: '',
          iconPosition: '',
          linkText: null,
          target: null,
          text: 'Over Partners in Energie',
          url: 'over-ons',
        },
      ],
      excerpt:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum faucibus pharetra neque vitae ornare',
      image: [
        {
          title: 'Rectangle',
          url: 'https://picsum.photos/id/22/367/267',
        },
      ],
      title: 'Wij bouwen samen aan een stralende toekomst',
    },
  }) satisfies HomePageQuery;
