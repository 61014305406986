import type { TeamDto, TeamDtoListResultDto } from '../generated/model';

export const createTeams = (overrides: Partial<TeamDtoListResultDto> = {}): TeamDtoListResultDto => ({
  items: [
    createTeam(),
    createTeam({
      id: 'b8808194-2ace-4d34-a548-e17b96d5e9e8',
      name: 'Team 2',
    }),
  ],
  totalCount: 2,
  ...overrides,
});

export const createTeam = (overrides: Partial<TeamDto> = {}): TeamDto => ({
  id: '086dc17c-2900-4df7-be25-2045ea78bf85',
  name: 'Pikablue',
  numberOfContacts: 2,
  ...overrides,
});
