import { atom } from 'jotai';
import { paginationAtom } from './pagination';
import { SearchParam, paramsAtom } from './searchParams';
import type { z } from 'zod';

export const encodeFilters = <T extends object>(filters: T) => {
  const filterList = Object.entries(filters).filter(([, values]) => values.length);
  return btoa(JSON.stringify(Object.fromEntries(filterList)));
};

export const decodeFilters = <T extends object>(str?: string | null): T => (str ? JSON.parse(atob(str)) : {});

export const createFiltersAtom = <T extends object>({
  defaultFilters,
  filtersSchema,
}: {
  filtersSchema: z.ZodType<T, z.ZodTypeDef, unknown>;
  defaultFilters: T;
}) =>
  atom<T, [T], T>(
    get => {
      const searchParams = get(paramsAtom);

      return filtersSchema.parse(decodeFilters<T>(searchParams?.get(SearchParam.filters)));
    },
    (get, set, filtersState) => {
      set(paramsAtom, searchParams => {
        const params = new URLSearchParams(searchParams);
        const encodedFilters = encodeFilters<T>(filtersSchema.parse(filtersState));

        if (encodedFilters && encodedFilters !== encodeFilters(defaultFilters)) {
          params.set(SearchParam.filters, encodedFilters);
        } else {
          params.delete(SearchParam.filters);
        }

        return params;
      });

      // reset pagination state to default when filters change
      const currentPagination = get(paginationAtom);
      if (currentPagination.pageIndex !== 0) {
        set(paginationAtom, {
          pageIndex: 0,
          pageSize: currentPagination.pageSize,
        });
      }

      return filtersState;
    }
  );
