import type { ComponentProps } from 'react';
import { cn } from '#pie/utils/TailwindUtils';

export const Skeleton: React.FC<ComponentProps<'span'>> = ({ className, ...props }) => (
  <span
    role="progressbar"
    aria-live="polite"
    aria-busy="true"
    className={cn(
      'mt-[.25em] inline-flex h-[1em] w-full animate-pulse select-none rounded-xl bg-neutral-200 leading-none',
      className
    )}
    {...props}
  >
    <br />
  </span>
);
