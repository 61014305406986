import { z } from 'zod';
import { SurveyDialogView } from '#edsn/api/pie-bff';
import type { SurveyDialogueDto, SurveyDialogueFilter } from '#edsn/api/pie-bff';
import { createFiltersAtom } from './createFiltersAtom';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';
import type { ZodType } from 'zod';
import { getValues } from '#pie/utils/ZodUtils';

export const defaultCesFilters = {
  surveyDialogView: SurveyDialogView.Unread,
};
export const cesFiltersSchema: ZodType<SurveyDialogueFilter, z.ZodTypeDef, unknown> = z
  .object({
    surveyDialogView: z.enum(getValues(SurveyDialogView)),
  })
  .catch(defaultCesFilters);

export const cesFiltersAtom = createFiltersAtom<SurveyDialogueFilter>({
  defaultFilters: defaultCesFilters,
  filtersSchema: cesFiltersSchema,
});

export const defaultCesSorting: DefaultSorting<SurveyDialogueDto> = {
  desc: true,
  id: 'createdOn',
};

export const cesSortingAtom = createSortingAtom({ defaultSorting: defaultCesSorting });
