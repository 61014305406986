import IconCalendarMonth from '@iconify/icons-material-symbols/calendar-month';
import { format } from 'date-fns';
import { type ComponentProps, useMemo } from 'react';
import HtmlWrapper from 'react-html-map';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetKnowledgeArticleId, useGetKnowledgeArticleImageId } from '#edsn/api/pie-bff';
import { ErrorPage } from '../error-page/ErrorPage';
import { AttachmentAnchor } from '#pie/components/attachment-anchor/AttachmentAnchor';
import { Divider } from '#pie/components/divider/Divider';
import { Icon } from '#pie/components/icon/Icon';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { RelatedKnowledgeArticlesCard } from '#pie/components/related-knowledge-articles-card/RelatedKnowledgeArticlesCard';
import { Skeleton } from '#pie/components/skeleton/Skeleton';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { useRelatedKnowledgeArticles } from '#pie/hooks/useRelatedKnowlegdeArticles';
import { useStoredParams } from '#pie/stores/searchParams';
import { parseDates } from '#pie/utils/date';
import { extractKnowledgeBaseImageId } from '#pie/utils/extractKnowledgeBaseImageId';

export const KnowledgeBaseDetail = () => {
  const id = useParams()['id']!;
  const { t } = useTranslation();
  const { data: rawData, isLoading } = useGetKnowledgeArticleId(id!);
  const data = parseDates(rawData, ['createdOn', 'modifiedOn']);

  const params = useStoredParams('/kennisbank');

  const { relatedArticles, relatedArticlesLoading } = useRelatedKnowledgeArticles(
    data?.mainCategory?.id,
    data?.subCategory?.id
  );

  const filteredRelatedArticles = useMemo(() => relatedArticles?.filter(m => m.id !== id), [relatedArticles, id]);

  if (!data && !isLoading) {
    return (
      <ErrorPage
        heading={t('knowledge_base_detail.error_page.not_found.title')}
        message={t('knowledge_base_detail.error_page.not_found.message')}
      />
    );
  }

  return (
    <>
      <PageHead title={data?.title} />
      <Page>
        <PageHeader title={t('knowledge_base_detail.header.title')} backHref={`/kennisbank${params || ''}`} />
        <div className="px-8 py-4">
          <article className="container">
            <Text className="text-primary text-xs uppercase tracking-[0.5em]">
              {isLoading ? <Skeleton className="w-[4rem]" /> : data?.mainCategory?.label}
            </Text>
            <Text variant="h1">{isLoading ? <Skeleton className="w-[4rem]" /> : data?.title}</Text>
            <Divider className="my-4" />
            <div className="flex flex-row items-center gap-2">
              <Icon icon={IconCalendarMonth} size={16} />
              <time className="font-light text-neutral-500">
                <Text variant="xs">
                  {data?.createdOn && format(data.createdOn, 'dd MMM y')} (laatste update:{' '}
                  {data?.modifiedOn && format(data.modifiedOn, 'dd MMM y')})
                </Text>
              </time>
            </div>
            <div className="mt-4 grid grid-cols-12">
              <div className="col-span-8">
                <Stack gap="lg">
                  {data?.content && (
                    <div data-testid="content" className="whitespace-pre-line">
                      <HtmlWrapper
                        decodeEntities
                        html={data.content.replace(/>\s+|\s+</g, function (m) {
                          return m.trim();
                        })}
                      >
                        {{
                          a: ({ children, href, id, target }) => (
                            <a
                              id={id}
                              rel="noopener noreferrer"
                              href={href}
                              target={target}
                              className="text-primary link"
                            >
                              {children}
                            </a>
                          ),
                          b: ({ children }) => <strong>{children}</strong>,
                          blockquote: ({ children }) => (
                            <blockquote className="mb-6 bg-neutral-200 p-4 italic">{children}</blockquote>
                          ),
                          br: () => <br />,
                          div: ({ children }) => <>{children}</>,
                          em: ({ children }) => <em>{children}</em>,
                          h1: ({ children }) => (
                            <Text variant="h3" className="mb-2 mt-7">
                              {children}
                            </Text>
                          ),
                          h2: ({ children }) => (
                            <Text variant="h4" className="mb-2 mt-7">
                              {children}
                            </Text>
                          ),
                          h3: ({ children }) => (
                            <Text variant="h5" className="mb-2 mt-7">
                              {children}
                            </Text>
                          ),
                          h4: ({ children }) => (
                            <Text variant="h6" className="mb-2 mt-7">
                              {children}
                            </Text>
                          ),
                          h5: ({ children }) => (
                            <Text variant="h6" className="mb-2 mt-7">
                              {children}
                            </Text>
                          ),
                          h6: ({ children }) => (
                            <Text variant="h6" className="mb-2 mt-7">
                              {children}
                            </Text>
                          ),
                          i: ({ children }) => <em>{children}</em>,
                          img: ({ src, alt }) => <KnowledgeArticleImage src={src} alt={alt} className="my-6" />,
                          li: ({ children }) => <li className="whitespace-pre-line">{children}</li>,
                          ol: ({ children }) => (
                            <ol className="mb-6 ml-8 list-decimal space-y-2 whitespace-normal">{children}</ol>
                          ),
                          p: ({ children }) => <p className="mb-6">{children}</p>,
                          section: ({ children }) => <>{children}</>,
                          span: ({ children }) => <>{children}</>,
                          strong: ({ children }) => <strong>{children}</strong>,
                          table: ({ children }) => <table className="bg-white">{children}</table>,
                          tbody: ({ children }) => <tbody className="border border-neutral-300">{children}</tbody>,
                          td: ({ children }) => <td className="border border-neutral-300 px-2 py-1">{children}</td>,
                          thead: ({ children }) => <thead>{children}</thead>,
                          tr: ({ children }) => <tr>{children}</tr>,
                          u: ({ children }) => <u>{children}</u>,
                          ul: ({ children }) => (
                            <ul className="mb-6 ml-8 list-disc space-y-2 whitespace-normal">{children}</ul>
                          ),
                        }}
                      </HtmlWrapper>
                    </div>
                  )}
                  {data?.attachments && data.attachments.length > 0 && (
                    <div className="flex flex-col gap-3">
                      <Text variant="h4">Bijlagen</Text>
                      {data.attachments.map((attachment, idx) => (
                        <AttachmentAnchor attachment={attachment} key={idx} />
                      ))}
                    </div>
                  )}
                </Stack>
              </div>
              <div className="col-span-3 col-start-10 flex flex-col gap-6">
                <RelatedKnowledgeArticlesCard articles={filteredRelatedArticles} isLoading={relatedArticlesLoading} />
              </div>
            </div>
          </article>
        </div>
      </Page>
    </>
  );
};

/* c8 ignore next 16 */
const KnowledgeArticleImage: React.FC<ComponentProps<'img'>> = ({ src, alt, ...props }) => {
  const id = extractKnowledgeBaseImageId(src);
  const { data, isLoading } = useGetKnowledgeArticleImageId(id!, {
    query: {
      enabled: !!id,
    },
  });

  if (!id) return <img src={src} alt={alt} {...props} />;

  const imageSrc = data && (window.URL || window.webkitURL).createObjectURL(data);

  if (isLoading || !imageSrc) return;

  return <img src={imageSrc} alt={alt} {...props} />;
};
