import { Avatar } from '../avatar/Avatar';
import type { ComponentProps } from 'react';
import { useCurrentOrganisation } from '#pie/auth';
import { useUserInfo } from '#pie/hooks/useUserInfo';

export const UserAvatar: React.FC<ComponentProps<typeof Avatar>> = props => {
  const { currentAccount } = useCurrentOrganisation();
  const [{ data: userInfo, isLoading }] = useUserInfo();

  const initials = userInfo
    ? [userInfo.given_name, userInfo.family_name].map(name => name.charAt(0).toUpperCase()).join('')
    : '';

  return (
    <Avatar
      isLoading={isLoading}
      initials={initials}
      size={32}
      name={currentAccount?.marketRole}
      company={currentAccount?.name}
      {...props}
    />
  );
};
