import type { PropsWithChildren } from 'react';

interface OverflowTextProps extends PropsWithChildren {
  maxWidth?: number;
  width?: number;
}

export const OverflowText = ({ children, maxWidth, width }: OverflowTextProps) => (
  <div
    className="group relative overflow-hidden text-ellipsis hover:z-10 hover:overflow-visible"
    style={{ maxWidth: `${maxWidth}px`, width: `${width}px` }}
  >
    <span className="-mx-3 px-3 group-hover:bg-white group-hover:shadow-[0_0_8px_8px_rgba(255,255,255,1)]">
      {children}
    </span>
  </div>
);
