type FormDataValue = Blob | string | number | boolean | null | undefined | Record<string, unknown> | FormDataValue[];

const appendValueToFormData = (formData: FormData, key: string, value: FormDataValue) => {
  if (Array.isArray(value)) {
    appendArrayToFormData(formData, key, value);
  } else if (value instanceof Blob) {
    formData.append(key, value);
  } else if (typeof value === 'object') {
    appendObjectToFormData(formData, key, value as Record<string, unknown>);
  } else if (value === undefined) {
    // do nothing
  } else {
    formData.append(key, value.toString());
  }
};

const appendArrayToFormData = (formData: FormData, key: string, array: FormDataValue[]) => {
  array.forEach((value, index) =>
    appendValueToFormData(formData, value instanceof Blob ? key : `${key}[${index}]`, value)
  );
};

const appendObjectToFormData = (formData: FormData, key: string, object: object) => {
  Object.entries(object).forEach(([subKey, value]) => {
    appendValueToFormData(formData, [key, subKey].filter(k => k && k.length > 0).join('.'), value as FormDataValue);
  });
};

const useFormDataMutator = (postTicketBody: object) => {
  const formData = new FormData();
  appendObjectToFormData(formData, '', postTicketBody);
  return formData;
};

export default useFormDataMutator;
