import { TokenRenewMode } from '@axa-fr/oidc-client';
import { VITE_IDP_URI, VITE_MOCK_IDP } from './env';
import type { OidcConfiguration } from '@axa-fr/oidc-client';

export const oidcConfig: OidcConfiguration = {
  /* c8 ignore next */
  authority: VITE_MOCK_IDP ? 'http://localhost:4220' : VITE_IDP_URI,
  client_id: 'pie',
  redirect_uri: `${document.location.origin}/oauth/handler`,
  scope: 'openid profile roles email token_v2',
  service_worker_only: true,
  service_worker_relative_url: VITE_MOCK_IDP ? undefined : '/OidcServiceWorker.js',
  silent_redirect_uri: `${window.location.origin}/oauth/silent-callback`,
  token_renew_mode: TokenRenewMode.access_token_invalid,
};
