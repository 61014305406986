import boltIcon from '@iconify/icons-material-symbols/bolt-outline';
import modeHeatIcon from '@iconify/icons-material-symbols/mode-heat-outline';
import { Product } from '#edsn/api/pie-bff';

// TODO: get icons for None
export const productIcons = {
  [Product.Both]: undefined,
  [Product.Electricity]: boltIcon,
  [Product.Gas]: modeHeatIcon,
  [Product.None]: undefined,
} as const;
