import { z } from 'zod';
import type { CprContactDto, CprContactFilter } from '#edsn/api/pie-bff';
import { createFiltersAtom } from './createFiltersAtom';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';
import type { ZodType } from 'zod';

export const defaultCprOrganisationDetailFilters = {
  contactGroupIds: [],
  organisationIds: [],
} satisfies z.infer<typeof cprOrganisationDetailFiltersSchema>;

export const cprOrganisationDetailFiltersSchema: ZodType<CprContactFilter, z.ZodTypeDef, unknown> = z
  .object({
    contactGroupIds: z.array(z.string()).optional(),
  })
  .catch(defaultCprOrganisationDetailFilters);

export const cprOrganisationDetailFiltersAtom = createFiltersAtom<CprContactFilter>({
  defaultFilters: defaultCprOrganisationDetailFilters,
  filtersSchema: cprOrganisationDetailFiltersSchema,
});

export const defaultCprOrganisationDetailSorting: DefaultSorting<CprContactDto> = {
  desc: false,
  id: 'contactGroup.label',
};

export const cprOrganisationDetailSortingAtom = createSortingAtom({
  defaultSorting: defaultCprOrganisationDetailSorting,
});
