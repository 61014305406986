import { forwardRef } from 'react';
import { styles } from '../input-text/InputText';
import { Text } from '../text/Text';
import type { InputHTMLAttributes } from 'react';
import { type VariantProps, cn, tv } from '#pie/utils/TailwindUtils';

export interface InputTextAreaProps
  extends Omit<VariantProps<typeof styles>, 'hasIcon' | 'isLoading'>,
    Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'disabled' | 'onBlur'> {
  rows?: number;
  showWordCount?: boolean;
}

export const InputTextArea = forwardRef<HTMLTextAreaElement, InputTextAreaProps>(function InputTextArea(
  { className, disabled, isError, fullWidth, showWordCount = false, ...props },
  ref
) {
  const s = inputTextArea({ disabled, fullWidth, isError });

  return (
    <div className={cn(s.base(), className)}>
      {showWordCount && props.maxLength && (
        <Text className={s.wordCount()}>
          {props.value?.toString().length || 0}/{props.maxLength}
        </Text>
      )}
      <textarea
        data-testid={`textbox-${props.name}`}
        ref={ref}
        className={s.input()}
        disabled={disabled}
        aria-labelledby={props.name}
        {...props}
      />
    </div>
  );
});

const inputTextArea = tv({
  base: 'relative',
  extend: styles,
  slots: {
    input: 'p-3',
    wordCount: 'text-right absolute right-0 bottom-full mb-2 text-neutral-500',
  },
});
