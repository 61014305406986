/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type TicketStatus = (typeof TicketStatus)[keyof typeof TicketStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TicketStatus = {
  Ingediend: 'Ingediend',
  InBehandeling: 'InBehandeling',
  ReactieVereist: 'ReactieVereist',
  UpdateBeschikbaar: 'UpdateBeschikbaar',
  Afgehandeld: 'Afgehandeld',
  Afgesloten: 'Afgesloten',
  Geannuleerd: 'Geannuleerd',
} as const;
