import { MarketRole } from '../generated/model';
import { generateEan } from './connection';
import type {
  AccountDto,
  CprContactDto,
  CprContactDtoListResultDto,
  CprContactGroupDto,
  CprFiltersDto,
  CprManagementContactDto,
  CprManagementContactDtoListResultDto,
  SelectDto,
} from '../generated/model';

export const createCprContacts = (overrides: Partial<CprContactDtoListResultDto> = {}): CprContactDtoListResultDto => ({
  items: [
    createCprContact({
      email: 'pietje.puk@eneco.nl',
      name: 'Pietje Puk',
      organisation: createOrganisation({ name: 'Eneco' }),
    }),
    createCprContact({
      email: 'jan.klein@edsn.nl',
      id: '321',
      name: 'Jan Klein',
      organisation: createOrganisation({ name: 'EDSN' }),
    }),
    ...Array(7).fill(createCprContact()),
  ],
  totalCount: 20,
  ...overrides,
});

export const createCprContact = (overrides: Partial<CprContactDto> = {}): CprContactDto => ({
  contactGroup: createContactGroupSelect(),
  contactId: '123',
  email: 'john.doe@liander.nl',
  id: '123',
  marketParty: createOrganisation(),
  name: 'John Doe',
  organisation: createOrganisation({ name: 'Liander' }),
  phone: '06-12345678',
  ...overrides,
});

export const createContactGroupSelect = (overrides: Partial<SelectDto> = {}): SelectDto => ({
  id: 'c6f7e4a2-7dc6-ea11-a816-000d3a21145f',
  label: 'Algemene groep',
  ...overrides,
});

export const createOrganisation = (overrides: Partial<AccountDto> = {}): AccountDto => ({
  ean13: generateEan(13, 'marketParty'),
  id: 'c6f7e4a2-7dc6-ea11-a816-000d3a21145f',
  marketRole: MarketRole.LV,
  name: 'Test organisatie',
  ...overrides,
});

export const createCprFilters = (overrides: Partial<CprFiltersDto> = {}): CprFiltersDto => ({
  contactGroups: [createContactGroupSelect()],
  marketParties: [{ id: createOrganisation().id, label: createOrganisation().name }],
  organisations: [{ id: createOrganisation().id, label: 'Liander' }],
  ...overrides,
});

export const createContactGroup = (overrides: Partial<CprContactGroupDto> = {}): CprContactGroupDto => ({
  id: 'c6f7e4a2-7dc6-ea11-a816-000d3a21145f',
  isRequired: true,
  marketRoles: [],
  name: 'Algemene groep',
  ...overrides,
});

export const createCprManagementContact = (
  overrides: Partial<CprManagementContactDto> = {}
): CprManagementContactDto => ({
  contactGroup: createContactGroup(),
  contactId: '123',
  email: 'john.doe@liander.nl',
  id: '123',
  name: 'John Doe',
  organisation: createOrganisation(),
  phone: '06-12345678',
  ...overrides,
});

export const createCprManagementContacts = (
  overrides: Partial<CprManagementContactDtoListResultDto> = {}
): CprManagementContactDtoListResultDto => ({
  items: [
    ...Array(5).fill(createCprManagementContact()),
    ...Array(5).fill(createCprManagementContact({ contactGroup: createContactGroup({ isRequired: false }) })),
  ],
  totalCount: 20,
  ...overrides,
});
