import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { MarketRole } from '#edsn/api/pie-bff';
import { getCprContact, getCprContactFilters, useGetCprContactFilters } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { Card } from '#pie/components/card/Card';
import { CprContactGroupDetailExport } from '#pie/components/cpr-export/CprContactGroupDetailExport';
import { DataFilters } from '#pie/components/data-filters/DataFilters';
import { DataTable } from '#pie/components/data-table/DataTable';
import { DataToolbar } from '#pie/components/data-toolbar/DataToolbar';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { useDataView } from '#pie/hooks/useDataView';
import { useMarketRoleOptions } from '#pie/hooks/useMarketRoleOptions';
import { cprContactGroupDetailFiltersAtom, cprContactGroupDetailSortingAtom } from '#pie/stores/cprContactGroupDetail';
import { mapToFilterOptions } from '#pie/utils/mapToFilterOptions';

export const ContactGroupDetail = () => {
  const { t } = useTranslation();
  const contactGroupId = useParams()['contactGroupId']!;
  const marketRoles = useMarketRoleOptions();
  const { data } = useGetCprContactFilters();
  const contactGroupTitle = data?.contactGroups.find(n => n.id === contactGroupId)?.label;

  const { tableProps, filterProps, toolbarProps } = useDataView({
    columns: helper => [
      helper.accessor('marketParty.name', {
        header: t('common.organisation'),
        meta: { columnWidth: 250 },
      }),
      helper.accessor('organisation.name', {
        header: t('cpr_overview.table.header.market_party'),
      }),
      helper.accessor('marketParty.ean13', {
        header: t('common.ean13'),
      }),
      helper.accessor('marketParty.marketRole', {
        cell: info => t(`common.market_role.abbreviation.${info.getValue() as MarketRole}`),
        header: t('common.market_role'),
      }),
      helper.accessor('name', {
        header: t('common.name'),
      }),
      helper.accessor('email', {
        cell: info => (
          <Button as="a" variant="linkPrimary" href={`mailto:${info.getValue()}`}>
            {info.getValue()}
          </Button>
        ),
        header: t('common.email'),
      }),
      helper.accessor('phone', {
        cell: info => (
          <Button as="a" variant="linkPrimary" href={`tel:${info.getValue()}`}>
            {info.getValue()}
          </Button>
        ),
        header: t('common.phone'),
      }),
    ],
    dateKeys: [],
    filters: [
      {
        id: 'marketPartyIds',
        label: t('common.filter.marketPartyIds'),
        options: data => mapToFilterOptions(data?.marketParties),
      },
      {
        id: 'organisationIds',
        label: t('common.filter.organisationIds'),
        options: data => mapToFilterOptions(data?.organisations),
      },
      {
        id: 'marketRoles',
        label: t('common.filter.marketRoles'),
        options: marketRoles,
      },
    ],
    filtersAtom: cprContactGroupDetailFiltersAtom,
    filtersQuery: getCprContactFilters,
    hardFilters: { contactGroupIds: [contactGroupId] },
    query: getCprContact,
    sortingAtom: cprContactGroupDetailSortingAtom,
  });

  return (
    <>
      <PageHead
        title={t('cpr_contact_group_detail.header.title', {
          contactGroup: contactGroupTitle,
        })}
      />
      <Page>
        <PageHeader
          title={t('cpr_contact_group_detail.header.title', {
            contactGroup: contactGroupTitle,
          })}
          className="mb-8"
          backHref={`../contactgroep`}
          previousText={t('common.button.back')}
        >
          <CprContactGroupDetailExport />
        </PageHeader>
        <Text variant="h4" as="h2" className="mb-3"></Text>
        <div className="flex items-start gap-6">
          <Card className="flex-1 overflow-x-hidden p-4">
            <Stack gap="md">
              <DataToolbar {...toolbarProps} />
              <DataTable {...tableProps} idKey="id" />
            </Stack>
          </Card>
          <DataFilters {...filterProps} />
        </div>
      </Page>
    </>
  );
};
