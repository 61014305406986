import addIcon from '@iconify/icons-material-symbols/add';
import crossIcon from '@iconify/icons-material-symbols/close';
import { Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useGetRejectionCategory } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { Divider } from '#pie/components/divider/Divider';
import { IconButton } from '#pie/components/icon-button/IconButton';
import { Stack } from '#pie/components/stack/Stack';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const rejectionReasonValidationSchema = z.object({
  rejections: z
    .array(
      z.object({
        rejectionCategoryId: z.string(),
        rejectionReasonId: z.string().optional(),
      })
    )
    .min(1)
    .optional(),
});

type RejectionReasonForm = z.infer<typeof rejectionReasonValidationSchema>;

const { FormDropdown } = typedFormFields<RejectionReasonForm>();

export const TicketRejectionReasonFields = () => {
  const { t } = useTranslation();
  const { control, watch, resetField } = useFormContext<RejectionReasonForm>();
  const { data, isLoading } = useGetRejectionCategory();

  const { remove, append, fields } = useFieldArray<RejectionReasonForm>({
    control,
    name: 'rejections',
  });

  return (
    <section>
      <Stack gap="sm">
        {fields.map(({ id }, idx) => {
          const currentCategory = watch(`rejections.${idx}.rejectionCategoryId`);
          const categoryReasons = data?.items.find(category => category.id === currentCategory)?.reasons || [];
          return (
            <Fragment key={id}>
              {idx !== 0 && <Divider className="my-3" />}
              <Stack direction="row" gap="sm">
                <div className="grid grow grid-cols-2 gap-4">
                  <FormDropdown
                    name={`rejections.${idx}.rejectionCategoryId`}
                    label={t('common.rejection_reason.category')}
                    fullWidth
                    options={data?.items.map(item => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    onChange={() => {
                      resetField(`rejections.${idx}.rejectionReasonId`);
                    }}
                    isLoading={isLoading}
                    className="basis-1/2"
                    // TODO: translation here shouldn't be necessary, remove
                    rules={{ required: t('zod.errors.required') }}
                  />
                  <FormDropdown
                    name={`rejections.${idx}.rejectionReasonId`}
                    label={t('common.rejection_reason.reason')}
                    fullWidth
                    options={categoryReasons.map(reason => ({ label: reason.name, value: reason.id }))}
                    disabled={!watch(`rejections.${idx}.rejectionCategoryId`)}
                    isLoading={isLoading}
                    className="basis-1/2"
                  />
                </div>
                {fields.length > 1 && (
                  <IconButton
                    icon={crossIcon}
                    size={24}
                    variant="primary"
                    className="text-error mb-2 self-end"
                    onClick={() => remove(idx)}
                    aria-label={t('ticket_create.rejection_reason.remove')}
                  />
                )}
              </Stack>
            </Fragment>
          );
        })}
        <Button
          iconStart={addIcon}
          variant="linkSecondary"
          className="self-start"
          onClick={() => append({ rejectionCategoryId: '', rejectionReasonId: '' })}
        >
          Voeg reden toe
        </Button>
      </Stack>
    </section>
  );
};
