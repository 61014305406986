import type { PropsWithChildren } from 'react';
import { cn } from '#pie/utils/TailwindUtils';

export interface DescriptionListProps extends PropsWithChildren {
  className?: string;
}

export function DescriptionList({ className, children }: DescriptionListProps) {
  return (
    <dl
      className={cn(
        'text-primary-dark grid grid-cols-1 gap-x-8 gap-y-6 xl:grid-cols-[min-content,_minmax(9rem,_1fr)]',
        className
      )}
    >
      {children}
    </dl>
  );
}

export { DescriptionListItem } from './description-list-item/DescriptionListItem';
