import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { SurveyDialogView } from '#edsn/api/pie-bff';
import { InputDropdown } from '../input-dropdown/InputDropdown';
import { Stack } from '../stack/Stack';
import type { ComponentProps } from 'react';
import { cesFiltersAtom } from '#pie/stores/ces';
import { isDefined } from '#pie/utils/isDefined';

const isViewKey = (key: string): key is keyof typeof SurveyDialogView => {
  return Object.keys(SurveyDialogView).includes(key);
};

export const SurveyDialogueToolbar = () => {
  const { t } = useTranslation();
  const [activeFilters, setActiveFilters] = useAtom(cesFiltersAtom);
  const selectedFilterView = activeFilters.surveyDialogView;
  const viewOptions: ComponentProps<typeof InputDropdown>['options'] = Object.keys(SurveyDialogView)
    .map(
      key =>
        isViewKey(key) && {
          label: t(`survey_dialogue_overview.toolbar.view.${key}`),
          value: key,
        }
    )
    .filter(isDefined);

  return (
    <header className="px-3">
      <Stack direction="row" gap="md" className="items-center">
        <div className="w-[312px]">
          <InputDropdown
            name="ces_view"
            fullWidth
            onChange={value => {
              gtag('event', 'filter_view_changed', {
                survey_dialog_view: value,
              });
              setActiveFilters({ ...activeFilters, surveyDialogView: value as SurveyDialogView });
            }}
            options={viewOptions}
            value={selectedFilterView}
            required
          />
        </div>
      </Stack>
    </header>
  );
};
