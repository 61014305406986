import { useTranslation } from 'react-i18next';
import { TicketStatus } from '#edsn/api/pie-bff';
import { StatusTag } from '../status-tag/StatusTag';
import type { ComponentProps } from 'react';

export interface StatusTagProps {
  className?: string;
  status: TicketStatus;
}

const twStatusMap: Record<TicketStatus, ComponentProps<typeof StatusTag>['status']> = {
  [TicketStatus.Afgehandeld]: 'completed',
  [TicketStatus.Afgesloten]: 'closed',
  [TicketStatus.Geannuleerd]: 'closed',
  [TicketStatus.InBehandeling]: 'progress',
  [TicketStatus.Ingediend]: 'new',
  [TicketStatus.ReactieVereist]: 'attention',
  [TicketStatus.UpdateBeschikbaar]: 'update',
};

export function TicketStatusTag({ className, status }: StatusTagProps) {
  const { t } = useTranslation();
  return (
    <StatusTag status={twStatusMap[status]} className={className}>
      {t(`common.ticket_statuses.${status}`)}
    </StatusTag>
  );
}
