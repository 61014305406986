import IconWest from '@iconify/icons-material-symbols/west-sharp';
import { Link } from 'react-router-dom';
import type { PropsWithChildren, ReactNode } from 'react';
import { Icon } from '#pie/components/icon/Icon';
import { Skeleton } from '#pie/components/skeleton/Skeleton';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { ConditionalWrapper } from '#pie/utils/ConditionalWrapper';
import { cn } from '#pie/utils/TailwindUtils';

export interface PageHeaderProps extends PropsWithChildren {
  title?: ReactNode;
  backHref?: string;
  backOnClick?: () => void;
  className?: string;
  details?: ReactNode;
  previousText?: string;
  isLoading?: boolean;
}

export function PageHeader({
  children,
  title,
  className,
  backHref,
  backOnClick,
  details,
  previousText = 'Vorige pagina',
  isLoading,
}: PageHeaderProps) {
  return (
    <div
      className={cn(
        'sticky top-0 z-20 -mx-6 mb-6 min-h-[5.5rem] content-center border-b border-b-neutral-200 bg-white px-6 py-4',
        className
      )}
    >
      <div className="flex items-center justify-between gap-8">
        <Stack gap="sm">
          {(backHref || backOnClick) && (
            <ConditionalWrapper
              condition={!!backHref}
              wrapper={children => (
                <Link to={backHref!} onClick={backOnClick} className="text-primary link mb-0.5 flex items-center gap-2">
                  {children}
                </Link>
              )}
              elseWrapper={children => (
                <button
                  onClick={backOnClick}
                  className="text-primary link mb-0.5 flex appearance-none items-center gap-2"
                >
                  {children}
                </button>
              )}
            >
              <Icon icon={IconWest} size={16} />
              {previousText}
            </ConditionalWrapper>
          )}
          <Text variant="h3" as="h1" className="text-primary-dark flex-1">
            {isLoading ? <Skeleton className="w-[50%]" /> : title}
          </Text>
        </Stack>
        <div className="flex-shrink-0" id={'pageheader-button'}>
          {children}
        </div>
      </div>
      {details}
    </div>
  );
}
