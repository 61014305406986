import { useTranslation } from 'react-i18next';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { ErrorPage } from '../error-page/ErrorPage';
import { Header } from '#pie/components/layout/header/Header';
import { Sidebar } from '#pie/components/layout/sidebar/Sidebar';
import { useSidebar } from '#pie/hooks/useSidebar';
import { cn } from '#pie/utils/TailwindUtils';

interface Props {
  noLayout?: boolean;
}

const isResponse = (cause: unknown): cause is Response => cause instanceof Response;

const useErrorMessage = (error: unknown) => {
  const { t } = useTranslation();
  const status = isRouteErrorResponse(error) || isResponse(error) ? error.status : undefined;

  switch (status) {
    case 401:
    case 403:
      return {
        heading: t('error_page.no_permission.heading'),
        message: t('error_page.no_permission.message'),
      };
    case 404:
      return {
        heading: t('error_page.not_found.heading'),
        message: t('error_page.not_found.message'),
      };
  }
  return { heading: t('error_page.catch_all.heading'), message: t('error_page.catch_all.message') };
};

/**
 * Component to render an error page. There are some supported scenarios
 * - ErrorResponse thrown in a React Router loader. It contains unwrapped data
 * - Other errors are caught by the ErrorBoundary are being delt with here.
 */
export function ErrorBoundary({ noLayout = false }: Props) {
  const { isOpen, setIsOpen } = useSidebar();
  const error = useRouteError();

  const { heading, message } = useErrorMessage(error);

  // eslint-disable-next-line no-console
  console.error('error boundary error:', error);

  return noLayout ? (
    <ErrorPage heading={heading} message={message} />
  ) : (
    <div className="flex-root">
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} className="fixed z-50" />
      {/* c8 ignore next */}
      <div className={cn('ml-[64px] flex flex-col pb-[104px]', isOpen && `2xl:ml-[240px]`)}>
        <div className="flex-1">
          <Header />
          <ErrorPage heading={heading} message={message} />
        </div>
      </div>
    </div>
  );
}
