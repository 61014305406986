import attachmentIcon from '@iconify/icons-material-symbols/attachment';
import closeIcon from '@iconify/icons-material-symbols/close';
import spinnerIcon from '@iconify/icons-material-symbols/progress-activity';
import { Icon } from '../icon/Icon';
import { ConditionalWrapper } from '#pie/utils/ConditionalWrapper';
import { formatBytes } from '#pie/utils/files';
import { cn, tv } from '#pie/utils/TailwindUtils';

export interface FileAnchorProps {
  className?: string;
  fileName: string;
  fileSize: number;
  onDelete?: () => void;
  onClick?: () => void;
  isLoading?: boolean;
}

export const FileAnchor: React.FC<FileAnchorProps> = ({
  className,
  onDelete,
  onClick,
  fileName,
  fileSize,
  isLoading = false,
}) => {
  const s = fileAnchor({
    isLoading: isLoading,
  });
  return (
    <div className={cn(s.base(), className)}>
      <ConditionalWrapper
        condition={!!onClick || isLoading}
        wrapper={children => (
          <button className={s.button()} onClick={onClick} aria-busy={isLoading} disabled={isLoading}>
            {children}
          </button>
        )}
      >
        <>
          <Icon icon={attachmentIcon} size={24} className="text-secondary" />
          <span>{fileName}</span>
          <span className="text-neutral-500">({formatBytes(fileSize)})</span>
          {isLoading && (
            <Icon role="progressbar" aria-label="loading" icon={spinnerIcon} size={16} className={s.spinner()} />
          )}
        </>
      </ConditionalWrapper>

      {onDelete && (
        <button
          className="text-error ml-auto px-0.5"
          aria-label="Delete file"
          onClick={e => {
            e.preventDefault();
            onDelete?.();
          }}
        >
          <Icon icon={closeIcon} size={16} />
        </button>
      )}
    </div>
  );
};

const fileAnchor = tv({
  base: 'flex w-full items-center gap-1',
  slots: {
    button: 'flex items-center gap-1 text-secondary',
    icon: 'text-secondary',
    spinner: 'animate-spin',
  },
  variants: {
    isLoading: {
      true: {
        base: 'opacity-50',
        button: 'text-neutral-500',
      },
    },
  },
});
