import { QueryClient } from '@tanstack/react-query';

export const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

export const queryClient = createQueryClient();

// TODO: Consider using a fetching library like ky or redaxios
// @deprecated use fetchInstance from #edsn/shared
export const fetchApi = <T>(input: RequestInfo | URL, init?: RequestInit): Promise<T> =>
  fetch(input, init).then(res => {
    if (!res.ok) {
      throw res;
    }

    return res.headers.get('Content-Type')?.match(/application\/json/) ? res.json() : res.text();
  });
