import type { CommentDtoListResultDto } from '#edsn/api/pie-bff/generated/model';

export const createComments = (overrides?: Partial<CommentDtoListResultDto>): CommentDtoListResultDto => ({
  items: [
    {
      attachments: [],
      createdBy: {
        id: 'd7d22845-8038-daa3-3d23-0f918d233f9f',
        label: 'Vincent Koster',
      },
      createdOn: '2023-09-12T23:26:34.4264329Z',
      description:
        'Quisquam est amet. Sint quia ex rem quam fuga aut ut nemo. Sapiente dolorem sunt nobis culpa et tenetur eaque blanditiis et. Voluptatum omnis tempora ea dolor non dolor aut velit. Et possimus quos odit. Et nam aspernatur dolorum officia.\n\nTemporibus ipsa eligendi quia nostrum temporibus culpa. Ut doloremque sint mollitia. Temporibus earum necessitatibus in dignissimos tenetur qui qui neque cum.\n\nQuaerat illo cupiditate. Molestias non facere corporis magni. Et et autem deleniti aut modi eveniet perferendis. Quos vero porro. Placeat et sed vel nihil qui voluptates.',
      id: '5f000f30-e3f4-47c8-dd6c-20d26e43f1fe',
    },
    {
      attachments: [],
      createdBy: {
        id: '918cfb0a-39c4-b1af-a963-96223a6f91b9',
        label: 'Pietje Puk',
      },
      createdOn: '2023-01-09T21:27:11.2345704Z',
      description:
        'Beatae totam alias magni. Quos voluptatibus praesentium qui ipsam eligendi ex. Et quis consequatur saepe et earum impedit reprehenderit. Modi vitae excepturi repudiandae deleniti vel sunt aut quos. Unde autem et tempora harum tenetur quis.\n\nEst quia ex perspiciatis quis inventore modi minima nulla. Tempore rerum sed et voluptatem deleniti aut qui sunt. Fugit sint saepe.\n\nDebitis earum aliquid libero aut et reprehenderit doloribus omnis. Eveniet dignissimos qui velit minus alias sed possimus enim. Magnam aut similique eum quaerat totam.',
      id: 'a25e16c9-9061-1beb-05ef-0823a4be3b1b',
    },
    {
      attachments: [],
      createdBy: {
        id: 'ea9714aa-bcd2-7e80-a542-f62466369c7d',
        label: 'Helena Wit',
      },
      createdOn: '2023-12-16T15:55:57.9726588Z',
      description:
        'Alias corporis porro esse. Occaecati sit commodi et suscipit dolorem dolores modi reiciendis. Eos voluptas maxime quis et quisquam veniam velit. Et saepe maxime veritatis.\n\nEum et atque optio atque commodi sed veniam dignissimos culpa. Eaque ex aut ipsa at quaerat omnis explicabo hic optio. Asperiores ipsa pariatur ut quos. Et doloremque fugit et nam veritatis veniam maxime est mollitia. Repudiandae quasi dolor in et incidunt.\n\nVoluptatem tenetur eum beatae numquam veritatis repellendus. Necessitatibus officiis magni dolorem voluptas corporis dicta ducimus dolores sequi. Sit ipsam laboriosam voluptas quis architecto et velit nihil totam.',
      id: '773fef00-9d46-7863-7fd1-60a7bbd9a107',
    },
    {
      attachments: [],
      createdBy: {
        id: '18f3b91d-93f3-af5f-43b1-0ad8705a4990',
        label: 'Jaap Stok',
      },
      createdOn: '2023-05-27T03:42:22.5697299Z',
      description:
        'Ipsam voluptatem excepturi aperiam quia dolorem velit fugit numquam. Porro ea aut. Saepe blanditiis odio tempora soluta iste autem et.\n\nVoluptatem iste modi eum aut asperiores est repellat aliquid sint. Accusantium tempore blanditiis ullam qui aut. Est iste aut occaecati provident error provident ad. Mollitia fuga eveniet aliquam occaecati. Similique natus dolorem libero nihil ad.\n\nCulpa velit quae aliquam qui. Eos aperiam illo. Est rerum vero nobis. Mollitia assumenda vero omnis odio illum. Et est eum et iusto.',
      id: 'ff906603-089e-f8d5-9eba-3574dc28e90b',
    },
    {
      attachments: [],
      createdBy: {
        id: '313303ac-b88b-2ec6-cef9-f976583b6640',
        label: 'Ethan Beek',
      },
      createdOn: '2023-09-15T19:28:42.9314571Z',
      description:
        'Ratione ad aut maiores rem eum numquam repellat rem dolores. Consectetur occaecati accusamus officia. Quo doloremque molestias magnam optio numquam quod voluptas nulla. Deserunt distinctio error aut impedit distinctio sed iste vel quae.\n\nVoluptates necessitatibus numquam molestiae eum. Porro sed sed a beatae eum nobis iste dolores. Cum porro qui ex beatae perspiciatis cumque et minus. Maxime qui impedit qui rerum veniam nobis assumenda quos quidem. Aut aliquam placeat nobis ducimus facere assumenda. Velit alias est veniam quasi eaque sint aut.\n\nIn quae dolor ut magnam molestiae. Aliquid vel rerum eligendi reprehenderit. Voluptatem tempora quod reprehenderit architecto aut est dolor sequi. Veniam eveniet voluptatem unde vel. Quasi dolores praesentium facilis perspiciatis dolores cum.',
      id: '29961270-b19f-8cfe-31e5-f1daf9bcb417',
    },
    {
      attachments: [],
      createdBy: {
        id: '56d7bde0-1595-34ef-e9d5-8f3c30e7017e',
        label: 'Jasper Jansen',
      },
      createdOn: '2023-07-04T08:49:01.4514376Z',
      description:
        'Unde laboriosam dolorum non a unde illum cum et. Et animi aut nobis officia esse eveniet. Ex occaecati aut.\n\nQuis temporibus magni nihil natus qui quas. Atque provident illo accusamus nemo rerum minima qui sunt. Aliquid qui molestiae vel eligendi molestias. Totam repudiandae aspernatur temporibus provident officia adipisci optio. Fugit expedita blanditiis similique tempore. Tempora architecto est architecto nisi consequatur quas fuga quis.\n\nCupiditate ut eaque perspiciatis a rem fugit quam quia. Aut numquam nobis voluptatem dolores. Recusandae quae incidunt iusto et quis voluptatem repellendus. Voluptatem iure autem.',
      id: '912e300a-cbfa-2e5b-f94b-c5ebb94607d7',
    },
    {
      attachments: [],
      createdBy: {
        id: 'c2bb82ed-d6f6-f37f-e36d-99615bd1b56c',
        label: 'Lucas Boer',
      },
      createdOn: '2023-05-04T14:40:10.4012614Z',
      description:
        'Cupiditate aperiam libero eum ducimus quae possimus eos. Id tempore atque ipsa eum earum enim qui aliquam. Blanditiis vero molestias laudantium est omnis. Libero fugiat sed nam. Ut mollitia animi est excepturi delectus. Tempore est voluptatem voluptatem numquam aperiam.\n\nOmnis expedita consequatur sint libero dolores voluptatibus cupiditate nisi. Provident quas ut harum autem quidem. Aut atque quia ea quaerat.\n\nEt consequatur ea dolorum vero amet sed. Sed perspiciatis nostrum veniam corrupti laborum officiis. Nostrum sunt et architecto et ut ut eum sunt. Enim nihil eum velit aut repellendus perferendis cupiditate at cupiditate.',
      id: 'feea546a-8bbc-2279-8ede-226ff57a58ea',
    },
    {
      attachments: [],
      createdBy: {
        id: '943d6411-fc47-51d0-3b9e-4650d18bdc0a',
        label: 'Jason Jong',
      },
      createdOn: '2023-07-03T02:06:43.5345637Z',
      description:
        'Minus ipsum rerum exercitationem qui qui consequatur non iure numquam. Qui quia ut non itaque minus voluptatem repellendus odit ut. Natus voluptate aut qui itaque. Sed ipsam quos natus explicabo. Deleniti animi ipsa suscipit nam expedita.\n\nEnim molestias voluptatem. Necessitatibus consequatur in necessitatibus rem. Adipisci ratione commodi voluptatum veritatis. Doloribus non officia et ducimus maiores magni in.\n\nQuia reprehenderit accusantium aut in. Perspiciatis dolore voluptas sint sit magni inventore non. Magni fugiat et laudantium animi. Quam sapiente sed eaque. Quae corrupti esse. Distinctio eveniet eveniet aliquid eos sapiente.',
      id: 'a4fcf605-cf4a-0202-0f72-ced907fdfd3e',
    },
  ],
  totalCount: 8,
  ...overrides,
});
