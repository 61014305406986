import { useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/vanilla/utils';
import { atomWithLocation } from 'jotai-location';
import { store } from '.';

export type SearchParam = (typeof SearchParam)[keyof typeof SearchParam];
export const SearchParam = {
  desc: 'desc',
  filters: 'filters',
  pageIndex: 'pageIndex',
  pageSize: 'pageSize',
  search: 'search',
  sortField: 'sortField',
} as const;

export const useStoredParams = (pathname: string) => {
  const params = useAtomValue(storedParamsAtom);
  if (typeof params !== 'object') return;
  return params[pathname];
};

const storedParamsAtom = atomWithStorage<Record<string, string> | undefined>('params', {});

const applyLocation = (params: URLSearchParams): void => {
  const url = new URL(window.location.href);
  url.search = params.toString();

  let currentParams = store.get(storedParamsAtom);
  if (typeof currentParams !== 'object') {
    currentParams = {};
  }
  currentParams[url.pathname] = `?${params.toString()}`;

  store.set(storedParamsAtom, currentParams);
  window.history.replaceState(null, '', url);
};

export const paramsAtom = atomWithLocation({
  applyLocation,
  getLocation: () => new URLSearchParams(window.location.search),
});
