/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type UsageClassification = (typeof UsageClassification)[keyof typeof UsageClassification];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageClassification = {
  None: 'None',
  SmallConsumption: 'SmallConsumption',
  LargeConsumption: 'LargeConsumption',
} as const;
