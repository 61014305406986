import type { PropsWithChildren } from 'react';
import { tv } from '#pie/utils/TailwindUtils';

export interface TagProps extends PropsWithChildren {
  isActive?: boolean;
  href?: string;
}

export function Tag({ children, isActive = false, href }: TagProps) {
  const style = tag({ hasHref: !!href, isActive });
  const Component = href ? 'a' : 'div';

  return (
    <Component className={style} href={href}>
      {children}
    </Component>
  );
}

const tag = tv({
  base: 'rounded-full flex bg-neutral-200 px-4 h-9 text-sm text-neutral-600 border border-neutral-200 whitespace-nowrap items-center gap-1',
  variants: {
    hasHref: {
      true: 'hover:border-primary-dark',
    },
    isActive: {
      true: 'bg-primary-dark text-white border-primary-dark',
    },
  },
});
