import { animate } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTicket } from '#edsn/api/pie-bff';
import { tv } from '#pie/utils/TailwindUtils';

type TicketCounterProps = {
  isOpen?: boolean;
};
export const TicketCounter = ({ isOpen }: TicketCounterProps) => {
  const { data } = useGetTicket({
    filter: { ticketView: 'Unread' },
    pagination: { pageIndex: 0, pageSize: 1 },
  });
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const previous = useRef(0);

  useEffect(() => {
    const node = ref.current;
    const max = 999;

    if (node && data?.totalCount) {
      const controls = animate(previous.current, Math.min(data.totalCount, max), {
        /* c8 ignore next */
        duration: previous.current === 0 ? 2 : 0.1,
        ease: 'easeOut',
        onUpdate(value) {
          node!.textContent = value === max ? `${max}⁺` : Math.round(value as number).toString();
        },
      });

      previous.current = data.totalCount;

      return () => controls.stop();
    }
  }, [data?.totalCount]);

  return (
    data &&
    data.totalCount > 0 && <div aria-label={t('common.ticket_counter')} ref={ref} className={styles({ isOpen })} />
  );
};

const styles = tv({
  base: 'ticket-counter bg-primary-dark flex items-center justify-center rounded-full text-xs text-white',
  variants: {
    isOpen: {
      false: 'w-6 h-4 absolute top-0 right-3',
      true: 'h-[20px] w-[30px]',
    },
  },
});
