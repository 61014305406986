import seedrandom from 'seedrandom';
import {
  type ConnectionInformationDto,
  type GridOperatorDto,
  type GridOperatorDtoListResultDto,
  Product,
} from '../generated/model';

export const generateEan = (length: 13 | 18 = 18, seed?: string) => {
  const rng = seed ? seedrandom(seed) : Math.random;
  let code = '';
  for (let j = 0; j < length - 1; j++) {
    code += Math.floor(rng() * 10);
  }
  let checkDigit = 0;
  if (length === 13) {
    const oddSum = code
      .split('')
      .filter((_, i) => i % 2 === 0)
      .reduce((sum, digit) => sum + parseInt(digit), 0);
    const evenSum = code
      .split('')
      .filter((_, i) => i % 2 !== 0)
      .reduce((sum, digit) => sum + parseInt(digit), 0);
    checkDigit = (10 - ((oddSum + 3 * evenSum) % 10)) % 10;
  } else {
    checkDigit = code.split('').reduce((sum, digit, index) => {
      return sum + (index % 2 === 0 ? 3 : 1) * parseInt(digit);
    }, 0);
    checkDigit = (10 - (checkDigit % 10)) % 10;
  }
  code += checkDigit;

  return code;
};

export const createConnectionInformation = (
  overrides: Partial<ConnectionInformationDto> = {}
): ConnectionInformationDto => ({
  administrativeStatus: '41369',
  deviceInUseDate: '2024-01-04T12:03:07.9931869+00:00',
  deviceNumber: '50755',
  deviceStatus: 'SMU',
  deviceType: 'SLM',
  ean18: generateEan(18, 'electricity'), // 197235981117714411
  failureEventCode: '18.5',
  failureEventDate: '2024-01-04T09:51:02.6612512+00:00',
  failureEventRemark:
    "Boston's most advanced compression wear technology increases muscle oxygenation, stabilizes active muscles",
  isLargeConsumerOrDeviceless: false,
  operationalStatus: 'OK',
  product: Product.Electricity,
  regionalGridOperator: createRegionalGridOperator(),
  supplier: 'Essent',
  ticketNumber: '',
  usageClassification: 'None',
  website: '',
  ...overrides,
});

export const createRegionalGridOperator = (overrides: Partial<GridOperatorDto> = {}): GridOperatorDto => ({
  connectedEan13s: [],
  ean13: generateEan(13, 'grid-operator'),
  id: 'c366d5f9-5d3e-404e-ae55-5ae6b3342ea2',
  name: 'Enexis',
  phone: '010-12345678',
  ...overrides,
});

export const createRegionalGridOperators = (
  overrides: Partial<GridOperatorDtoListResultDto> = {}
): GridOperatorDtoListResultDto => ({
  items: [
    createRegionalGridOperator(),
    createRegionalGridOperator({
      ean13: generateEan(13, 'grid-operator 1'),
      id: 'c366d5f9-5d3e-404e-ae55-5ae6b3342ea3',
      name: 'Liander',
    }),
    createRegionalGridOperator({
      ean13: generateEan(13, 'grid-operator 2'),
      id: 'c366d5f9-5d3e-404e-ae55-5ae6b3342ea4',
      name: 'Stedin',
    }),
  ],
  totalCount: 3,
  ...overrides,
});
