import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useGetConnectionInformation } from '#edsn/api/pie-bff';
import { useEanFormContext } from './TicketCreateEan';
import type { StepProps } from './TicketCreateEan';
import { Card } from '#pie/components/card/Card';
import { Divider } from '#pie/components/divider/Divider';
import { FailureInfo } from '#pie/components/failure-info/FailureInfo';
import { Page, PageHeader } from '#pie/components/page/Page';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { TicketTableEan } from '#pie/components/ticket-table-ean/TicketTableEan';
import { productIcons } from '#pie/constants/productIcons';
import { isDefined } from '#pie/utils/isDefined';

const schema = z.object({});

type TicketsForm = z.infer<typeof schema>;

export const TicketCreateEanTickets = ({ wizardActions, onSubmit, onBack, stepper }: StepProps) => {
  const { t } = useTranslation();
  const {
    data: { electricityEan, gasEan },
  } = useEanFormContext();
  const { data: elecData } = useGetConnectionInformation(
    { ean18: electricityEan! },
    { query: { enabled: !!electricityEan } }
  );
  const { data: gasData } = useGetConnectionInformation({ ean18: gasEan! }, { query: { enabled: !!gasEan } });

  const hasElecFailure = !!elecData?.failureEventCode;
  const hasGasFailure = !!gasData?.failureEventCode;

  const formMethods = useForm<TicketsForm>({ resolver: zodResolver(schema) });
  const { handleSubmit } = formMethods;

  return (
    <Page>
      <PageHeader
        title="Elektriciteits- en/of gasaansluiting (EAN-18) ticket indienen"
        previousText="Vorige stap"
        backOnClick={onBack}
        details={stepper}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="mb-6 max-w-4xl p-6" shadow>
            <Stack gap="lg">
              <Stack gap="sm">
                <Text as="h2" variant="h5" className="text-primary-dark">
                  {t('ticket_create.prior_tickets.failures.title')}
                </Text>
                {hasElecFailure || hasGasFailure ? (
                  <Stack gap="sm">
                    {hasElecFailure && electricityEan && <FailureInfo connection={elecData} ean18={electricityEan} />}
                    {hasGasFailure && gasEan && <FailureInfo connection={gasData} ean18={gasEan} />}
                  </Stack>
                ) : (
                  t('ticket_create.prior_tickets.failures.no_failures_for_ean', {
                    eans: [electricityEan && `EAN ${electricityEan}`, gasEan && `EAN ${gasEan}`]
                      .filter(isDefined)
                      .join(` ${t('common.and')} `),
                  })
                )}
              </Stack>

              {electricityEan && (
                <Stack gap="sm">
                  <Stack direction="row" gap="sm" className="items-center">
                    <Icon className="text-primary-dark flex-shrink-0" icon={productIcons.Electricity} />
                    <Text as="h2" variant="h5" className="text-primary-dark">
                      {t('ticket_create.prior_tickets.earlier_tickets', { ean: electricityEan })}
                    </Text>
                  </Stack>
                  <TicketTableEan ean={electricityEan} />
                </Stack>
              )}

              {gasEan && (
                <Stack gap="sm">
                  <Stack direction="row" gap="sm" className="items-center">
                    <Icon className="text-primary-dark flex-shrink-0" icon={productIcons.Gas} />
                    <Text as="h2" variant="h5" className="text-primary-dark">
                      {t('ticket_create.prior_tickets.earlier_tickets', { ean: gasEan })}
                    </Text>
                  </Stack>
                  <TicketTableEan ean={gasEan} />
                </Stack>
              )}

              <Divider />

              <Stack direction="row" className="justify-between" gap="lg">
                {wizardActions}
              </Stack>
            </Stack>
          </Card>
        </form>
      </FormProvider>
    </Page>
  );
};
