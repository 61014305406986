// @see https://zod.dev/ERROR_HANDLING?id=customizing-errors-with-zoderrormap
import i18next from 'i18next';
import { z } from 'zod';

type ZodErrorMapError = (issue: z.ZodIssueOptionalMessage) => ReturnType<z.ZodErrorMap> | undefined;

export const ErrorMessage = {
  ean18: 'zod.errors.invalid_ean18',
  either: 'zod.errors.either',
  elecEan18Incorrect: 'zod.errors.ean18_incorrect_electricity',
  gasEan18Incorrect: 'zod.errors.ean18_incorrect_gas',
  required: 'zod.errors.required',
} as const;

/**
 * Translated 'required' error when string should be larger than 1 instead of the default error
 * TODO: transform "" into undefined with React Hook Form, then we don't need this anymore
 */
export const requiredError: ZodErrorMapError = issue => {
  if (issue.code === z.ZodIssueCode.too_small && issue.minimum === 1) {
    return { message: i18next.t(ErrorMessage.required) };
  }
};

export const buildErrorMap =
  ({ errors, fallbackErrorMap }: { errors: ZodErrorMapError[]; fallbackErrorMap: z.ZodErrorMap }): z.ZodErrorMap =>
  (issue, ctx) => {
    // Find the last error that matches the issue
    const matchingError = errors.findLast(error => error(issue));

    // If a matching error is found, call it with the issue
    if (matchingError) return matchingError(issue)!;

    // If no matching error is found, use the fallback error map
    return fallbackErrorMap(issue, ctx);
  };
