import { type ClassValue, clsx } from 'clsx';
import { defu } from 'defu';
import { type TV, cn as _cn, tv as _tv } from 'tailwind-variants';

const tvConfig = {
  twMerge: true,
  twMergeConfig: {
    cacheSize: 500,
    classGroups: {
      'font-size': [
        'text-display',
        'text-h1',
        'text-h2',
        'text-h3',
        'text-h4',
        'text-h5',
        'text-h6',
        'text-subtitle',
        'text-xl',
        'text-lg',
        'text-base',
        'text-sm',
        'text-xs',
        'text-xxs',
      ],
    },
    conflictingClassGroups: {},
    theme: {},
  },
};

export const tv: TV = (options, config) => _tv(options, defu(tvConfig, config));

export function cn(...classes: ClassValue[]) {
  return _cn(clsx(classes))(tvConfig);
}

export { type VariantProps, type TV } from 'tailwind-variants';
