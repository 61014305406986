import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { TeamDto } from '#edsn/api/pie-bff';
import { useGetTeam } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { RemoveTeamButton } from '#pie/components/remove-team-button/RemoveTeamButton';
import { Stack } from '#pie/components/stack/Stack';
import { Table } from '#pie/components/table/Table';
import { Text } from '#pie/components/text/Text';

export const TeamManagement = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetTeam();
  const helper = useMemo(() => createColumnHelper<TeamDto>(), []);
  const teams = data?.items ?? [];

  return (
    <section className="flex items-start gap-6">
      <Card className="flex-1 overflow-x-hidden p-4">
        <Stack gap="md">
          {!isLoading && !teams?.length ? (
            <Text className="p-3">{t('common.no_results')}</Text>
          ) : (
            <Table
              rowLinks={row => ({ 'aria-label': row.original.name, to: row.original.id })}
              columns={[
                helper.accessor('name', {
                  cell: info => (
                    <div className="flex">
                      <Link className="text-primary link" to={info.row.original.id} aria-label={info.getValue()}>
                        {info.getValue()}
                      </Link>
                      <RemoveTeamButton data={info.row.original} />
                    </div>
                  ),
                  header: t('common.name'),
                }),
              ]}
              sortDescFirst
              getRowId={m => m.id}
              data={teams}
              pagination={{ pageIndex: 0, pageSize: 100 }}
              sorting={[]}
              isLoading={isLoading}
            />
          )}
        </Stack>
      </Card>
    </section>
  );
};
