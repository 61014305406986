import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useGetGridOperator } from '#edsn/api/pie-bff';
import type { ComponentProps } from 'react';
import { validation } from '#pie/components/form/formHelpers';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const gridOperatorValidationSchema = z.object({
  regionalGridOperatorId: z.string(),
});

type GridOperatorForm = z.infer<typeof gridOperatorValidationSchema>;

const { FormDropdown } = typedFormFields<GridOperatorForm>();

type Props = Pick<ComponentProps<typeof FormDropdown>, 'disabled'>;

export const TicketGridOperatorsFields = ({ disabled }: Props) => {
  const { t } = useTranslation();
  const { data: gridOperatorData, isLoading: isLoadingGridOperators } = useGetGridOperator();
  return (
    <FormDropdown
      fullWidth
      label={t('common.regional_grid_operator')}
      name="regionalGridOperatorId"
      options={
        gridOperatorData?.items.map(operator => ({
          label: operator.name,
          value: operator.id,
        })) || []
      }
      rules={{ required: validation.required }}
      isLoading={isLoadingGridOperators}
      disabled={disabled}
    />
  );
};
