import type { ContactDto } from '#edsn/api/pie-bff';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';

export const defaultContactSorting: DefaultSorting<ContactDto> = {
  desc: false,
  id: 'fullName',
};

export const contactSortingAtom = createSortingAtom({ defaultSorting: defaultContactSorting });
