import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { type ComponentProps, useEffect } from 'react';
import type { Pagination, Sorting } from '#edsn/api/pie-bff';
import { useDebounce } from './useDebounce';
import type { DataTable } from '#pie/components/data-table/DataTable';
import type { SetTypeForFields } from '#pie/utils/date';
import type { SortingState } from '@tanstack/react-table';
import type { WritableAtom } from 'jotai';
import { paginationAtom } from '#pie/stores/pagination';
import { searchAtom } from '#pie/stores/search';
import { paramsAtom } from '#pie/stores/searchParams';
import { parseDates } from '#pie/utils/date';

export interface Props<TData extends object, TFilter extends object, TDates extends (keyof TData)[]>
  extends Pick<ComponentProps<typeof DataTable<SetTypeForFields<TData, TDates, Date>>>, 'columns'> {
  filtersAtom: WritableAtom<TFilter, [TFilter], TFilter>;
  query: (options: { filter: object; pagination: Pagination; search?: string | null; sorting?: Sorting }) => Promise<{
    items: TData[];
    totalCount: number;
  }>;
  sortingAtom: WritableAtom<SortingState, [SortingState], SortingState>;
  hardFilters?: TFilter;
  dateKeys: TDates;
}

export const useDataTable = <TData extends object, TFilter extends object, TDates extends (keyof TData)[]>({
  filtersAtom,
  query,
  sortingAtom,
  columns,
  dateKeys,
  hardFilters,
}: Props<TData, TFilter, TDates>) => {
  const setParams = useSetAtom(paramsAtom);
  useEffect(() => {
    const { search } = window.location;
    if (!search.includes('&skip=true')) {
      setParams(new URLSearchParams(search));
    }
  }, []);

  const filter = useAtomValue(filtersAtom);
  const sorting = useAtomValue(sortingAtom)[0];
  const pagination = useAtomValue(paginationAtom);
  const search = useAtomValue(searchAtom);
  const debouncedSearch = useDebounce(search, 500);
  const options = {
    filter: {
      ...Object.fromEntries(Object.entries(filter).map(([key, value]) => [key, value.filter(Boolean)])),
      ...hardFilters,
    },
    pagination,
    search: debouncedSearch,
    sorting: { descending: sorting.desc, field: sorting.id },
  };

  const { data, isLoading } = useQuery({
    /* c8 ignore next */
    queryFn: () => query(options),
    // TODO: Find a way to get the url of the query instead of using the name
    queryKey: [query.name, options],
    select: data => ({
      ...data,
      items: data.items?.map(item => ({ ...parseDates(item, dateKeys) })),
    }),
  });

  return {
    columns,
    data: data?.items,
    isLoading,
    sortingAtom,
    totalCount: data?.totalCount,
  };
};
