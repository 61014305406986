import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { MainCategoryDto, SubCategoryDto} from '#edsn/api/pie-bff';
import { useGetMainCategory } from '#edsn/api/pie-bff';
import { validation } from '#pie/components/form/formHelpers';
import { typedFormFields } from '#pie/utils/typedFormFields';

interface Props {
  excludedSubcategories?: (MainCategoryDto['id'] | SubCategoryDto['id'])[];
  isLoading?: boolean;
}

interface CategoriesForm {
  maincategoryId: string;
  subcategoryId: string;
}

const { FormDropdown } = typedFormFields<CategoriesForm>();

export const TicketCategoriesDropdowns = ({ excludedSubcategories = [], isLoading }: Props) => {
  const { t } = useTranslation();
  const { watch, resetField } = useFormContext<CategoriesForm>();
  const { data: mainCategoryData, isLoading: isLoadingCategories } = useGetMainCategory();
  const mainCategories = mainCategoryData?.items;
  const category = watch('maincategoryId');

  const subCategories = useMemo(
    () =>
      mainCategories
        ?.find(c => c.id === category)
        ?.subCategories.filter(scat => !excludedSubcategories.includes(scat.id)),
    [category, mainCategories]
  );

  return (
    <>
      <FormDropdown
        label={t('common.main_category')}
        fullWidth
        name="maincategoryId"
        options={
          mainCategories?.map(cat => ({
            label: cat.name,
            value: cat.id,
          })) || []
        }
        rules={{ required: validation.required }}
        isLoading={isLoadingCategories || isLoading}
        onChange={() => {
          resetField('subcategoryId');
        }}
      />

      <FormDropdown
        key={category}
        fullWidth
        label={t('common.sub_category')}
        name="subcategoryId"
        options={
          subCategories?.map(subCat => ({
            label: subCat.name,
            value: subCat.id,
          })) || []
        }
        rules={{ required: validation.required }}
        disabled={!category}
        isLoading={isLoadingCategories || isLoading}
        placeholder={category ? t('common.dropdown.select') : t('ticket_create.categories.select_main_category_first')}
      />
    </>
  );
};
