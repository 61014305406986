import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { VITE_GOOGLE_ANALYTICS_METRIC_ID } from '#pie/env';

interface Props {
  title?: string;
  description?: string;
  noIndex?: boolean;
}

export const PageHead = ({ title, description, noIndex, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <Helmet titleTemplate={`%s | ${t('site_name')}`} defaultTitle={t('site_name')} {...props}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${VITE_GOOGLE_ANALYTICS_METRIC_ID}`} />

      {noIndex && <meta name="robots" content="noindex" />}

      <script>
        {`window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${VITE_GOOGLE_ANALYTICS_METRIC_ID}');`}
      </script>
    </Helmet>
  );
};
