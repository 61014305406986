/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type CommentReferenceType = (typeof CommentReferenceType)[keyof typeof CommentReferenceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommentReferenceType = {
  Ticket: 'Ticket',
  SurveyDialogue: 'SurveyDialogue',
} as const;
