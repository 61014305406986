// Mock data for PiE and PiE Public translations combined
export function createStaticMessages() {
  return {
    'archive.results.error': 'Er ging iets fout met het laden...',
    'archive.results.loading': 'Berichten aan het laden...',
    'archive.results.none': 'Er zijn geen resultaten gevonden...',
    'archiveItem.button.readMore': 'Lees verder',
    'button.moreNews': 'Meer nieuws',
    'button.readMore': 'Lees meer',
    'components.breadcrumbs.home': 'Home',
    'components.footer.quickAccess': 'Snel naar',
    'components.header.account': 'Mijn Partners in Energie',
    'components.header.quickAccess': 'Snel naar',
    'components.header.search': 'Zoeken',
    'components.header.support': 'Support',
    'components.layout.header.viewAll': 'Alles weergeven',
    'components.localeToggle.locales.en-GB': 'Engels',
    'components.localeToggle.locales.nl-NL': 'Nederlands',
    'components.socialMedia.share': 'Deel',
    'components.socialMedia.shareArticle': 'Deel artikel',
    'cookie.accept': 'Accepteren',
    'cookie.description':
      "Wij en derde partijen gebruiken cookies op onze website voor statistische, voorkeurs- en marketingdoeleinden. Google Analytics-cookies zijn geanonimiseerd. U kunt uw voorkeur wijzigen door op 'Zelf instellen' te klikken. Door op 'Accepteren' te klikken accepteert u het gebruik van alle cookies zoals beschreven in onze privacyverklaring.",
    'cookie.marketing.description':
      'Fusce vehicula risus vel tortor imperdiet, ut congue lectus rutrum. Phasellus consectetur nisi arcu, id blandit nulla fermentum at. Quisque lacinia tortor et ipsum ullamcorper tristique. Fusce vehicula risus vel tortor imperdiet, ut congue lectus rutrum. Phasellus consectetur nisi arcu, id blandit nulla fermentum at. Quisque lacinia tortor et ipsum ullamcorper tristique.',
    'cookie.marketing.title': 'Marketing',
    'cookie.necessary.description':
      'Fusce vehicula risus vel tortor imperdiet, ut congue lectus rutrum. Phasellus consectetur nisi arcu, id blandit nulla fermentum at. Quisque lacinia tortor et ipsum ullamcorper tristique. Fusce vehicula risus vel tortor imperdiet, ut congue lectus rutrum. Phasellus consectetur nisi arcu, id blandit nulla fermentum at. Quisque lacinia tortor et ipsum ullamcorper tristique.',
    'cookie.necessary.title': 'Noodzakelijk',
    'cookie.setup': 'Zelf instellen',
    'cookie.title': 'Wij geven om jouw privacy',
    'cookie.video.accept': 'Accepteer alle cookies',
    'cookie.video.external': 'Klik hier om de video te bekijken op YouTube',
    'cookie.video.title': 'Accepteer de cookies om deze video te bekijken',
    'error.subtitle':
      'Het lijkt erop dat er iets is fout gegaan. Vervolg je pad met de zoekmachine of gebruik de snelle navigatie',
    'error.title': 'Er is een onbekende fout opgetreden.',
    'filter.categories.all': 'Alle',
    'filter.categories.title': 'Categorieën',
    'filter.results': 'Resultaten',
    'filter.sort.ASC': 'Oplopend',
    'filter.sort.DESC': 'Aflopend',
    'filter.sort.title': 'Sorteer op',
    'meta.siteName': 'Partners in Energie',
    'news.allArticles': 'Alle artikelen',
    'news.noResults': 'Er zijn geen artikelen gevonden',
    'news.resetFilter': 'Wis filter',
    'notFound.quickLinks': 'Snel naar',
    'pages.home.allThemes': "Alle thema's",
    'pages.home.news': 'Bekijk het laatste nieuws',
    'pages.home.title': 'Wij bouwen samen aan een stralende toekomst',
    'pages.news.detail.articles': 'Relevante artikelen',
    'pages.news.detail.subtitle': 'Nieuws',
    postedAt: 'Gepost op',
    'search.filter.tag.all': 'Toon alles',
    'search.filter.tag.news': 'Nieuws',
    'search.filter.tag.themes': "Thema's",
    'search.filter.tag.title': 'Filter op',
    'search.form.placeholder': 'Zoek naar...',
    'search.form.submit': 'Zoeken',
    'search.resultsFound_one': "{{total}} resultaat gevonden op <x>'{{search}}'</x>",
    'search.resultsFound_other': "{{total}} resultaten gevonden op <x>'{{search}}'</x>",
    'search.type.home_home_Entry': 'Home',
    'search.type.newsArchive_newsArchive_Entry': 'Nieuws archief',
    'search.type.news_newsItem_Entry': 'Nieuws',
    'search.type.page_default_Entry': 'Pagina',
    'search.type.servicesArchive_servicesArchive_Entry': 'Diensten archief',
    'search.type.services_default_Entry': 'Diensten',
    'search.type.themes_default_Entry': "Thema's",
    siteName: 'Partners in Energie',
    tableOfContents: 'Inhoudsopgave',

    // Custom via requiredErrorMap
    'zod.errors.ean18_incorrect_electricity': 'Dit is geen geldige electriciteit EAN-18',
    'zod.errors.ean18_incorrect_gas': 'Dit is geen geldige gas EAN-18',
    'zod.errors.either': 'Vul minimaal één van de velden in',
    'zod.errors.invalid_ean18': 'Dit is geen geldige EAN-18',
    'zod.errors.invalid_type_received_undefined': 'Dit veld is verplicht',
    'zod.errors.required': 'Dit veld is verplicht',
    'zod.errors.too_small.string.inclusive': 'De waarde moet minimaal {{minimum}} karakters zijn',
  };
}
